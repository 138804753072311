import {
    FETCH_ORIGINAL_CONTENTS
} from './types';
import { fetchAll } from '../queries'

export const fetchAllOriginalContent = ({ id, pageID, queryString, queries }) => async dispatch => {
    
    const res:any = await fetchAll({ pageID, queryString })
    
    if (res.error) {
        dispatch({
            type: FETCH_ORIGINAL_CONTENTS, 
            payload: {
                viewTemplate: {
                    formPrompts: {
                        primary: "Oop, something went wrong fetching you page's 'originalContent'!",
                        secondary:'',
                        status:'error'
                    }
                }
            }
        });
    } else {
        dispatch({
            type: FETCH_ORIGINAL_CONTENTS,
            payload: res
        })
    }
}