import {
    FETCH_PAGES,
    FETCH_PAGE,
    START_PAGE_POPULATE,
    END_PAGE_POPULATE
} from './types';

// import axios from 'axios'
// import { PromptError } from 'src/shared/utils';
import { SERVER_ROOT, AUTH_HEADERS } from 'src/shared/constants'; 
const PAGE_ROOT = SERVER_ROOT + '/page';
const PAGES_ROOT = SERVER_ROOT + '/pages';
import { fetchAll, fetchOne, populateOne } from '../queries'
// import { Route } from 'react-router-dom'
import { store } from 'src'
// export const  fetchAllPages =() => {console.log('wtf :');}
export const  fetchAllPages = async ({queryString, noDispatch = false}) => {
    const res:any = await fetchAll({queryString})

    if (!noDispatch) {
        return function (dispatch) {
            sendDispatch({dispatch})
        }
    } else {
        sendDispatch({dispatch: store.dispatch})
    }

    function sendDispatch({dispatch}) {
        if (res.error) {
            dispatch({
                type: FETCH_PAGES, 
                payload: {
                    viewTemplate: {
                        formPrompts: {
                            primary: "Oop, something went wrong fetching you pages!",
                            secondary:'',
                            status:'error'
                        }
                    }
                }
            });
        } else {
            dispatch({
                type: FETCH_PAGES, 
                payload: {
                    pages: res.pages
                }
            });
        }
    }
}

export const fetchOnePage = ({ id, queryString, queries }) => async dispatch => {
    const res:any = await fetchOne({id, queryString})
    let page = {
        ...res.page,
        ...queries
    }

    if (res.error) {
        dispatch({
            type: FETCH_PAGE, 
            payload: {
                viewTemplate: {
                    formPrompts: {
                        primary: "Oop, something went wrong fetching you page!",
                        secondary:'',
                        status:'error'
                    }
                }
            }
        });
    } else {
        dispatch({
            type: FETCH_PAGE,
            payload: {
                page
            }
        })
    }
}


export const populateOnePage = ({ pageID }) => async dispatch => {

    dispatch({
        type: START_PAGE_POPULATE,
        payload: {
            pageID
        }
    })

    const res:any = await populateOne({id: pageID})

    if (res.error) {
        dispatch({
            type: END_PAGE_POPULATE, 
            payload: {
                viewTemplate: {
                    formPrompts: {
                        primary: "Oop, something went wrong populating you page!",
                        secondary:'',
                        status:'error'
                    }
                }
            }
        });
    } else {
        dispatch({
            type: END_PAGE_POPULATE,
            payload: {
                populatedPage: res
            }
        })
    }
}


