import * as React from 'react';
import { Component } from 'react';
import {connect} from 'react-redux'
import styles from './index.scss';
import { MainTopNav_I } from '../interfaces'
import WithNavHOC from 'src/data/nav/containers/index';
import { Link, RouteComponentProps } from "react-router-dom";
import Button from 'src/components/atoms/Button';
import { signOutUser } from 'src/data/user/actions'
import logoImage from "public/images/logos/leaf-logo-final-crop.png"

interface Props {
	isAuthed?:boolean;
	signOutUser?: Function
	toggleMobile?: Function
}

interface State {
	isAuthed?:boolean;
}

class MainTopNav extends Component<Props & RouteComponentProps<any>, State>  {
	state = {
		isAuthed: false
	}
	static defaultProps = {
		navType: "mainTopNav",
		signOutUser: ()=>{},
		toggleMobile: ()=>{}
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		const { user } = nextProps,
			  { isAuthed } = user;

		if (!user || isAuthed === prevState.isAuthed) return prevState;
		return { isAuthed } 
	}

	render () {
		const { isAuthed } = this.state,
			{ toggleMobile } = this.props
		return(
			<div className={`main-top-nav grid-x align-middle ${styles['main-top-nav']}`}>
				<div className={`${styles['left-section']} cell shrink`}>
					<div className={styles['logo']}>
						<Link to="/">
							<img src={logoImage} />
							{/* <h2 className="bg-l1-text">VIG</h2> */}
						</Link>
					</div>
				</div>
				<div className="middle-section cell auto">
				</div>
				<div className={`${styles['right-section']} cell shrink`}>
					{ isAuthed ?
						<div className="user-info cell auto grid-x align-middle">
							<div className={`grid-x ${styles['notifications']}`}>
								<h6>Notifications</h6>
								<span className="new badge primary-background">0</span>
							</div>
							{/* <img src="/public/images/user/avatar.png" width="40px" className={styles['avatar']} /> */}
							<Button size="small" label={{text:'Log Out'}} width="cell shrink" action={()=>this.props.signOutUser()} />
						</div>
						:
						<div className="grid-x align-middle  hide-on-small-only">
							<h6 className="cell shrink" style={{marginRight:'20px',marginBottom:'0px'}}>Interested in registering?</h6>
							<a className="cell auto primary-text" href="email:inquiry@vigcontent.com">inquiry@vigcontent.com</a>
						</div>
						// <Button 
						// 	size="small" 
						// 	label={{text:'Log In'}} 
						// 	width="cell shrink" 
						// 	action={()=>{
						// 		let prefix = window.location.host.startsWith("http") ? "" : "http://";
						// 		let target = prefix + window.location.host;
						// 		window.location.replace(target+'/')
						// 	}}
						// />
					}
				</div>
			</div>
		);	
	}
}

const mapStateToProps = state => { 
	const {user} = state;
	return {user};
}
export default WithNavHOC(
					connect<any,any,any,any>(
						mapStateToProps,
						{ signOutUser }
					)(MainTopNav)
				);



