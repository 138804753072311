import * as React from 'react';
import './index.scss';
import { Button_I } from '../interfaces'

const Button:React.StatelessComponent<Button_I> = (props:any) => {
	const {
		action,
		actionParams,
		label = {},
		...restProps
	}: Button_I  = props,
	uiPropDefaults = { color:"primary", shape:"square", size: "regular", status:"visible" }

	const classNames = Object.entries({...uiPropDefaults, ...restProps}).reduce((finalString:string, className:any) => {
		if (className[0] !== "label") {
			return `${finalString} ${className[1]}`;
		}

		let labelClassNames = ( labelClasses  => {
			if (!labelClasses) return '';
			return Object.entries(labelClasses).reduce((labelClassString, labelClassName) => {
				if (labelClassName[0] !== "text" && labelClassName[0] !== "iconName" ) {
					return `${labelClassString} ${labelClassName[1]}`;
				}
				return labelClassString;
			},'');
		})( className[1] )

		return `${finalString} ${labelClassNames}`;
	},'');

	const renderLabel = (label) => {
		if (label === undefined) return false;
		return (
			<div 
				className="grid-x align-middle align-left"
				style={label.iconPosition === 'icon-right' ? {flexFlow: 'row-reverse'}:{}}
			>
				<i 
					className="icon material-icons cell shrink"
					style={'iconName' in label ? {display:'inline-block'} : {display:'none'}}
				>{'iconName' in label ? label.iconName : ''}</i>
				<p className="label cell auto">{'text' in label ? label.text : ''}</p>
			</div>
		);
	}

	const handleButtonClick = () => {
		if (props.status !== 'disabled' && props.status !== 'disabled-filled') {
			if (actionParams) {
				action(actionParams);
			} else {
				action();
			}
		}
	}

	return(
		<a 
			className={`btn ${classNames}`}
			onClick={ handleButtonClick }
		>
			{ renderLabel(label) }
		</a>
	);	
}

export default Button;
