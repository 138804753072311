import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux'
import { createElemDraft, updateElemDraft } from 'src/data/layer/actions'
import { BlockPicker, GithubPicker, CirclePicker, TwitterPicker } from 'react-color';
import { registerUser } from 'src/data/user/actions';
import StyleFieldsAdmin from 'src/components/molecules/StyleFieldsAdmin/ui'
import { buildStyleFields } from '../modules/buildStyles'

interface IDipatchProps { 
	load?: (ref: string) => void; 
	createElemDraft?:Function
	updateElemDraft?: Function
}

interface StylesEditor_I extends IDipatchProps{
	media?:string
	elemEditing?: any 
	multimediaObj?: any 
	admin?:boolean
}

interface State {
    newElemStyles?: any
	storedElemStyles?:any
	manualEditorStyles?:any
	userEditorStyles?:any
	lastUpdate?: string
}
class StylesEditor extends Component<StylesEditor_I, State> {
	state = {
		storedElemStyles: {},
		newElemStyles: {},
		manualEditorStyles: {
			css: {},
			cssContainer:{},
			animations: [],
			animationsContainer: []
		},
		userEditorStyles:{},
		lastUpdate: null
	};

	static getDerivedStateFromProps(newProps, prevState) { 
		const { elemEditing, vig } = newProps,
			{ elem_formats, elem_drafts } = elemEditing,
			{ css } = elem_formats;
		
		if (!prevState.lastUpdate || elemEditing.updated_at !== prevState.lastUpdate) {
			const formatsEditing = (()=>{
				if (!elem_drafts || !elem_drafts.length) return elem_formats;
				const draftElem = vig.elems.find(({id}) => id === elem_drafts[0]);
				if (!draftElem || !draftElem.elem_formats) return elem_formats;
				return draftElem.elem_formats;
			})()

			return {
				...prevState,
				storedStyles: css,
				manualEditorStyles: formatsEditing,
				userEditorStyles: formatsEditing,
				lastUpdate: elemEditing.updated_at
			}
		}
		return prevState;
	}

	updateStyles({ elem, newElemStyles }) {
		const { 
			elemEditing,
			createElemDraft,
			updateElemDraft
		 } = this.props;
		 console.log('newElemStyles :', newElemStyles);
		const currentDraft = elemEditing.elem_drafts && elemEditing.elem_drafts[0] ? elemEditing.elem_drafts[0] : null;

		switch (currentDraft) {
			case null:
				createElemDraft({
					targetElemID: elem.id, 
					newElemStyles,
					type: 'styles'
				});
				break;
			default:
				updateElemDraft({
					draftID: currentDraft,
					newElemStyles,
					type: 'styles'
				});
		}
	}

	handleFieldChange (props) {
		const { prop, value, obj = 'css' } = props;
		
		console.log('prop,value :', prop,value);

		const removeBlendMode = (()=>{
			if (prop !== 'color') return {};
			return {
				cssContainer: {
					"mix-blend-mode": "normal"
				}
			}
		})()

		if (prop && value ) {
			let prevState = this.state,
				newState = {
				...prevState,
				userEditorStyles: {
					...prevState.userEditorStyles,
					[obj]: {
						...prevState.userEditorStyles[obj],
						[prop]: value
					},
					...removeBlendMode
				}
			}
			console.log('newState :', newState);
			this.setState(newState)
                
			setTimeout(() => {
				console.log('this.state :', this.state);
			},2000)

            // this.setState(prevState => {
            //     let newState = {
            //         ...prevState,
            //         userEditorStyles: {
            //             ...prevState.userEditorStyles,
            //             [obj]: {
			// 				...prevState.userEditorStyles[obj],
			// 				[prop]: value
			// 			},
			// 			...removeBlendMode
            //         }
			// 	}
			// 	console.log('newState :', newState);
			// 	return newState;
            // }, () => {
			// 	console.log('this.state :', this.state);
			// })
		}
    }

	handleUserUpdate() {
		const { elemEditing } = this.props,
			{ newElemStyles, userEditorStyles} = this.state;
		
		this.updateStyles({ 
			elem: elemEditing, 
			newElemStyles: userEditorStyles
		})
	}	
	handleManualUpdate({obj, data}) {
		const {elemEditing} = this.props;
		
		this.updateStyles({ 
			elem: elemEditing, 
			newElemStyles: data
		})
		
	}
	render () {
		const { elemEditing, admin, media } = this.props, 
			{ elem_formats } = elemEditing,
			{ newElemStyles, manualEditorStyles, userEditorStyles } = this.state;
		
		let cssFields = (()=>{
			switch (admin) {
				case true:
					return <StyleFieldsAdmin 
						elem_formats={manualEditorStyles}
						handleChange={this.handleFieldChange.bind(this)}
						manualUpdate={this.handleManualUpdate.bind(this)}
					/>
				case false:
				default:
					return buildStyleFields({
							media,
							elem_formats: userEditorStyles, 
							handleChange: this.handleFieldChange.bind(this),
							handleUpdate: this.handleUserUpdate.bind(this)
						})
			}
		})()

		return (
			<div>
				<form>
					{ cssFields }					
				</form>
			</div>
		);
	}

}

export default connect<{}, IDipatchProps, StylesEditor_I>(
                            state => state,
                            { createElemDraft, updateElemDraft}
						)(StylesEditor)
						

