declare var API_HOST: string;
declare var API_HOST_WS: string;

export const SERVER_ROOT = API_HOST+'/api';
export const WEBSOCKET_ROOT = API_HOST_WS+'/api/ws';

export const AUTH_HEADERS = {
    headers: {
        authorization: localStorage.getItem('token')
    }
}

