// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__content-option___KoFcU{background-size:cover;background-position:center;background-repeat:no-repeat;height:60px;width:60px;margin:0 10px 0 10px}.index__list___o3we0{margin:0}.index__list___o3we0 .index__list-item___I6Knh{padding-top:10px;padding-bottom:10px;cursor:pointer}.index__list___o3we0 .index__list-item___I6Knh p,.index__list___o3we0 .index__list-item___I6Knh h5,.index__list___o3we0 .index__list-item___I6Knh h6{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/components/organisms/ContentGroupEditor/ui/index.scss"],"names":[],"mappings":"AAGA,+BACI,qBAAA,CACA,0BAAA,CACA,2BAAA,CACA,WAAA,CACA,UAAA,CACA,oBAAA,CAGJ,qBACI,QAAA,CAEA,+CACI,gBAAA,CACA,mBAAA,CACA,cAAA,CAEA,qJACI,eAAA","sourcesContent":["\n\n\n.content-option {\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    height: 60px;\n    width:60px;\n    margin: 0 10px 0 10px;\n}\n\n.list {\n    margin: 0;\n    \n    .list-item {\n        padding-top:10px;\n        padding-bottom:10px;\n        cursor: pointer;\n\n        p, h5, h6 {\n            margin-bottom: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-option": `index__content-option___KoFcU`,
	"list": `index__list___o3we0`,
	"list-item": `index__list-item___I6Knh`
};
export default ___CSS_LOADER_EXPORT___;
