import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import styles from './index.scss';

import { TickerBoard_I } from '../interfaces'

interface State extends TickerBoard_I {}

class TickerBoard extends Component<TickerBoard_I, State> {

	static defaultProps = {
		title:'',
		tallys: []
	}

	renderTallys ({tallys}) {
		let width = 100 / tallys.length;
		return tallys.map((tally, i) => {
			return (
				<div key={i} className="columns" style={{ width: width+'%', float:'left' }} >
					<h5>{tally.title}</h5>
					<p>{tally.count}</p>
				</div>
			)
		})
	}
	render () {
		const {tallys, title} = this.props;
		return (
			<div>
				<h4>{title}</h4>
				<div className="row">
				{ tallys.length ? this.renderTallys({tallys}) : '' } 
				</div>
			</div>
		);
	}
}

export default TickerBoard;
