import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import styles from './index.scss';

import ViewTitles from 'src/components/molecules/ViewTitles';
import {ViewTitles_I} from 'src/components/molecules/ViewTitles/interfaces';
import InputText from 'src/components/atoms/InputText';
import { InputTextProps_I } from 'src/components/atoms/InputText/interfaces'
import Button from 'src/components/atoms/Button';
import { Button_I} from 'src/components/atoms/Button/interfaces';
import FormPrompts from 'src/components/molecules/FormPrompts';
import { FormPromptsProps_I } from 'src/components/molecules/FormPrompts/interfaces';
import AuthForm from 'src/components/organisms/AuthForm';
import  { Project_I } from 'src/data/project/interfaces';
import withProject from 'src/data/project/containers' 
import withProjects from 'src/data/project/containers/projects' 
import withPage from 'src/data/page/containers' 
import withPageTemplates from 'src/data/pageTemplate/containers/pageTemplates'
import {User_I} from 'src/data/user/interfaces';
import { fetchOne } from 'src/data/project/actions'

import clientViewStates  from 'src/shared/constants/viewStates';
const projectViewStates = clientViewStates.viewStates; 
 
import * as componentConfig from './config';

import VigPreview from 'src/components/atoms/VigPreview'
import TreeMenu from 'src/components/organisms/TreeMenu/ui'
import { PageWithVIG_I } from 'src/data/page/interfaces'
import { createNewVIG } from 'src/data/vig/actions'
import { fetchAll as fetchAvailableTemplates } from 'src/data/pageTemplate/actions'
import { TimeStamps } from 'src/components/molecules/TimeStamps/ui';
import Spinner from 'src/components/atoms/Spinner/ui';
import { simpleID } from 'src/shared/utils';

// import { ImagePreview } from 'src/components/organisms/SecondarySideNav/ui'
import { NavSubSection } from 'src/components/organisms/SecondarySideNav/ui'

interface Props {
	currentViewState?:string;
	viewTitles?: ViewTitles_I
	page?: PageWithVIG_I
	vigs?: any
	viewing?: string
	fetchAvailableTemplates?: Function
	pageTemplates?:any
	createNewVIG?: Function
	project?:any
}

interface State extends Props{
	formPrompts?:FormPromptsProps_I;
	viewState?: object
	viewTitles:ViewTitles_I
	treeData?: any
	newVIGSelecting?: boolean
}

interface VigTable_I {
	vigs?:Array<any>
	projectID?: string
}

const VigTable:React.StatelessComponent<VigTable_I> = (props: VigTable_I) => {
	const { vigs, projectID } = props;

	// if (!vigs.length) return  <h3>No vigs built yet :(</h3>;
	if (!vigs.length) return  <div></div>;

	const renderRows = vigs => {
		return (
			<div className="grid-x align-left align-middle">
				{vigs
				.sort((a,b)=> {
					if (a.updated_at > b.updated_at) return -1;
					if (a.updated_at < b.updated_at) return 1;
					return 0;
				})
				.map(vig => {
				let { status, path_thumbnail, id, updated_at, project_page, attemptID, failedBuild } = vig,
					key = id || attemptID || simpleID();
					
					switch (status) {
						case 'pending':
							return (
								<div className={`${styles['vig-cell']} cell medium-3`} key={key}>
									<Spinner active="true" />
								</div>
							);		
						case 'error': 
							const { name } = failedBuild;
							return (
								<div key={key} className={`${styles['vig-cell']} cell medium-3`}>
									<p className="error-text">Sorry! This page doesn't have enough content for the '{name}' VIG.</p>
								</div>
							);		
						default:
							return (
								<div className={`${styles['vig-cell']} cell medium-3`} key={key}>
									<Link to={`/project/${projectID}/page/${project_page}/vig/${id}`}>
										{ path_thumbnail ? <img src={path_thumbnail} /> : '' } 
										{ name ? <h3>{name}</h3> : ''}
										<TimeStamps 
											size="tiny"
											times={[{
												label: 'Updated',
												timeDate: updated_at,
												format: "date"
											}]}
										/>
									</Link>
								</div>
							);
					}
				})}
			</div>
		);
	}

	return <div>{renderRows(vigs)}</div>;
}

class PageView extends Component<Props & RouteComponentProps<any>, State>  {	
	state:State = componentConfig.defaultState;
	
	static getDerivedStateFromProps(newProps, prevState) {      
		const { page = {}, project = {}, history, vigs = [], pageTemplates= [] } = newProps;

		if (page.error) {
			history.push('/');
		// } else if (vigs.length) {
		// 	// DUMMY!! push to first VIG for now so I dont have to build PageView UI
		// 	let firstMatch = vigs.find(({project_page})=> page.id === project_page);
		// 	if (firstMatch) {
		// 		history.push(`/project/${page.project}/page/${page.id}/vig/${firstMatch.id}`);
		// 	}
		} else {
			if (page.id) {
				let props = {
					page
				}
			}
		}

		return {
			...newProps,
			viewing: page.viewing || 'info',
			// ...newVIGSelecting
		};
	}

	renderViewTitles ({page}) {
		if (page) {
			return  (
				<ViewTitles 
					primary={page.url_project_root}
					secondary={page.url_path}
					position={'left'}
				/>
			);
		} 
	}

	vigSelectOpen = state =>{
		this.setState({newVIGSelecting: state})
	}

	renderBody = (viewing) => {
		const { page, pageTemplates, createNewVIG, vigs, project } = this.props,
			{ scraped } = page,
			{ newVIGSelecting } = this.state;

		switch (viewing) {
			case 'info':
				if (!scraped) {
					return (
						<div className="grid-x align-left">
							<div className="cell">
								<h4>Page isn't populated</h4>
								<Button label={{text:"Populate Now"}}/>
							</div>
						</div>
					)
				}
			case 'vigs':
				const { all_vigs } = page,
					vigsExist = (all_vigs && all_vigs.length) || vigs.length;
				const vigTable = (()=>{
					if (vigsExist) {
						const props = { 
							projectID: project.id,
							vigs: [all_vigs,vigs].reduce((acc,v)=> {
								if (!v || !v.length) return acc;
								return [...acc, ...v];
							},[])
						};
						return (
							<VigTable {...props} />	
						)
					} 
				})()

				const newVig = (()=>{
					if (newVIGSelecting || (newVIGSelecting === null && !vigs.length)) {
						let {publicTemplates, customTemplates } = pageTemplates.reduce((acc,template)=> {
							let {id, name, preview, custom_template} = template;
							let elem =  <NavSubSection
								key={id}
								onClick={()=> createNewVIG({ pageID: page.id, templateID: id })}
								thumbnail={preview ? preview : 'https://s3.amazonaws.com/open-gif/placeholders/placeholder-vig.jpg'}
								hoverContent={`
									<div class="grid-x align-center align-middle" style="height: 100%; padding:5px">	
										<h6 class="cell text-center white-text">
										${name}
										</h6>
									</div>
								`}
								width={'20%'}
								inActiveWidth={'150'}
							/>;
							if (custom_template) return {...acc, customTemplates:[...acc.customTemplates, elem]};
							return {...acc, publicTemplates:[...acc.publicTemplates, elem]};
						},{publicTemplates:[], customTemplates:[]});

						return (
							<div className={`${styles['template-options']} grid-x`}>
								<h6 style={{textTransform:'uppercase', fontWeight:'bold'}} className="cell grow">
									{ vigsExist ? 'Select a template' : 'Select your first template'}
								</h6>
								{customTemplates.length ? <h6>Custom</h6> : ''}
								<div className="cell grid-x align-left align center">
									{customTemplates}
								</div>
								<h6>General</h6>
								<div className="cell grid-x align-left align center">
									{publicTemplates}
								</div>
							</div>
						)
					}
				})()

				return (
					<div>
						<div className="grid-x grid-margin-x align-left align-middle">
							<h3 className="cell shrink" style={{marginRight:'20px'}}>VIGs</h3>
							{ !newVIGSelecting && vigsExist ?
								<Button 
									label={{text: 'create new vig',iconName:'add', iconPosition:'icon-right'}}
									action={()=> this.vigSelectOpen(true)}
									size="small"
									shape="round"
								/>
								: ''
							}
							{ newVIGSelecting && vigsExist ?
								<Button 
									label={{iconName:'close', iconPosition:'icon-right'}}
									action={()=> this.vigSelectOpen(false)}
									size="small"
									status="filled"
									color="error"
									padding="no-padding"
									// shape="round"
								/>
								: ''
							}
						</div>
						{ newVig }
						{ vigTable }
					</div>
				)
		}
	}
	render () {
		return(
			<div className="grid-x">
				<div className="cell small-10 small-offset-1">
					<div className={`grid-x grid-margin-x ${styles['project-container']}`}>
						<div className="cell small-12">
							<h2>{ this.renderViewTitles({page: this.props.page}) }</h2>
							{ this.renderBody(this.state.viewing) }
						</div>
					</div>
				</div>
			</div>
		);	
	}
}



function mapStateToProps(state:any ) {
	return state;	
}

export default withPageTemplates(
					withProjects(
						withProject(
							withPage(
									withRouter(
										connect<{},Props>(
											mapStateToProps,
											{ fetchAvailableTemplates, createNewVIG },
											null,
											{pure: false }
										)(PageView)
									
								)
							)
						)	
					)
				);	



