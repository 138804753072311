import * as React from 'react';
import { connect,  DispatchProp } from 'react-redux'
// import { setViewDefaults } from 'src/data/quickStart/actions';
// import * as quickStartActions from 'src/data/quickStart/actions';
import { 
  BrowserRouter as  Router, 
  Redirect, 
  RouteComponentProps, 
  Route, 
  withRouter 
} from 'react-router-dom';
import clientViewStates  from 'src/shared/constants/viewStates';
const projectViewStates = clientViewStates.viewStates; 
import { validateNewProject, updateQuickStart } from 'src/data/project/actions';


export interface DispatchProps {
  setViewDefaults: (viewDefaults: object) => any;
}

interface Props extends React.Props<any>, DispatchProps {
  isLoggedIn?:Boolean;
  currentViewState?:string;
  mapStateToProps?:Function
}

interface State extends Props {
  state?: any
}

export function quickStartAuth(WrappedComponent:any) {
    const Container:any =  class extends React.Component<Props & RouteComponentProps<any>,State> {
    
    static displayName = `WithQuickStartAuth(${WrappedComponent.displayName  || WrappedComponent.name})`;

    renderWrappedComponent() {
      return  <WrappedComponent { 
          ...this.props
      } />
    }

    render() {
      return (
        <div>
        {this.renderWrappedComponent()}
        </div>
      );
    }

  }
  function mapStateToProps (state:any) {
    return {
      state
    }
  }
  return connect<{},Props>(mapStateToProps, null)(Container);
}
