import { createImageBuffer } from '../multimedia'
import * as storedFunctions from './storedFunctions'

export async function buildFrame({vigLayers}) {
    let frameFormats = vigLayers.reduce((acc, layer) => {
        return {
            cssString: acc.cssString + layer.cssString,
            htmlString: acc.htmlString + layer.htmlString,
            layerAnimations: [
                ...acc.layerAnimations,
                {
                    startOffset: layer.start_offset,
                    startAbsolute: layer.start_absolute,
                    duration: layer.duration,
                    fragments: layer.jsAnimations
                }
            ]
        }
    },{
        cssString:'',
        htmlString:'',
        layerAnimations:[]
    })
    return frameFormats;
}
  
export async function reduceContentGroups({ layer, layerIndex, vigID, useBinaryImg }) {
    let contentGroups = layer.content_groups || layer.vig_content_groups;

    let groupProps = await Promise.all(contentGroups.map(async (group, index) => {
        let uiID = `layer-${layerIndex}-group-${index}`;
        let groupProps = await setContentGroupVIGProps({
            group,
            groupuiID: uiID,
            vigID,
            useBinaryImg
        });
        
        return {
            cssString: groupProps.cssString,
            htmlString:  groupProps.htmlString,
            jsAnimations: groupProps.jsAnimations
        }
    }));
    return groupProps.reduce((acc:any, props:any) => {
        return {
            cssString: acc.cssString + props.cssString,
            htmlString: acc.htmlString + props.htmlString,
            jsAnimations: [...acc.jsAnimations, ...props.jsAnimations]
        }
    },{
        cssString:'',
        htmlString:'',
        jsAnimations:[]     
    })
}

async function setContentGroupVIGProps({group, groupuiID, vigID, useBinaryImg}) {
   let containerFormats = group.container_formats || {};
    
    let cssPropsContainer;
    if (containerFormats.css) {
        cssPropsContainer = cssObjToString({cssObj: containerFormats.css})
    }
    let cssBefore = (()=> {
        if (!containerFormats.cssBefore) return '';
        let res = cssObjToString({cssObj: containerFormats.cssBefore})
        console.log('res :', res);
        return res
    })();

    let elemProps = await reduceElemProps({group, groupuiID, vigID, useBinaryImg});

    let htmlString;
    if (group.content_group_render) {
        let renderFunc  = eval(group.content_group_render);
        htmlString = renderFunc({elems: elemProps.elemsWithHTML, groupuiID});
    } else {
        htmlString = `<div data-id="${groupuiID}">${elemProps.htmlString}</div>`;
    }

    return {
        jsAnimations: [
            {
                key: groupuiID,
                animations: containerFormats.animations
            },
            ...elemProps.jsAnimations
        ],
        cssString: ` 
            #${vigID} [data-id="${groupuiID}"] { ${cssPropsContainer || ''} } 
            #${vigID} [data-id="${groupuiID}"]::before { ${cssBefore} } 
            ${elemProps.cssString}
        `,
        htmlString
    }
}

async function reduceElemProps({group, groupuiID, vigID, useBinaryImg }) {
    let elemsWithHTML = [],
        elems = group.elems || group.vig_content_group_elems,
        elemsStandardized = elems.map(elem=> {
            return {
                ...elem,
                multimediaObj: elem.multimediaObj || elem.multimedia_obj
            }
        })
    
    let elemProps = await Promise.all(
        elemsStandardized.map(async (elem, i) => {
            let uiID = (()=> {
                let base = `${groupuiID}-elem-${i}`,
                    electives = ['name','elem_family','elem_type'].reduce((acc,key)=> {
                        if (elem[key]) return acc + '-'+ key+'-'+elem[key];
                        return acc;
                    },'');
                return base+electives;
            })();

            let elemFormats = await setElemVIGProps({
                elem: {...elem, uiID }, 
                groupFormats: group.group_elem_formats,
                vigID,
                useBinaryImg
            });
            // ugly side effect!!
            elemsWithHTML.push({
                ...elem,
                htmlString: elemFormats.htmlString
            })
            return {
                cssString: elemFormats.cssString,
                jsAnimations: elemFormats.jsAnimations.map(anim=> {
                    return {...anim, elemData: elem}
                }),
                
            }
        })
    )

    let htmlString = elemsWithHTML.reduce((acc, elem) => {
        return acc + elem.htmlString;
    },''); 

    let elemStyles:any = elemProps.reduce((acc:any, elem:any, i) => {
        return {
            cssString: acc.cssString + elem.cssString,
            jsAnimations: [...acc.jsAnimations, ...elem.jsAnimations],
        }
    },{
        cssString: '',
        jsAnimations: []
    })

    return {
        htmlString,
        cssString: elemStyles?.cssString,
        jsAnimations: elemStyles?.jsAnimations,
        elemsWithHTML
    }
}


async function setElemVIGProps({elem, groupFormats, vigID, useBinaryImg = true} ) {
    let formats = elem.elem_formats;

    let cssProps = (formats.css) ? cssObjToString({cssObj: formats.css}) : '';

    let cssPropsContainer = (formats.cssContainer) ? cssObjToString({cssObj: formats.cssContainer}) : '';

    let cssBefore = (()=> {
        if (!formats.cssBefore) return '';
        return cssObjToString({cssObj: formats.cssBefore})
    })();

    let cssBeforeContainer = (()=> {
        if (!formats.cssBeforeContainer) return '';
        return cssObjToString({cssObj: formats.cssBeforeContainer})
    })();

    let htmlString = await (async (elem) => {
        if (elem.elem_render) {
            let renderFunc  = eval(elem.elem_render);
            return renderFunc({elem, storedFunctions});
        } 

        let elemWithImage = await (async (elem, useBinaryImg) => {
            if (!elem.multimediaObj || !elem.multimediaObj.s3_path) return elem;
            
            let src = await (async () => {
                if (!useBinaryImg) return elem.multimediaObj.s3_path;
                let bufferData = await createImageBuffer(elem.multimediaObj.s3_path);
                return 'data:image/jpeg;base64,' + bufferData.toString(); // TS wouldn't allow 'base64' option?
            })()
            
            return { ...elem, src }
        })( elem, useBinaryImg );

        return elemHtmlDefaults({ elem: elemWithImage });
    })(elem);


    return {
        jsAnimations: [{
            key: elem.uiID,
            animations: formats.animations
        },{
            key: elem.uiID+'-container',
            animations: formats.animationsContainer
        }],
        cssString: `
           #${vigID} [data-id="${elem.uiID}"] { ${cssProps || ''} }
           #${vigID} [data-id="${elem.uiID}"]::before { ${cssBefore} } 
           #${vigID} [data-id="${elem.uiID}-container"] { ${cssPropsContainer || ''} } 
           #${vigID} [data-id="${elem.uiID}-container"]::before { ${cssBeforeContainer || ''} } 
        `,
        htmlString
    }
}

function elemHtmlDefaults({elem}) {
    switch (elem.elem_family) {
        case 'text':
            return text(elem);
        case 'image':
            switch (elem.elem_type) {
                case "inline":
                case "inLine":
                    return inlineImage(elem);
                case "background":
                    return backgroundImage(elem);
                default:
                    return backgroundImage(elem);
            }
        case 'shape':
            return emptyDiv(elem);
        case 'graph':
            return emptyDiv(elem, true);
        case 'map':
            return emptyDiv(elem, true);
        case 'special_elem':
            if (!elem.multimediaObj) {
                console.log('error finding multimedia object! :', elem);
                return '<div></div>';
            }
            switch (elem.elem_type) {
                case 'logo':
                    // test if multimedia is image or domNode (svg probably), very ugly but requires refactoring all of multimedia objects to be more elegant
                    if (elem.multimediaObj.s3_path) return inlineImage(elem);
                    if (elem.multimediaObj.node) return domNode(elem);
                    return domNode(elem)
                case 'email':
                    return text(elem);
                case 'phone_number':
                    return text(elem);
                case 'url':
                    return text(elem);
                default:
                    console.log('error finding multimedia object for special_elem! :', elem);
                    return '<div></div>';
            }
    }
    
    function backgroundImage({ uiID, src }) {
        if (!src) return null;
        return `
            <div data-id="${uiID}-container">
                <div data-id="${uiID}" style="background-image:url(${src || ""});background-size:cover;background-repeat:no-repeat;"></div>
            </div>`;
    } 
    function inlineImage({ uiID, src }) {
        if (!src) return null;
        return `
            <div data-id="${uiID}-container">
                <img data-id="${uiID}" src="${src  || ""}" />
            </div>`;
    }
    function text({ uiID, multimediaObj }) {
        let text = multimediaObj && multimediaObj.text ? multimediaObj.text : 'error finding text!';
        if (!text) return null;
        return `
        <div data-id="${uiID}-container">
            <p data-id="${uiID}">${text || ""}</p>
        </div>`;
    };

    function domNode({ uiID, multimediaObj:{node} }) {
        if (!node) return null;
        return `
        <div data-id="${uiID}-container">
            <div data-id="${uiID}">
                ${node || ""}
            </div>
        </div>`;
    };

    function emptyDiv({ uiID }, withId = false) {
        return `
        <div data-id="${uiID}-container">
            <div data-id="${uiID}" ${withId ?'id="'+uiID+'"' : '' }>
            </div>
        </div>`;
    };
}

function cssObjToString({cssObj}) {
    return Object.keys(cssObj).reduce((acc, prop) => {
        return `${acc} ${prop} : ${String(cssObj[prop])};`;
    },'');
}
