import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.scss';
import { FormPromptsProps_I } from '../interfaces';

/*
	- block sizing
		- either by placeholder or prompts?
			if placeholder, 
				1. pull dimensions of placeholder 
				2. make container fixed dimenions
				3. drop in promt-body
				4. make relative dimensions

				OR 

				* just set min-height to placeholder dimensions, leave width auto
*/

interface Placeholder {
	elem?: HTMLElement;
	height?: string;
}

interface State extends FormPromptsProps_I {
	placeholderElem?: Placeholder
}

class FormPrompts extends Component<FormPromptsProps_I, State>  {

	static defaultProps = {
		primary: '',
		secondary: '',
		placeholder: '',
		popOver: {
			body: ''
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return Object.assign(prevState, nextProps)
	}

	state:State = {		
		status: 'placeholder',
		popOver: {
			active: false
		},
		placeholderElem: {
			height:'auto'
		}
	}

	componentDidMount () {	
		this.setState((prevState) => {
			return Object.assign(prevState, this.props);
		});
		((callback) => {
			setTimeout(() => {
				window.requestAnimationFrame(()=>{
					this.state.placeholderElem.height = this.state.placeholderElem.elem.clientHeight + 'px';
				})
			}, 1000)
		})();

	}
	
	togglePopOver = () => {
		this.setState((prevState) => {
			Object.assign(
				prevState,
				{
					popOver: {
						active: !prevState.popOver.active 
					}
				}
			)
		})
	}
    handleOnSubmit = (e:any) => {}
	render () {
		return(
			<div
				className={`${styles['prompt-container']} grid-x align-center align-middle`}
				data-status={this.state.status}
				// style={{height: this.state.placeholderElem.height}}
			>
				<div
					className={`${styles['prompt-body']}`}
				>
					
					<i 
						className={`${styles['info-icon']}`}
						onClick={this.togglePopOver}
					></i>	

					<h5 
						className={`${styles['primary-prompt']}`}
					>{this.props.primary}</h5>
					<h6
						className={`${styles['secondary-prompt']}`}
					>{this.props.secondary}</h6>

					<div
						className={`${styles['popover-body"']}`}
						dangerouslySetInnerHTML={{__html: this.props.popOver.body}}
					></div>
				</div>
				<div 
					className={`${styles['prompt-placeholder']}`}
					ref={ (placeholder) => this.state.placeholderElem.elem = placeholder}
					dangerouslySetInnerHTML={{__html: this.props.placeholder}}
				></div>
			</div>
		);	
	}
}

export default FormPrompts;



