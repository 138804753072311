import {
    REGISTER_USER
} from '../../user/actions/types'
import { FETCH_PAGES, FETCH_PAGE, START_PAGE_POPULATE, END_PAGE_POPULATE  } from 'src/data/page/actions/types';
import { ADD_NEW_PAGE } from 'src/data/project/actions/types'

export function pagesReducer(state = [], action) {
    // if (!action.payload || !action.payload.pages || !action.payload.pages.length ) return state;
    switch (action.type) {
        case REGISTER_USER:
            return [
                ...state, 
                ...action.payload.pages
            ]
        case FETCH_PAGES: 
            return action.payload.pages;
        case START_PAGE_POPULATE:
            let updating = state.find(({id}) => id  === action.payload.pageID);
            if (!updating) return state;
            return state.map(pg=> {
                if (pg.id !== updating.id) return pg;
                return {
                    ...pg,
                    is_scraping: true
                }
            })
        case END_PAGE_POPULATE:
            let { populatedPage } = action.payload;
            if (!populatedPage) return state;
            return state.map(pg=> {
                if (pg.id !== populatedPage.id) return pg;
                return populatedPage;
            })
        case ADD_NEW_PAGE:
            let { newPage } = action.payload;
            return [...state, newPage];
        default:
            return state
    }
}

export function pageReducer(state = {}, action) {
    switch (action.type) {
        case REGISTER_USER:
            return {
                ...state, 
                ...action.payload.page
            }
        case FETCH_PAGE:
            const { all_vigs, ...page} = action.payload.page;
            return page;
        default:
            return state
    }
}