import * as React from 'react';
import { Component } from 'react';
import styles from './index.scss';

import { TimeStamps_I } from '../interfaces'
import { formatTime } from 'src/shared/utils'

export const TimeStamps: React.StatelessComponent<TimeStamps_I> = props => {
	const { title, size = 'medium', times = [] } = props; 

	return (
		<div  className={`${styles['time-stamps']} grid-x small-12`} data-size={size}>
			{ title ? <h4>{title}</h4> : '' }
			<div className="grid-x cell">
				{ times.length ? renderTimeStamps({ times }) : '' } 
			</div>
		</div>
	);
}

function renderTimeStamps({times}) {
	let width = 100 / times.length;

	return times.map((time, i) => {
		let {format, timeDate, label} = time; 
		let labelParsed = (()=>{
			if (!label) return null;
			let noUnderscore = label.replace('_',' ');
			return noUnderscore;
		})()
		return (
			<div key={i} className={`${styles['stamp-holder']} cell`} style={{ width: width+'%', float:'left' }} >
				{ labelParsed ? <h5 style={{textTransform:'capitalize'}}>{labelParsed}</h5> : '' }
				{ timeDate ? <p>{formatTime({format , timeDate})}</p> : '' }
			</div>
		)
	})
}

