import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { MainSideNav_I, NavSection_I, NavSubSection_I, NavLink_I } from '../interfaces';
import { Link } from 'react-router-dom'
import styles from './index.scss';
import WithNavHOC from 'src/data/nav/containers/index';

const NavLink: React.StatelessComponent<NavLink_I> = (props:NavLink_I) => {
	const { target, label, active }: NavLink_I  = props;

	return(
		<div className={`${styles['nav-link']}`} data-active={active}>
			<Link to={target|| '/'}>{label}</Link>
		</div>
	);	
}

const NavSubSection: React.StatelessComponent<NavSubSection_I> = (props:NavSubSection_I) => {
	const { links, label, active,target }: NavSubSection_I  = props;

	const renderLinks = () => {
		if (links && links.length){
			return links.map((navLink:NavLink_I, i) => {
				return (
					<NavLink {...navLink} key={i} />
				)
			})	
		}
	}
	return(
		<div className={`${styles['nav-sub-section']}`}>
			{/* <h5>{label}</h5> */}
			{ target ? <Link to={target}><h5>{label}</h5></Link> :<h5>{label}</h5> }
			<div className="row">
				{renderLinks()}
			</div>
		</div>		
	);	
}

const NavSection: React.StatelessComponent<NavSection_I> = (props:NavSection_I) => {
	const { subSections, label, active, collapsed, target = '' }: NavSection_I  = props;

	const renderSubSections = () => {
		if (subSections && subSections.length) {
			return subSections.map((subSection:NavSubSection_I, i) => {
				return (
					<NavSubSection {...subSection} key={i} />
				)
			})
		}
	}

	return(
		
		<div className={`${styles['nav-section']}`}  data-active={active}>
			{ !collapsed ? <Link to={target}><h3>{label}</h3></Link> : <Link to={target}><h5>{label}</h5></Link> }
			<div className="row">
				{renderSubSections()}
			</div>
		</div>		
	);	
}

class MainSideNav extends Component<MainSideNav_I, {}> {
	static defaultProps = {
		sections: [],
		navType: "mainSideNav",
		isCollapsed: false
	};

	renderSections ({sections, isCollapsed}) {
		if (sections && sections.length) {
			return sections.map((section:NavSubSection_I, i) => {
				return (
					<NavSection {...section} collapsed={isCollapsed} key={i} />
				)
			})
		}
	}

	render () {
		const { active, isCollapsed, sections } = this.props;
		return (
			<nav 
				className={`${styles['main-side-nav']}`}
				style={active ? {opacity:1, visibility:'visible'} : {opacity:0, visibility:'hidden'}}
				data-collapsed={isCollapsed}
			>
				{this.renderSections({sections, isCollapsed})}
			</nav>
		) 
	}
}

export default WithNavHOC(MainSideNav);