import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchOneVig } from '../actions';
import * as qs from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface VigHOC_I {
  draft?:String;
  vig?:any
}
interface IDipatchProps {
  fetchOneVig?: Function
}
const VigHOC = (WrappedComponent:any) => {
  const Container:any = class extends Component<VigHOC_I & IDipatchProps & RouteComponentProps<any>, {}> {
    fetchingLayer = false;
    fetchingVig = null;

    static displayName = `VigHOC(${WrappedComponent.displayName  || WrappedComponent.name})`;

    componentWillReceiveProps(nextProps) {
      let { match, fetchOneVig, history, location , vig } = this.props,
          params:any = {}, queries = {};
        
      if (match && match.params) {
        params = match.params;
      }

      if (location && location.search) {
        queries = qs.parse(
          location.search, 
          // { ignoreQueryPrefix: true }
        );
      }
      // should isolate layer view changes into another container!!
      if (
        !vig.data
        || !vig.data.id
        || !nextProps.match.params 
        || !nextProps.match.params.vigID 
        || vig.data.id !== params.vigID
        || (!vig.data.layerViewing && params.layerID)
        || (vig.data.layerViewing !== params.layerID) ) {
        
        if (this.fetchingVig !== params.vigID || (params.layerID && this.fetchingLayer !== params.layerID)) {
          
          this.fetchingVig = params.vigID;
          this.fetchingLayer = params.layerID || null;
          fetchOneVig({ 
            id: params.vigID, 
            layerViewing: params.layerID || null,
            ...params, 
            queries
          });
        }
      }
    }

    render() {
      return (
        <WrappedComponent {...this.props} />
      )
    }
  }

  return withRouter( 
            connect<{}, IDipatchProps, RouteComponentProps<any>>(
            (state) => state, 
            { fetchOneVig }
          )(Container)
        )
}

export default VigHOC;
