import { Button_I} from 'src/components/atoms/Button/interfaces';
import {ViewTitles_I} from 'src/components/molecules/ViewTitles/interfaces';
import { FormPromptsProps_I } from 'src/components/molecules/FormPrompts/interfaces';
import {Project_I} from 'src/data/project/interfaces';
import {User_I} from 'src/data/user/interfaces';

export const defaultProps: {
        authButtonStyles:Button_I,
        authButtonData:Button_I[]
     } = {
        authButtonStyles: {
            color:'primary',
            shape:'square',
            size:'large',
            width:'small-4'
        },
        authButtonData: [
            { label:{ text: 'Login', }},
            { label:{ text: 'Register', }}
        ]
};

export const defaultState: {
        viewTitles:ViewTitles_I,
        formPrompts:FormPromptsProps_I,
        projectButton: Button_I,
        user: User_I,
        currentViewState:string
        treeData?: any,
        newVIGSelecting: boolean
    } = {
        viewTitles: { 
            primary: "Quick, start your first project...",
            secondary: "",
            position: "center" 
        },
        formPrompts: {
            status: 'placeholder',
            placeholder: `<div class="spacer-inline spacer40"></div>
                            <h4 class="text-center">OR</h4>
                        <div class="spacer-inline spacer40"></div>
            `,
        },
        projectButton: {
            status: 'visible', // only need 'status' to be held in state
            color:'primary',
            shape:'round',
            size:'large',
            width: 'full-width',
            label: {
                text: 'Create Project',
                transform:'text-uppercase',
                weight: 'text-light'
            },
        },
        treeData: null,
        user: {
            id: '',
            username:'',
            email:'',
        },
        currentViewState: 'validating',  // this needs to be set in Route guards
        newVIGSelecting: null 
};
