export function rankToStars({rank, color }) {
    let full = Math.floor(rank),
    half = rank % 1 !== 0 ? true : false,
    empty = 5 - Math.ceil(rank),
    str = "";
        
    for (let i = 0; i < full; i++) {
    str += `<i class="material-icons" style="color:${color}">star</i>`;
    }
    if (half) {
    str += `<i class="material-icons" style="color:${color}">star_half</i>`;
    }
    for (let i = 0; i < empty; i++) {
    str += `<i class="material-icons" style="color:${color}">star_border</i>`;
    }
    return str;
} 


export function capitalizeSentences({text}) {
    return text.split('.')
            .map(sentence=> {
                let trimmed = sentence.trim();
                return trimmed.slice(0,1).toUpperCase()
                       + trimmed.slice(1).toLowerCase();
            })
            .join('. ');
}