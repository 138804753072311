import {
    REGISTER_USER
} from '../../user/actions/types'
import {
    FETCH_PROJECT
} from '../../project/actions/types'
import { FETCH_PAGE } from '../../page/actions/types'

let defaultState = {
    mainSideNav: {
        visible: false
    },
    secondarySideNav: {
        visible: false
    },
    topNav: {
        visible: true
    }
}
export default function viewTemplateReducer(state:any = defaultState, action) {
    switch (action.type) {
        case REGISTER_USER:
            return {
                ...state, 
                ...action.payload.viewTemplate,
                viewTitles: {
                    ...state.viewTitles,
                    ...action.payload.viewTemplate.viewTitles
                }
            }
        case FETCH_PROJECT:
            return {
                ...state,
                mainSideNav: {
                    visible: true
                },
                // secondarySideNav: {
                //     visible: false
                // },
                ...action.payload.viewTemplate
            }
        case FETCH_PAGE:
            return {
                ...state,
                mainSideNav: {
                    visible: true
                },
                secondarySideNav: {
                    visible: true
                },
                ...action.payload.viewTemplate
            }
        default:
            return state
    }
}