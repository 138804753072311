import * as React from 'react';
import { Component } from 'react';
import styles from './index.scss';
import Dropzone from 'react-dropzone';
import { bindActionCreators } from '../../../../../node_modules/redux';
import Button from 'src/components/atoms/Button'

interface InputFile_I {
    input?: any
    data64bit?: any 
    change?: any
    meta?: any
    accept?: any
    multiple?: any
    onChange?: Function
    onBlur?: Function
    selected?: any
    onAccept?:Function
}

// Important: react-dropzone doesn't manage dropped files. You need to destroy the object URL yourself whenever you don't need the preview by calling window.URL.revokeObjectURL(file.preview); to avoid memory leaks.

/**
 * file field that supported dnd
 */
export default class InputFile extends Component <InputFile_I, {}> {
    state = {
        selected: null,
        dropzoneKey: Math.random()
    }

  handleDropOrClick = (acceptedFiles, rejectedFiles, e) => {
    let eventOrValue = e;
    let { input, onChange, onBlur } = this.props;
    
    let file = (()=> {
        if (e.type === 'drop')  {
            if (!acceptedFiles.length) return null;
            // FileList or [File]
            return acceptedFiles[0];
        }
        if (e.target && e.target.files && e.target.files.length) return e.target.files[0];
        return null;
    })()

    console.log('file :', file);
    
    if (file) {
        this.setState({
            selected: file
        })
        onChange(file);
    }
    // onBlur(eventOrValue); // update touched
    // onChange(eventOrValue); // update value
  }
  clearDropzone () {
    this.setState({
      selected:null
    })
  }
  render() {
    let {input, 
        // data64bit, 
        // change, 
        // meta: {touched, error}
    } = this.props,
    { selected } = this.state;
    
    // if (data64bit && input && input.value && input.value[0]) {
    //   let file = input.value[0];
    //   let reader = new FileReader();
    //   reader.onload = event => {
    //     if (data64bit === "only") {
    //       change(input.name,reader.result);
    //       console.log(this);
    //     } else {
    //       this.props.value.data64bit = reader.result;
    //     }
    //   }
    //   reader.readAsDataURL(file)
    // }
    let {accept, multiple, onAccept} = this.props;
    let selectedFile = (input && input.value && input.value[0]) || null;
    let dropzoneProps = {
      accept:".png, .jpg, .jpeg",
      multiple,
      onDrop: this.handleDropOrClick
    };
    
    return (
      <div className="grid-x small-12">
        <div className={`${styles['file-uploader']} grid-x small-12`}>
            {
              selected && selected.preview 
              ? <div className={`${styles.preview} cell small-12small-12 grid-x align-middle align-center`}>
                  <img src={selected.preview}/>
                  <h6>{selected.name}</h6> 
                </div> 
              : <div className={`${styles['change-prompt']} cell grid-x small-12 align-middle align-center`}>
                  <h5 className="cell small-12">Change Photo</h5>
                  <input 
                    type='hidden' 
                    id="theFile" 
                    disabled {...input} 
                  />
                  <p className="cell small-12">Drag Image or Click Here</p>
                  {/* <Dropzone 
                    key={this.state.dropzoneKey || Math.random() } 
                    className={`${styles.dropzone}`} 
                    {...dropzoneProps} 
                    /> */}
                </div>
            }
        </div>
        <div className="grid-x small-12 align-middle align-center">
          <Button 
            width="cell auto"
            label={{text: 'Save to Draft'}}
            status={ selected && selected.preview ? 'visible' : 'hidden'}
            action={ 
              () => {
                // this.clearDropzone.bind(this);
                onAccept();
              }
            }
          />
          <Button 
            width="cell small-2"
            label={{text: 'X'}}
            color="error"
            status={ selected && selected.preview ? 'filled' : 'hidden'}
            action={ this.clearDropzone.bind(this) }
          />
        </div>
      </div>
      );
  }
}