import * as React from 'react';
const reactElem = React.createElement;

import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import styles from './index.scss';

import { TreeNode_I } from '../interfaces'

const TreeNode: React.StatelessComponent<TreeNode_I & RouteComponentProps<any>> = props => {
	const {  
		title, 
		active, 
		children = [],
		childrenType = 'node_objects',
		size,
		thumbnail, 
		editPath,
		selectPath,
		onSelect,
		noConnector = false,
		createPath,
		pendingMsg
	}: TreeNode_I  = props,
	{ history } = props;

	const renderChildren = ({children, type}) => {
		if (children && children.length) {
			switch (type) {
				case 'node_objects':
					return children.map((child,i) => <TreeNode key={i} {...child} />);
				case 'dom_nodes':
					return children.map((child,i) => {return {...child, key: i} });
			}
			
		}
	}
	const renderThumbnail = ({thumbnail, link, createPath, pendingMsg} ) => {
		if (pendingMsg) {
			return (
				<div className={`grid-x ${styles.preview}`}>
					<p>{pendingMsg}</p>
				</div>
			);
		}
		if (thumbnail && link){ 
			return (
				<Link to={link || "/"}className={`grid-x ${styles.preview}`}>
					<img className={`cell shrink ${styles.thumbnail}`} src={ thumbnail } />
					<span className={`cell auto ${styles['edit-button']}`}>
						<i className="material-icons">edit</i>
					</span> 
				</Link>
			);
		}
		if (link){ 
			return (
				<Link to={link}  className={`grid-x ${styles.preview}`}>
					<span className={`cell auto ${styles['edit-button']}`}>
						<i className="material-icons">edit</i>
					</span> 
				</Link>
			);
		}
		if (createPath) {
			return (
				<div className={`grid-x ${styles.preview}`}>
					<span 
						className={`cell auto ${styles['edit-button']}`}
						onClick={ ()=> createPath() }
					>
						{/* <Link to={createPath} > */}
							<i className="material-icons secondary-text">build</i>
						{/* </Link> */}
					</span>
				</div>
			);
		}
	}
	const renderTitle = ({ size, title, link, onSelect }) => {
		let textTag = 'h4';
		switch (size) {
			case 'large':
				textTag = 'h3';
				break;
			case 'medium':
				textTag = 'h4';
				break;
			case 'small':
				textTag = 'h5';
				break;
			case 'tiny':
				textTag = 'h6';
				break;
		}
		let elem = reactElem(
						textTag, 
						{className:"cell shrink", style:{color: textTag === 'h3' ? '#2AB5CA' : '#000'}}, 
						title
					)
		if (link) {
			return <Link to={link || "/"}>{elem}</Link>
		}
		return elem;

		// return (
		// 			 reactElem(textTag, 
		// 		 	{ 
		// 			 className:"cell shrink",
		// 			 onClick: onSelect ? onSelect : ()=> {console.log('nope')} 
		// 			 }, title) 
		// )
	}
	return(
		<li 
			className={styles['tree-node']} 
			data-size={ size }
			data-no-connector={ noConnector }
		>	
			<div className={`grid-x ${styles.content}`}>
				{ renderTitle({size, title, link: selectPath, onSelect })}
				{ 
					renderThumbnail({
						thumbnail, //: thumbnail || 'http://www.thisiscolossal.com/wp-content/uploads/2014/03/120430.gif',
						link: editPath,
						createPath,
						pendingMsg
					}) 
				}
			</div>
			<ul className={styles['node-children']}>
				{ renderChildren({ children, type: childrenType }) }
			</ul>
		</li>		
	);	
}

export default withRouter(TreeNode);
