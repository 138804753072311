// tslint:disable
// this is an auto generated file. This will be overwritten

export const onCreateVigVideo = `subscription OnCreateVigVideo {
  onCreateVIGVideo {
    vigId
    userId
    fps
    vigDuration
    frameFormatsURL
    createdAt
  }
}
`;
export const onUpdateVigVideo = `subscription OnUpdateVigVideo($userId: String!)  {
  onUpdateVIGVideo(userId: $userId) {
    vigId
    userId
    fps
    vigDuration
    frameFormatsURL
    createdAt
  }
}
`;
export const onDeleteVigVideo = `subscription OnDeleteVigVideo {
  onDeleteVIGVideo {
    vigId
    userId
    fps
    vigDuration
    frameFormatsURL
    createdAt
  }
}
`;
export const onObjectComplete = `subscription OnObjectComplete($userId: String!)  {
  onObjectComplete(userId: $userId) {
    vigId
    vigUIID
    userId
    fps
    vigDuration
    frameFormatsURL
    createdAt
  }
}
`;