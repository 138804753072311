import { Button_I} from 'src/components/atoms/Button/interfaces';
import { InputTextProps_I } from 'src/components/atoms/InputText/interfaces'

export const authButtons:{
    [key:string]:Button_I
} = {
    loginSubmit: {
        label: { text: 'Login' }
    },
    loginRedirect:{
        label: { text: 'Login' }
    },
    registerFree:{
        label: { text: 'Free Account' }
    },
    registerPremium:{
        label: { text: 'Premium Account' }
    },
    recoverSubmit:{
        label: { text: 'Start Reset' }
    },
    resetSubmit:{
        label: { text: 'Reset Password' }
    },
    recoverRetry:{
        label: { text: 'Try Again' }
    },

}

export const authFields:{
    [key:string]:InputTextProps_I
} = {
    username: {
        type: 'username',
        label: {
            text:'Username'
        },
        validation: {
            // fieldPolicies:[
            //     {type:'required'}
            // ],
            execution: [
                'onBlur',
                'onChange'
            ], 
        },
        hasErrors:true
    },
    email: {
        type:'email',
        label: {
            text:'Email'
        },
        validation: {
            fieldPolicies:[
                // {type:'required'},
                {type:'email'}
            ],
            execution: [
                'onBlur',
                'onChange'
            ], 
        },
        hasErrors:true
    },
    password: {
        type:'password',
        label: {
            text:'Password'
        },
        validation: {
            fieldPolicies:[
                {type:'minimum', assertion:8},
                {type:'required'}
            ],
            execution: [
                'onBlur',
                'onChange'
            ], 
        },
        hasErrors:true
    },
    passwordConfirm: {
        type:'confirmPassword',
        label: {
            text:'Confirm Password'
        },
        validation: {
            fieldPolicies:[
                {type:'required'}
            ],
            formPolicies: [
                {type:'matchField', assertion:'password'}
            ],
            execution: [
                'onBlur',
                'onChange'
            ], 
        },
        hasErrors:true
    },
    pagePaths: {
        type:'pagePaths',
        label: {
            text:'Page paths contain...'
        },
        hasErrors:false
    }
};

export const forms = {
    registration: {
        fields: [
            authFields.username,
            authFields.email,
            authFields.password,
            authFields.passwordConfirm,
            authFields.pagePaths
        ],
        buttons: [
            authButtons.registerFree,
            // authButtons.registerPremium
        ]
    },
    login: {
        fields: [
            authFields.username,
            authFields.email,
            authFields.password
        ],
        buttons: [
            authButtons.loginSubmit
        ]
    },
    passwordRecover: {
        fields: [
            authFields.email
        ],
        buttons: [
            authButtons.recoverSubmit,
            authButtons.recoverRetry,
            authButtons.loginRedirect
        ]
    },
    passwordReset: {
        fields: [
            authFields.password,
            authFields.passwordConfirm
        ],
        buttons: [

        ]
    }
}
interface ValidationResults_I {
    error: Boolean 
    valueToMatch:null | number | string
    fieldLabels:Array<string>
}
export const defaultFormValidations = {
    matchFields: (formFields, fieldTypes) => {
        let fields:{[key:string]:InputTextProps_I} = formFields;

        return new Promise(resolve => {
            // find fields to match while comparing values
            let fieldKeys = Object.keys(formFields);

            let validationResults:ValidationResults_I = fieldKeys.reduce((acc,fieldKey) => {
                let field:InputTextProps_I = fields[fieldKey]; 
                
                if (fieldTypes.includes(field.type)) {
                    if (acc.valueToMatch === null) {
                        // first matching type, just use field value as assertion and add to labels
                        return {
                            error:false,
                            valueToMatch: field.value,
                            fieldLabels: [field.label.text] 
                        }
                    } 
                    if (acc.valueToMatch === field.value) {
                        // current field matches initial value, just append to to list of labels
                        return {
                            ...acc,
                            fieldLabels: [...acc.fieldLabels, field.label.text] 
                        }
                    } else {
                        // current field doesn't match, set error and append to list of labels
                        return {
                            valueToMatch: acc.valueToMatch,
                            error: true,
                            fieldLabels: [...acc.fieldLabels, field.label.text]
                        }
                    }              
                } else {
                    return acc;
                }
            },{
                error: false,
                valueToMatch:null,
                fieldLabels:[]
            })
            
            if (validationResults.error === false) {  
                resolve({status: 'success', errorMessages: null}) 
            } else {
                resolve({
                    status: 'error',
                    errorMessages: {
                        secondary: 'The following fields must match: ' + validationResults.fieldLabels.join(', ')
                    }
                })
            }
        });
    } 
}