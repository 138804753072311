import * as React from 'react';
import { Component } from 'react';

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './index.scss';
import Button from 'src/components/atoms/Button'
import InputText from '../../../atoms/InputText'
import InputTextSimple from '../../../atoms/InputTextSimple'
import { createElemDraft, deleteElemDraft, updateElemDraft } from 'src/data/layer/actions'
import withOriginalContent from 'src/data/originalContent/containers' 
import StylesEditor from 'src/components/molecules/StylesEditor/ui'
import { EditorNav } from '../../Editors/modules/EditorNav'
// import { replaceMedia } from '../../Editors/modules'

interface IDipatchProps { 
	load?: (ref: string) => void; 
	createElemDraft?:Function
	deleteElemDraft?: Function
	updateElemContent?: Function
	updateElemDraft?: Function
}

interface Props extends IDipatchProps {
	vig?: any
	user?: any
	elem?:Object 
	elems?: Array<any>
	multimediaObj?: any
	elemEditing: any
	originalContents?: Array<any>
}

interface State extends Props {
	editorState?: 'styles' | 'stylesAdmin' | 'contentManual' | 'contentFromPage'
	elemEditing: any
	multimediaObj: any
	storedText?:  Object
	previewText?: any
}

class TextContentEditor extends Component<Props & RouteComponentProps<any>, State>  {	
	state: State = {
		editorState: 'styles',
		elemEditing: {},
		multimediaObj: {},
		storedText: {},
		previewText: {}
	};

	static defaultProps = {
		vig: {
			data: {
				created_at: '',
				updated_at: ''
			}, 
			layers: [], 
			contentGroups: [], 
			elems: [], 
			multimediaObjs: []
		}
	}
	
	static getDerivedStateFromProps(newProps, prevState) { 
		return newProps;
	}

	startContentUpdate({ from, multimediaObj, elem }) {
		this.setState(prevState=>{
			return {
				editorState: from
			}
		})
	}
	/* redundant. Refactor!!! */ 
	replaceMedia({ elem, newObjID = null, newMediaObj = null }) {
		const { 
			elemEditing,
			createElemDraft,
			updateElemDraft
		 } = this.props;

		const currentDraft = elemEditing.elem_drafts && elemEditing.elem_drafts[0] ? elemEditing.elem_drafts[0] : null;

		switch (currentDraft) {
			case null:
				if (newObjID) {
					createElemDraft({
						targetElemID: elem.id, 
						newObjID,
						type: 'mediaFromPage'
					});
				}
				if (newMediaObj) {
					createElemDraft({
						targetElemID: elem.id, 
						newMediaObj,
						type: 'mediaManually'
					});
				}
				break;
			default:
				if (newObjID) {
					updateElemDraft({
						draftID: currentDraft, 
						newMediaID: newObjID,
						type: 'mediaFromPage'
					});
				}
				if (newMediaObj) {
					updateElemDraft({
						draftID: currentDraft, 
						newMediaObj,
						type: 'mediaManually'
					});
				}
		}
	}

	updateStyles({ elem, newElemStyles }) {
		const { 
			elemEditing,
			createElemDraft,
			updateElemDraft
			// updateElemContent, // action
		 } = this.props;

		const currentDraft = elemEditing.elem_drafts && elemEditing.elem_drafts[0] ? elemEditing.elem_drafts[0] : null;

		switch (currentDraft) {
			case null:
				createElemDraft({
					targetElemID: elem.id, 
					newElemStyles,
					type: 'styles'
				});
				break;
			default:
				updateElemDraft({
					draftID: currentDraft,
					newElemStyles,
					type: 'styles'
				});
		}
	}

	editContent(contentEditing) {
		this.setState(prevState => {
			return {
				...prevState,
				contentEditing
			}
		})
	}

	/* Preview */
	renderEditorPreview({storedText, draftText, loading, draftID, elemID}) {
		if (loading) {
			return (
				<div className="grid-x align-middle align-center" style={{minHeight: '200px'}}>
					<div className="progress cell small-8 small-offset-1">
						<div className="indeterminate"></div>
					</div>
				</div>
			);
		}
		if (draftText) {
			return (
				<div className={`${styles['preview-text']} grid-x align-center align-middle`}>
					<div className={`cell auto grid-x align-middle align-center`}>
						<p>{storedText}</p>
						<h5 className="cell">Saved Text</h5>
					</div>
					<div className={`cell auto grid-x align-middle align-center`}>
						<p>{draftText}</p>
						<h5 className="cell">Draft Text</h5>
						<div className={`${styles.actions} grid-x`}>
							<i 
								className={`material-icons ${styles.clear}`}
								onClick={()=> this.props.deleteElemDraft({draftID, id: elemID})}
							>clear</i>
							{/* <i className={`material-icons ${styles.save}`}>done</i> */}
						</div>
					</div>
				</div>
			)
		} else {
			return (
				<div className={`${styles['preview-text']} grid-x align-center align-middle`}>
					<p>{storedText}</p>
				</div>
			)
		}
	}

	/* Editor Body */
	renderEditorStyles({ elemEditing, multimediaObj, admin = false }) {
		let props = {
			media:'text',
			elemEditing, 
			multimediaObj,
			admin
		}
		return <StylesEditor {...props} />;
	}

	renderEditorManual ({elemEditing}) {
		const { previewText } = this.state;
		return (
			<div className="grid-x align-middle align-center">
				<InputTextSimple 
					label={{text: 'New Text', position:'top'}} 
					size="large" 
					textarea={true} 
					classNames="cell small-12"
					onChange={ target => this.setState({previewText:{text: target.value}}) }
				/>
				<Button 
					label={{text:'View Changes'}}
					status={ previewText && previewText.text && previewText.text.length ? 'visible' : 'disabled'}
					action={()=> this.replaceMedia({ elem: elemEditing, newMediaObj:previewText}) }
				/>
			</div>
		)
	}
	renderEditorFromPage({ multimediaText, elem }) {
		const previews = (() =>  {
			return multimediaText
			.sort((a,b)=>{
				if (!a.scores || !a.scores.total) return 1;
				if (!b.scores || !b.scores.total) return -1;
				if (a.scores.total < b.scores.total) return 1;
				if (a.scores.total > b.scores.total) return -1;
				return 0;
			})	
			.reduce((acc,mediaObj) => {
				let { text, id } = mediaObj;
				if (id === elem.multimedia_obj) return acc;
				let preview = (
					<div
						key={id}
						onClick={() => this.replaceMedia({elem, newObjID: id})}
						className={`${styles['content-option']} cell small-6`}
					>{text}</div>	
				);
				return [...acc, preview]
			},[]) 
		})()

		return <div className="grid-x align-center align-middle">{ previews }</div>;
	}
	/* Editor Body: end */

	render () {
		const { elemEditing, multimediaObj, vig, user } = this.props,
			{ elems } = vig,
			{ editorState, originalContents: multimediaText } = this.state;

		const draftElem = (()=> {
			const { elem_drafts } = elemEditing;
			if (!multimediaText.length) return 'loading';
			if (!elem_drafts || !elem_drafts.length) return null;
			const elem = elems.find(({id}) => elem_drafts[0] === id);
			if (!elem || !elem.multimedia_obj) return null;
			if (elemEditing.multimedia_obj === elem.multimedia_obj) return null;
			const multimedia_obj = multimediaText.find(({id})=> elem.multimedia_obj === id);
			return {
				...elem,
				multimedia_obj
			}
		})()
		
		const editorBody = (()=>{
			switch (editorState) {
				case 'contentManual':
					return this.renderEditorManual({elemEditing});
				case 'contentFromPage':
					return this.renderEditorFromPage({multimediaText, elem: elemEditing });
				case 'stylesAdmin':
					return this.renderEditorStyles({ elemEditing, multimediaObj , admin: true});
				case 'styles':
				default:
					return this.renderEditorStyles({ elemEditing, multimediaObj });
			}
		})()

		return(
			<div className="grid-x">
				<div className="cell small-12">
					{ 	this.renderEditorPreview({
							storedText: multimediaObj.text,
							draftText: draftElem  && draftElem !== 'loading' && draftElem.multimedia_obj && draftElem.multimedia_obj.text ? draftElem.multimedia_obj.text : null,
							loading: draftElem === 'loading' ? true : false,
							draftID: draftElem  && draftElem !== 'loading' ? draftElem.id : null,
							elemID: elemEditing.id
						}) 
					}
					<EditorNav {...{ 
						media: 'Text',
						editorState, 
						pristine: true, 
						multimediaObj, 
						elem: elemEditing,
						startContentUpdate: this.startContentUpdate.bind(this),
						isSuperAdmin: user.super_admin ? true : false
					}} />
					{ editorBody }
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => state;	

export default withOriginalContent(
						withRouter(
							connect<{}, IDipatchProps, Props & RouteComponentProps<any>>(
								mapStateToProps,
								{ createElemDraft, deleteElemDraft, updateElemDraft}
							)(TextContentEditor)
						),
					['text']
				);
