import {
    REGISTER_USER
} from '../../user/actions/types'
import { FETCH_PAGE } from 'src/data/page/actions/types';
import { 
    FETCH_VIG, 
    BUILD_VIG_MEDIA, 
    CREATE_VIG_START, 
    CREATE_VIG_END,
    CREATE_VIG_REMOVE_ERROR
} from '../actions/types'
import { UPDATE_ELEM_DRAFT, DELETE_DRAFT_ELEM, UPDATE_LAYER_DRAFT, CREATE_ELEM_DRAFT } from '../../layer/actions/types'

const defaultVig = {
    data: {
        id: ''
    },
    layers: [],
    contentGroups: [],
    elems: [],
    multimediaObjs: []
}
export function vigsReducer(state:any = [], action) {
    switch (action.type) {
        case REGISTER_USER:
            if (action.payload.vigs && action.payload.vigs.length) {
                return [
                    ...state,
                    ...action.payload.vigs                
                ]
            }
            return state;
        case FETCH_PAGE:
            const { all_vigs, ...page } = action.payload.page;
            return all_vigs;
        case CREATE_VIG_START:
            return [
                ...state,
                ...action.payload
            ]
        case CREATE_VIG_END:
            return (()=>{
                let { attemptID } = action.payload;
                let attempt = state.find(vig => vig.attemptID === attemptID);
                if (!attempt) return state;
                return state.map(vig => {
                    if (vig.attemptID && vig.attemptID === attemptID) return action.payload;
                    return vig;
                })
            })()
        case CREATE_VIG_REMOVE_ERROR:
            return state.reduce((acc,vig) => {
                let { attemptID } = action.payload;
                if (vig.attemptID && vig.attemptID === attemptID) return acc;
                return [...acc, vig];
            },[])
        // case BUILD_VIG_MEDIA:
        //     if (action.payload.id) {
        //         return [
        //             ...state,
        //             ...action.payload
        //         ]
        //     }
        //     return state;
        default:
            return state
    }
}

export function vigReducer(state = defaultVig, action) {
    switch (action.type) {
        case FETCH_VIG:
            let {vig, ...rest} = action.payload;
            return {
                ...state,
                data: vig,
                ...rest
            }
        case UPDATE_LAYER_DRAFT:
            var { updatedElems, updatedGroups, newMultimediaObjs } = action.payload;

            return {
                ...state,
                elems: [
                    ...state.elems.map(elem=> {
                        let updated = updatedElems.find(({id}) => elem.id === id);
                        if (updated && updated.id) return updated;
                        return elem;
                    })
                ],
                contentGroups: [
                    ...state.contentGroups.map(group=> {
                        let updated = updatedGroups.find(({id}) => group.id === id);
                        if (updated && updated.id) return updated;
                        return group;
                    })
                ],
                multimediaObjs: [
                    ...state.multimediaObjs,
                    ...newMultimediaObjs
                ],
            }
        
        case CREATE_ELEM_DRAFT:
            return (()=>{
                const { updatedElem, newElem, newMultimediaObj } = action.payload;
                const newMultimediaObjs = (()=>{
                    if (!newMultimediaObj) return state.multimediaObjs;
                    return [
                        ...state.multimediaObjs,
                        ...newMultimediaObj
                    ];
                })()
                return {
                    ...state,
                    elems: [
                        ...state.elems.map(elem=> {
                            if (elem.id !== updatedElem.id) return elem;
                            return updatedElem;
                        }),
                        newElem
                    ],
                    multimediaObjs: newMultimediaObjs
                }
            })()
        case DELETE_DRAFT_ELEM:
            var { updatedElem, removedID } = action.payload;
            return {
                ...state,
                elems: [
                    ...state.elems.reduce((acc, elem)=> {
                        if (elem.id === updatedElem.id) return [...acc, updatedElem];
                        if (elem.id === removedID) return acc;
                        return [...acc, elem];
                    },[]),
                ]
            }
        case UPDATE_ELEM_DRAFT:
            return (()=> {
                const { updatedDraft, newMultimediaObj }  = action.payload;

                const newMultimediaObjs = (()=>{
                    if (!newMultimediaObj) return state.multimediaObjs;
                    return [
                        ...state.multimediaObjs,
                        ...newMultimediaObj
                    ];
                })()

                return {
                    ...state,
                    elems: [
                        ...state.elems.reduce((acc, elem)=> {
                            if (elem.id === updatedDraft.id) return [...acc, updatedDraft];
                            return [...acc, elem];
                        },[]),
                    ],
                    multimediaObjs: newMultimediaObjs
                }
            })()
        case BUILD_VIG_MEDIA:
            if (action.payload.vigId && action.payload.vigId === state.data.id) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        path_video: action.payload.path_video,
                        is_building: false
                    }
                }
            }
            return state;
        default:
            return state
    }
}