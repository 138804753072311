// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App__App___BHMrm{text-align:center}.App__App-logo___cP8rm{-webkit-animation:App__App-logo-spin___dNrFh infinite 20s linear;animation:App__App-logo-spin___dNrFh infinite 20s linear;height:80px}.App__App-header___UHTxc{background-color:#222;height:150px;padding:20px;color:#fff}.App__app-title___nhgpQ{margin-top:40px;margin-bottom:40px}.App__App-intro___6BVUP{font-size:large}@-webkit-keyframes App__App-logo-spin___dNrFh{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App__App-logo-spin___dNrFh{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./src/components/App/ui/App.scss"],"names":[],"mappings":"AAEA,kBACE,iBAAA,CAOF,uBACE,gEAAA,CAAA,wDAAA,CACA,WAAA,CAGF,yBACE,qBAAA,CACA,YAAA,CACA,YAAA,CACA,UAAA,CAGF,wBACE,eAAA,CACA,kBAAA,CAGF,wBACE,eAAA,CAGF,8CACE,KAAA,8BAAA,CAAA,sBAAA,CACA,GAAA,gCAAA,CAAA,wBAAA,CAAA,CAFF,sCACE,KAAA,8BAAA,CAAA,sBAAA,CACA,GAAA,gCAAA,CAAA,wBAAA,CAAA","sourcesContent":["// @import 'src/components/App/sass/app';\n\n.App {\n  text-align: center;\n\n  // h1 {\n  //   color:$black;\n  // }\n}\n\n.App-logo {\n  animation: App-logo-spin infinite 20s linear;\n  height: 80px;\n}\n\n.App-header {\n  background-color: #222;\n  height: 150px;\n  padding: 20px;\n  color: white;\n}\n\n.app-title {\n  margin-top:40px;\n  margin-bottom:40px;\n}\n\n.App-intro {\n  font-size: large;\n}\n\n@keyframes App-logo-spin {\n  from { transform: rotate(0deg); }\n  to { transform: rotate(360deg); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `App__App___BHMrm`,
	"App-logo": `App__App-logo___cP8rm`,
	"App-logo-spin": `App__App-logo-spin___dNrFh`,
	"App-header": `App__App-header___UHTxc`,
	"app-title": `App__app-title___nhgpQ`,
	"App-intro": `App__App-intro___6BVUP`
};
export default ___CSS_LOADER_EXPORT___;
