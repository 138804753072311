import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { SecondarySideNav_I, NavSection_I, NavSubSection_I, NavLink_I } from '../interfaces';
import { Link } from 'react-router-dom'
import styles from './index.scss';
import WithNavHOC from 'src/data/nav/containers/index';
import { registerUser } from 'src/data/user/actions';

const NavLink: React.StatelessComponent<NavLink_I> = (props:NavLink_I) => {
	const { target, label, active, index }: NavLink_I  = props;

	return(
		<div className={`${styles['nav-link']} grid-x`} data-active={active}>
			<Link to={target||'/'} className="grid-x cell grow align-left align-middle">
				<span className="cell shrink">{index + 1}</span>
				<span className="cell auto">{label}</span>
			</Link>
		</div>
	);	
}

export const ImagePreview: React.StatelessComponent<any> = (props:any) => {
	const { label, active, viewingSubSection, thumbnail, hoverContent, classNames = '', target = null, onClick = ()=>{}, inActiveWidth }: any  = props;

	const hoverElement = (()=> {
		if (!hoverContent) return '';
		return (
			<div 
				className={`${styles['hover-content']}`}
				data-active={active}
			>
				<div className={styles['cover-layer']}></div>
				<div 
					className={styles['content']} 
					dangerouslySetInnerHTML={{__html: hoverContent}}
					data-selected={!viewingSubSection && active}
				></div>
			</div>
		);
	})()
	if (target) {
		return (
			<Link to={target} className={`cell ${active ? 'small-12' : 'shrink'} ${classNames}`}> 
				{ hoverElement }
				<h6 className="bg-l1-text">{label}</h6>
				{ thumbnail ? <img src={thumbnail} width={ active ? '100%' : inActiveWidth } /> : ''}
			</Link>
		);
	}
	return (
		<a onClick={onClick} className={`cell ${active ? 'small-12' : 'shrink'}`}> 
			{ hoverElement }
			<h6 className="bg-l1-text">{label}</h6>
			{ thumbnail ? <img src={thumbnail} width={ active ? '100%' : inActiveWidth } /> : ''}
		</a>
	);
}

export const NavSubSection: React.StatelessComponent<NavSubSection_I> = (props:NavSubSection_I) => {
	const { links = [], label, active, thumbnail, target = null, onClick = ()=>{}, hoverContent, width, inActiveWidth = '75'}: NavSubSection_I  = props;

	const renderLinks = () => {
		if (links && links.length){
			return links.map((navLink:NavLink_I, i) => {
				return (
					<NavLink {...navLink} index={i} key={i} />
				)
			})	
		}
	}
	const viewingSubSection = (()=> {
		if (!links || !links.length) return false; 
		return links.some(link => link && link.active);
	})()

	const theWidth = (()=> {
		if (width) return width;
		if (active) return '100%';
		return '50%';
	})()

	return(
		<div 
			className={`${styles['nav-sub-section']} cell grid-x align-center align-middle`}
			style={{width:theWidth}}
		>
			<ImagePreview 
				target={target}
				onClick={onClick}
				active={active}
				label={label}
				thumbnail={thumbnail}
				viewingSubSection={viewingSubSection}
				hoverContent={hoverContent}
				inActiveWidth={inActiveWidth}
			/>
	
			{links && links.length ? 
				<div className={`${styles['nav-links']} cell grow`}>
					<p className={`${styles['nav-links-label']} primary-text`}>
						Layers
					</p> 
					{renderLinks()}
				</div>
				: 
				''
			}			
		</div>		
	);	
}

const NavSection: React.StatelessComponent<NavSection_I> = (props:NavSection_I) => {
	const { subSections, label, active, target }: NavSection_I  = props;

	const renderSubSections = () => {
		if (subSections && subSections.length) {
			return subSections.map((subSection:NavSubSection_I, i) => {
				return (
					<NavSubSection {...subSection} key={i} />
				)
			})
		}
	}

	return(
		<div className={`${styles['nav-section']}`}  data-active={active}>
			<h5>{ target ? <Link to={target}>{label}</Link> : label }</h5>
			<div className="grid-x align-center align-middle">
				{renderSubSections()}
			</div>
		</div>		
	);	
}


interface State extends SecondarySideNav_I {}

class SecondarySideNav extends Component<SecondarySideNav_I, State> {
	
	state = {
		sections: []
	};

	static defaultProps = {
		sections: [],
		navType: "secondarySideNav"
	};

	static getDerivedStateFromProps(nextProps, prevState){
		return {
			...prevState,
			...nextProps
		}
	}

	renderSections () {	
		let sections = this.state.sections;
		if (sections && sections.length) {
			return sections.map((section:NavSubSection_I, i) => {
				return (
					<NavSection {...section} key={i} />
				)
			})
		}
	}

	render () {
		const { active } = this.props;
		
		return (
			<nav 
				className={`${styles['main-side-nav']}`}
				style={active ? {opacity:1, visibility:'visible'} : {opacity:0, visibility:'hidden'}}
			>
				{this.renderSections()}
			</nav>
		) 
	}
}

export default WithNavHOC(SecondarySideNav);

/* main nav 
	- data structured...
		- subsections from...
			projects, pages, pageTemplates
		- active status from...
			route/path,

	- where to transform/de-normalize..
		reducer, container/HOC/RP, class
		Nav_HOC
*/