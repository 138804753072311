// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__color-option___Dqqmf{padding:5px 8px;margin:5px 8px;border-radius:3px;cursor:pointer}.index__color-option___Dqqmf[data-preview=true]{width:30px;height:30px}.index__color-option___Dqqmf[data-preview=true] span{opacity:0;visibility:hidden;-webkit-transition:opacity .25s linear;transition:opacity .25s linear}.index__color-option___Dqqmf[data-preview=true]:hover{-webkit-transform:scale(1.1);-ms-transform:scale(1.1);transform:scale(1.1)}.index__color-option___Dqqmf[data-color=transparent]{border:2px dashed #000}`, "",{"version":3,"sources":["webpack://./src/components/atoms/ColorPicker/ui/index.scss"],"names":[],"mappings":"AAAA,6BACI,eAAA,CACA,cAAA,CACA,iBAAA,CACA,cAAA,CAEA,gDACI,UAAA,CACA,WAAA,CAEA,qDACI,SAAA,CACA,iBAAA,CACA,sCAAA,CAAA,8BAAA,CAEJ,sDACI,4BAAA,CAAA,wBAAA,CAAA,oBAAA,CAIR,qDACI,sBAAA","sourcesContent":[".color-option {\n    padding: 5px 8px;\n    margin: 5px 8px;\n    border-radius: 3px;\n    cursor: pointer;\n\n    &[data-preview=\"true\"] {\n        width: 30px;\n        height: 30px;\n\n        span {\n            opacity: 0;\n            visibility: hidden;\n            transition: opacity 0.25s linear;\n        }\n        &:hover {\n            transform: scale(1.1)\n        }\n    }\n\n    &[data-color=\"transparent\"] {\n        border:2px dashed #000;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color-option": `index__color-option___Dqqmf`
};
export default ___CSS_LOADER_EXPORT___;
