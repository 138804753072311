import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchOne } from '../actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { User_I } from 'src/data/user/interfaces';
import * as qs from 'query-string';


interface ProjectHOC_I {
    fetchOne?: Function
    type?:any
    project?:any
}

interface IDipatchProps {
  fetchOne?: Function
}

interface State {
  fetching?:boolean
}
const WithProjectHOC = (WrappedComponent:any) => {
  const Container:any = class extends Component<ProjectHOC_I & IDipatchProps &  RouteComponentProps<any>, State> {
    projectFetching = null;
    pageFetching = null;

    static displayName = `WithProjectHOC(${WrappedComponent.displayName  || WrappedComponent.name})`;

    componentWillReceiveProps (nextProps) {
        let { match, fetchOne, history, location, project } = this.props;

        
        let params = (()=>{
          if (!nextProps.match || !nextProps.match.params) return null;
          return nextProps.match.params
        })()

        let pageViewing = (location => {
          if (location && location.search) {
            let queries = qs.parse(location.search, 
              // { ignoreQueryPrefix: true }
              );
            if (!queries || !queries.pageViewing ) return null;
            return queries.pageViewing
          } 
          return null;
        })( nextProps.location )

        if (params.projectID) {
            if (this.projectFetching !== params.projectID || this.pageFetching !== pageViewing){
              this.projectFetching = params.projectID;
              this.pageFetching = pageViewing;
              fetchOne({id: params.projectID, pageViewing , populate:['pages']}) 
            }
        }
      
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
        />
      )
    }
  }

  return  withRouter(
            connect<{}, IDipatchProps, RouteComponentProps<any>>(
              mapStateToProps, 
              { fetchOne }
            )(Container)
          );
      
}

function mapStateToProps(state) {
  return state;
}
export default WithProjectHOC;
