// tslint:disable
// this is an auto generated file. This will be overwritten

export const createVigVideo = `mutation CreateVigVideo($input: CreateVIGVideoInput!) {
  createVIGVideo(input: $input) {
    vigId
    userId
    fps
    vigDuration
    frameFormatsURL
    createdAt
  }
}
`;
export const createVigEncoding = `mutation CreateVigEncoding($input: CreateVIGVideoInput!) {
  createVIGEncoding(input: $input) {
    vigId
    vigUIID
    userId
    fps
    vigDuration
    frameFormatsURL
    createdAt
    updatedAt
  }
}
`;
export const updateVigVideo = `mutation UpdateVigVideo($input: UpdateVIGVideoInput!) {
  updateVIGVideo(input: $input) {
    vigId
    userId
    fps
    vigDuration
    frameFormatsURL
    createdAt
  }
}
`;
export const deleteVigVideo = `mutation DeleteVigVideo($input: DeleteVIGVideoInput!) {
  deleteVIGVideo(input: $input) {
    vigId
    userId
    fps
    vigDuration
    frameFormatsURL
    createdAt
  }
}
`;

export const objectComplete = `mutation ObjectComplete($input: UpdateVIGVideoInput!) {
  objectComplete(input: $input) {
    vigId
    vigUIID
    videoPath
    status
    userId
    fps
    vigDuration
    frameFormatsURL
    createdAt
    updatedAt
  }
}
`
