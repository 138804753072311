import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import styles from './index.scss';

import ViewTitles from 'src/components/molecules/ViewTitles';
import {ViewTitles_I} from 'src/components/molecules/ViewTitles/interfaces';
import InputText from 'src/components/atoms/InputText';
import { InputTextProps_I } from 'src/components/atoms/InputText/interfaces'
import Button from 'src/components/atoms/Button';
import { Button_I} from 'src/components/atoms/Button/interfaces';
import FormPrompts from 'src/components/molecules/FormPrompts';
import { FormPromptsProps_I } from 'src/components/molecules/FormPrompts/interfaces';
import AuthForm from 'src/components/organisms/AuthForm';
import  { Project_I } from 'src/data/project/interfaces';
import withProjects from 'src/data/project/containers/projects' 
import withProject from 'src/data/project/containers' 
import withPageTemplates from 'src/data/pageTemplate/containers/pageTemplates'
import {User_I} from 'src/data/user/interfaces';
import { fetchOne } from 'src/data/project/actions'
import { populateOnePage } from 'src/data/page/actions'

import clientViewStates  from 'src/shared/constants/viewStates';
const projectViewStates = clientViewStates.viewStates; 
 
import * as componentConfig from './config';

import VigPreview from 'src/components/atoms/VigPreview'
import TreeMenu from 'src/components/organisms/TreeMenu/ui'
import { PageWithVIG_I } from 'src/data/page/interfaces'
import Spinner from 'src/components/atoms/Spinner/ui';

import AddProjectPage from 'src/components/organisms/AddProjectPage'
// import { addNewPage } from 'src/data/project/actions'

interface Props {
	currentViewState?:string;
	viewTitles?: ViewTitles_I
	project?: Project_I
	projects?: Array<Project_I>
	pages?: Array<PageWithVIG_I>
	fetchOne?: Function
	populateOnePage?: Function
}

interface State extends Props{
	formPrompts?:FormPromptsProps_I;
	viewState?: object
	viewTitles:ViewTitles_I
	treeData?: any
	key?: any
	projectID?: string
	pageViewing?: string
}

class ProjectView extends Component<Props & RouteComponentProps<any>, State>  {	
	state:State = componentConfig.defaultState; 	


	static getDerivedStateFromProps (newProps, prevState) {
		const { project = {}, pages = [], projects = [] } = newProps,
			{ id, pageViewing } = project;
		// return prevState
		if (newProps.project.error) {
			if (projects.length) {
				let prefix = window.location.host.startsWith("http") ? "" : "http://";
				let target = prefix + window.location.host + `/project/${projects[0].id}`;
				window.location.replace(target);
			} 
			newProps.history.push('/');
			return null;
		} else {
			if (id && pages.length) {
				let pagesUpdated = (()=> {
					if (!prevState.pages || !prevState.pages.length) return true;
					if (pages.length !== prevState.pages.length) return true;
					return pages.some(page => {
						let prev = prevState.pages.find(({id})=> id === page.id)
						if (!prev) return true;
						if (prev.updated_at !== page.updated_at) return true;
						if (prev.is_scraping !== page.is_scraping) return true;
						return false;
					})
				})()
				if (prevState.projectID !== id || prevState.pageViewing !== pageViewing || pagesUpdated) {
					return {
						...prevState,
						pages,
						projectID: id,
						pageViewing
					}
				}
			}
			return prevState;
		}
	}

	handleCreate = ({pageID}) => {
		if (confirm('Are you sure you want to scrape this page?')) {
			this.props.populateOnePage({pageID})
		}
	}
	renderTree (props){
		const {pages, projectID } = props;

		if (pages && pages.length && projectID) {
			return <TreeMenu key={Math.random()} {...props} />
		} 
		return (
			<div className="grid-x align-center align-middle" style={{width:'100%', height:'100%'}}>
				{/* <h1 className="cell shrink"> Loading......</h1> */}
				<div className="cell auto" style={{width:'100%', height:'400px', position: 'relative'}}>
					<Spinner active="true" />
				</div>
			</div>
		);
	}
	
	render() {
		const { pages, projectID, pageViewing } = this.state,
			{ handleCreate } = this;
		const pageProps:any = {projectID}
		return(
			<div className="grid-x">
				<div className="cell small-10 small-offset-1">
					<div className={`grid-x grid-margin-x ${styles['project-container']}`}>
						<div className="cell small-12">
							{ this.renderTree({pages, projectID, pageViewing, handleCreate })} 
						</div>
					</div>
				</div>
				<AddProjectPage {...pageProps} />
			</div>
		);	
	}
}


function mapStateToProps(state:any ) {
	return state;	
}

export default 
	withPageTemplates(
		withProjects(
			withProject(			
				withRouter(
					connect<{},Props>(
						mapStateToProps,
						{ fetchOne, populateOnePage },
						null,
						{pure: false }
					)(ProjectView)
				)
			)
		)
	);
