import * as React from 'react';
import styles from './index.scss';
import { ViewTitles_I } from '../interfaces'

const ViewTitles:React.StatelessComponent<ViewTitles_I> = (props:any) => {
	const { primary, secondary, position }: ViewTitles_I  = props;

	return(
		<div className={`${styles['view-titles']} ${styles[position]}`}>
			<h3>{primary ? primary.replace('http://','').replace('https://',''):''}</h3>
			<h6>{secondary}</h6>
		</div>
	);	
}

export default ViewTitles;
