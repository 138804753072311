import * as React from 'react';
import { Component } from 'react';
import Button from 'src/components/atoms/Button'
import * as brace from 'brace';
import AceEditor from 'react-ace';
import ColorPicker from 'src/components/atoms/ColorPicker/ui'
import 'brace/mode/javascript';
import 'brace/theme/monokai';
// import * as prettier from 'prettier/standalone'
// const plugins = [ 
// 	// require("prettier/parser-babylon") 
// 	require("prettier-plugin-import-sort")
// ];

interface StyleFieldsAdmin_I {
	manualUpdate?:Function
	elem_formats?: any 
	handleChange?: Function
}
interface State  {
	activeKey?: string
	status?: string
    oldElemStyles?: any
    newElemStyles?: any
}

export default class StyleFieldsAdmin extends Component<StyleFieldsAdmin_I,State> {
	state = {
		activeKey: 'css',
		oldElemStyles: {}, 
		newElemStyles: {},
		status:'pristine'
	}

	componentDidMount () {
		this.setState({
			newElemStyles: {
				...Object.entries(this.props.elem_formats).reduce((acc,entry)=> {
					return {
						...acc,
						[entry[0]]: JSON.stringify(entry[1])
					}
				},{})
			}
		})
	}

	shouldComponentUpdate(nextProps, nextState){
		if (this.state.status !== 'loaded') return true;
		if (this.state.activeKey !== nextState.activeKey) return true;
		return false;
	}
	handleEditorChange = (obj, data) => {
		console.log('obj, data :', obj, data);
		const { manualUpdate } = this.props;
		let parsedData = {
			'animations': data => data.replace('var animations = ','').replace('];',']'),
			'animationsContainer': data => data.replace('var animationsContainer = ','').replace('];',']'),
			'css': data => data.replace('var css = ','').replace('};','}'),
			'cssBefore': data => data.replace('var cssBefore = ','').replace('};','}'),
			'cssContainer': data => data.replace('var cssContainer = ','').replace('};','}')
		}
		this.setState(prevState=> {
			return {
				...prevState,
				newElemStyles: {
					...prevState.newElemStyles,
					[obj]: parsedData[obj](data)
				}
			};
		})
	}

	handleSubmit (){
		this.props.manualUpdate({
			data: Object.entries(this.state.newElemStyles).reduce((acc,entry)=> {
				let val:any = entry[1];

				return {
					...acc,
					[entry[0]]: eval('('+val+')')
				}
			},{})
		})
	}
	renderEditor ({elem_formats, activeKey = ''}) {
		return <AceEditor
			key={elem_formats[activeKey]}
			mode="javascript"
			theme="monokai"
			onChange={ newValue => this.handleEditorChange(activeKey,newValue) }
			onLoad={ ()=>this.setState({status: 'loaded'}) }
			name="UNIQUE_ID_OF_DIV"
			editorProps={{$blockScrolling: true}}
			// value={
			// 	prettier.format(`var ${activeKey} = `+elem_formats[activeKey],{ parser: "babylon", plugins, semi:false })
			// }
			wrapEnabled={true}
			height="250px"
		/>;
	}
	renderNav (activeKey) {
		return (
			<div className="grid-x cell small-12">
				{
					['css', 'cssContainer', 'animations', 'animationsContainer', 'cssBefore'].map(obj => {
						return  (<Button 
							key={obj}
							size="mini"
							label={{text:obj, weight:'text-bold'}} width="cell small-3" 
							action={()=> {this.setState({activeKey: obj})}}
						/>)
					})
				}
			</div>
		)
	}
	render () {
		const { activeKey, newElemStyles } = this.state;
		return (
			<div>
				{ this.renderNav(activeKey) }
				{ this.renderEditor({ elem_formats: newElemStyles, activeKey }) }
				<Button 
					size="small"
					shape="round"
					label={{text:'Submit Changes', weight:'text-bold'}} width="cell small-3" 
					action={ this.handleSubmit.bind(this) }
				/>
			</div>
		);
	}
}

