import * as React from 'react'
import { Component } from 'react'
import styles from './index.scss';

interface ColorPicker_I {
    colors?: Array<string>
    default?:string
    onSelect?:Function
}

export default class ColorPicker extends Component<ColorPicker_I,{}> {
    state = {
        active:false,
        selected: null
    }
    static defaultProps = {
        colors: ['#fff', '#000','#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#EB144C', '#F78DA7', '#9900EF', 'none']
    }
    componentDidMount(){
        this.setState({selected: this.props.default})
    }
    selectColor(color) {
        this.props.onSelect(color)
        this.setState({
            selected: color,
            active:false
        })
        
    }

    render () {
        const { colors } = this.props, 
              { active, selected } = this.state;
        return (
            <div className="grid-x cell small-12 align-middle align-left">
                {
                    active 
                    ? colors.map(color=> {
                            return (
                                <div
                                    data-preview="true"
                                    data-color={color}
                                    className={`${styles['color-option']} cell`}
                                    style={{backgroundColor:color}}
                                    onClick={ ()=>this.selectColor(color) }
                                >
                                    <span className="label">{color}</span>
                                </div>
                            )
                        })
                    : <div 
                            className={`${styles['color-option']} cell shrink`}
                            style={{ backgroundColor: selected || "transparent" }}
                            onClick={ ()=>this.setState({ active:true }) }
                        >
                            <span className="label">{selected || "(none)"}</span>
                    </div>
                }
            </div>
        )
    }
}