import * as React from 'react'

export const SelectField:React.StatelessComponent<any> = (props:any) => {
    const { options = [], handleChange, label, default:defaultValue = '', name } = props;
	console.log('label :', label);
    const selectOptions = options.map((option,i) => {
        let { value, label = null} = option;
        return <option key={i} value={value}>{label || value}</option>;
    })

    return (
        <div className="grid-x small-12">
            <label className="cell auto">{label}</label>
            <select
				className="cell auto"
                name={ name }
                defaultValue={defaultValue}
                onChange={ e => handleChange({value: e.target.value, name: e.target.name }) }
            >
                {selectOptions}
            </select>
        </div>
    );
}