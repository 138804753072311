import axios from 'axios'
import { SERVER_ROOT, AUTH_HEADERS } from 'src/shared/constants'; 
const VIG_ROOT = SERVER_ROOT + '/vig';
const VIGS_ROOT = SERVER_ROOT + '/vigs';

export const fetchOne = async ({id, queryString = ''}) => {
    const res:any = await axios.get(
        `${VIG_ROOT}/${id}${queryString ? queryString : ''}`,
        AUTH_HEADERS
    )
    .catch(err => { throw new Error(err) })
    
    if (!res.data) throw new Error();
    return res.data;
}

export const generateOne = async ({id, output}) => {
    const res:any = await axios.post(
        `${VIG_ROOT}/generate`,
        { vigID: id, output },
        AUTH_HEADERS
    )
    .catch(err => { throw new Error(err) })
    
    if (!res.data) throw new Error();
    return res.data;
}

export const createOne = async ({ pageID, templateID, output, attemptID }) =>{
const res:any = await axios.post(
    `${VIG_ROOT}`,
    { pageID, templateID, output, attemptID },
    AUTH_HEADERS
)
.catch(err => { throw new Error(err) })

if (!res.data) throw new Error();
return res.data;
}




