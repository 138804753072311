import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './index.scss';

import ViewTitles from 'src/components/molecules/ViewTitles';
import {ViewTitles_I} from 'src/components/molecules/ViewTitles/interfaces';
import withProject from 'src/data/project/containers' 
import withProjects from 'src/data/project/containers/projects' 
import withPage from 'src/data/page/containers' 
import withVig from 'src/data/vig/containers' 
import withPageTemplates from 'src/data/pageTemplate/containers/pageTemplates'
import Button from 'src/components/atoms/Button'
import { PageWithVIG_I } from 'src/data/page/interfaces'
import { TimeStamps } from 'src/components/molecules/TimeStamps/ui'
import { buildVIGPreview } from 'src/shared/utils/vig'
import VigPreview from 'src/components/atoms/VigPreview'
import { generateOneVig } from 'src/data/vig/actions'
import Spinner from 'src/components/atoms/Spinner/ui';

// import * as Observable from 'zen-observable';
// import API, { graphqlOperation } from "@aws-amplify/api";
import Amplify, { API, graphqlOperation, Auth } from "aws-amplify";
import * as subscriptions from 'src/graphql/subscriptions';
import * as mutations from 'src/graphql/mutations';
// import 

interface Props {
	currentViewState?:string;
	viewTitles?: ViewTitles_I
	page?: PageWithVIG_I
	project?:any
	viewing?: string
	vig?: any
	history?: any 
	created_at?: string 
	updated_at?: string
	fetchOne?: Function
}

interface State extends Props{
	previewData?: any;
	isBuilding?:boolean
	animationKey?: any
	currentAnimation?: number
}

interface IDipatchProps {
	generateOneVig?: Function
}


class VigView extends Component<Props & IDipatchProps &  RouteComponentProps<any>, State>  {	
	state = {
		previewData: null,
		isBuilding: false,
		animationKey: Math.random(),
		currentAnimation: null
	};

	static defaultProps = {
		vig: {
			data: {
				created_at: '',
				updated_at: ''
			}, 
			layers: [], 
			contentGroups: [], 
			elems: [], 
			multimediaObjs: []
		}
	}

	componentDidUpdate(prevProps) {
		const oldVigProps = prevProps.vig,
			newVigProps = this.props.vig;

		if ( previewDataUpdated(oldVigProps, newVigProps, this.state) ) {
			this.buildPreviewData(newVigProps);
		}
		// if ( mediaDataUpdated(oldVigProps, newVigProps, this.state) ) {
		// 	this.buildMediaData(newVigProps);
		// }

		function previewDataUpdated(oldVig, newVig, state:any = {}) {
			if (!state.previewData) return true;
			if (oldVigProps.data.updated_at !== newVigProps.data.updated_at) return true;
			if (oldVigProps.data.id !== newVig.data.id) return true;
			if (oldVigProps.data.path_video !== newVig.data.path_video) return true;
			if (oldVigProps.data.is_building !== newVig.data.is_building) return true;
			return false;
		}
		// function mediaDataUpdated(oldVig, newVig, state:any = {}) {
		// 	if (oldVigProps.data.path_video !== newVig.data.path_video) return true;
		// 	if (oldVigProps.data.is_building !== newVig.data.is_building) return true;
		// 	return false;
		// }
	}

	buildPreviewData (vigProps) {
		const { data , layers, contentGroups, elems, multimediaObjs } = vigProps;

		if (!data || !layers.length || !contentGroups.length || !elems.length || !multimediaObjs.length ) return <h3>LoadingVIG preview... </h3>;
		renderPreview({ 
			vigData: data, 
			layers, 
			contentGroups, 
			elems, 
			multimediaObjs,
			draftMode: false
		}).then(previewData => {
			this.setState(prevState => {
				return {
					...prevState,
					previewData,
					animationKey: Math.random()
				}
			})
		})	
	}

	render () {
		const { page, vig, generateOneVig } = this.props,
				{ data } = vig,
				{ id, duration }  = data, 
				{ isBuilding, previewData, animationKey} = this.state;
		console.log('data', data)
		return(
			<div className="grid-x">
				<div className="cell small-10 small-offset-1">
					<div className={`grid-x grid-margin-x ${styles['project-container']}`}>
						<div className="cell auto" style={{position:'relative', flexBasis:'500px'}}>
							<h2>{ renderViewTitles({ page }) }</h2>
							{ previewData ? <VigPreview {...previewData} key={animationKey} /> : <Spinner active={true} />}
						</div>
						<div className="cell shrink grid-x" style={{flexBasis:'350px'}}>
							
							<div className="cell grow">
								{ data && (data.created_at || data.updated_at) ? renderTimeStamps(data) : '' }
							</div>
							<div className="cell grow">
								{ 	data 
									? renderMediaSources({ 
										vigData: data, 
										isBuilding,
										rebuild: output => {
											this.setState({ isBuilding: true });
											generateOneVig({ id, duration, output:['thumbnail',output] })
										}
									}) 
									: ''
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}



function mapStateToProps(state:any ) {
	return state;	
}

export default 	withPageTemplates(
					withProjects(
						withProject(
							withPage(withVig(
									withRouter(
										connect<{}, IDipatchProps, Props & RouteComponentProps<any>>(
											mapStateToProps,
											{ generateOneVig }
										)(VigView)
									)
								)
							)
						)
					)
				);

function flattenVigObjs(vigData) {
	const { data, ...rest  } = vigData;
	
	return [].concat(
		...Object.values(rest).map((list:any) => 
			[].concat(
				...list.map(obj => {
					let {id, created_at} = obj;
					return {id, created_at}
				})
			)
		)
	)
}



async function renderPreview({
	vigData,
	layers,
	contentGroups,
	elems,
	multimediaObjs,
	draftMode
}) {
	
	const previewData = await buildVIGPreview({
		layers, //[ layers[0] ],
        contentGroups,
        elems,
        multimediaObjs,
		vigData,
		draftMode
	});

	const { htmlString, cssString, layerAnimations, vigID } = previewData;

	if (htmlString.length && cssString.length && layerAnimations.length && vigID.length) {
		let props = {
			cssString,
			htmlString,
			layerAnimations,
			vigDuration: vigData.duration || 20000,
			dimensions: {
				width:500,
				height:400
			},
			vigID
		}
		return props;
	} 
}

function renderMediaSources({ vigData, rebuild, isBuilding }) {
	const { path_video, path_gif, id} = vigData;

	if (isBuilding === true ) {
		return (
			<div className="grid-x small-12 cell">
				<h5 className="cell grow">Hold on, we're building one right now!</h5>
				<div className="cell grow" style={{position:'relative'}}>
					<Spinner active={true} />
				</div>
			</div>
		)
	}
	const videoSource = (path => {
		let displayName = path ? buildDisplayName(path) : '';
		return (
			<div>
				<div className="cell auto">
					{ displayName.length ? <a key={0} href={path} target="_blank"><h6>{displayName}</h6></a> : ''}
					<Button key={1} shape="round" color={displayName.length ? "secondary" : "primary"} size={displayName.length ? "mini" : "small"} action={ () => rebuild('video') } label={{text: displayName.length ? "RE-BUILD" : "BUILD"}} />
				</div>
				
			</div>
		)
	})(path_video);

	// const gifSource = (path => {
	// 		if (!path) return <Button shape="round" size="small" action={ () => rebuild('gif') } label={{text:"BUILD"}} />
	// 		let displayName = buildDisplayName(path);
	// 		return <a href={path}  target="_blank"><p>{displayName}</p></a>
	// 	})(path_gif);

	return (
		<div>
			<div className={`${styles['media-source']} grid-x align-left align-center`}>
				<div className={`${styles['media-title']} cell shrink`}>
					<h5>Video:</h5>
				</div>
				<div className="cell auto">
					{ videoSource }
				</div>
			</div>
			<Button 
				size="small"
				shape="round" 
				color="secondary" 
				action={()=>downloadResource(path_video,path_video)} 
				label={{text:'Download Now'}} 
			/>
			<div className="spacer-inline spacer60"></div>
			{/* <div className={`${styles['media-source']} grid-x align-left align-center`}>
				<div className={`${styles['media-title']} cell shrink`}>
					<h5>GIF:</h5>
				</div>
				<div className="cell auto">
					{ gifSource }
				</div>
			</div> */}
		</div>
	)
}

function forceDownload(blob, filename) {
	var a = document.createElement('a');
	a.download = filename;
	a.href = blob;
	// For Firefox https://stackoverflow.com/a/32226068
	document.body.appendChild(a);
	a.click();
	a.remove();
  }
  
  // Current blob size limit is around 500MB for browsers
function downloadResource(url, filename) {
	if (!filename) filename = url.split('\\').pop().split('/').pop();
	fetch(url, {
		headers: new Headers({
		  'Origin': location.origin
		}),
		mode: 'cors'
	  })
	  .then(response => response.blob())
	  .then(blob => {
		let blobUrl = window.URL.createObjectURL(blob);
		forceDownload(blobUrl, filename);
	  })
	  .catch(e => console.error(e));
  }
  

function buildDisplayName(path) { 
	let noProtocol = path.replace('http://','').replace('https://','')
	if (noProtocol.length > 30) return noProtocol.slice(0,30) + '...';
	return noProtocol;
}

function buildVig({id, media}) {
	switch (media) {
		case 'video':
		case 'gif':
	}
}

function renderViewTitles({ page}) {
	if (page) {
		return  (
			<ViewTitles 
				primary={page.url_project_root || ''}
				secondary={page.url_path || ''}
				position={'left'}
			/>
		);
	} 
}

function renderTimeStamps(vigData) {
	const { 
			created_at, 
			updated_at 
		} = vigData,
		timesObj =  {
			created_at, 
			updated_at 
		},
		times = Object.keys(timesObj);

	if (times) {
		const props:any = {
			size: "tiny",
			times: times.map(key=> {
				return { label: key, timeDate: timesObj[key] }
			})
		}	
		return <TimeStamps {...props} />;
	}
}

