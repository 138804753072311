import * as React from 'react';
import { App } from '..';
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { userAuth } from './Guards/userGuards';
// import { projectAuth } from './Guards/projectGuards';
import { quickStartAuth } from './Guards/quickStartGuards';
import ViewTemplate from 'src/components/organisms/ViewTemplate';
import QuickStartView from 'src/components/views/QuickStartView';
import ProjectView from 'src/components/views/Project'
import PageView from 'src/components/views/Page'
import VigView from 'src/components/views/Vig'
import LayerView from 'src/components/views/Layer'
import { getUser, checkAuth,signInUserRaw } from 'src/data/user/actions'
import MainTopNav  from 'src/components/organisms/MainTopNav/ui';
import { MainSideNav } from 'src/components/organisms/MainSideNav';
import { SecondarySideNav } from 'src/components/organisms/SecondarySideNav';

const PrivateRoute:any = ({ component: Component, ...rest }) => {
    let isAuthed = localStorage.getItem('token');
    if (isAuthed) getUser(); 
    return (
        <Route
            {...rest}
            render={ props => {
                if (isAuthed) return <Component {...props} />;
                return (
                    <Redirect
                        to={{
                        pathname: "/",
                        state: { from: props.location }
                        }}
                    />
                )
            }}
        />
    )
};
const AppRouter = () => {   
    return(
        <Router>   
            <div className="grid-x">
                <ViewTemplate />
                <div 
                    className="cell auto"
                    style={{marginTop: '90px'}}
                >
                    <Switch>
                        {/* <Route path="/" component={ViewTemplate} /> */}
                        <Route path="/signin/:token"  render={({match})=>{
                            console.log('match.params :', match.params.token);
                            signInUserRaw({
                                url_token: match.params.token,
                                email:'',
                                username:''
                            })
                            return '';
                        }} />
                        <Route path="/quick-start/:step"  component={ quickStartAuth(QuickStartView)} />
                        <Route path="/quick-start" component={ quickStartAuth(QuickStartView)} />
                        {/* <Route path="/project/:id" component={ projectAuth(ProjectView) } /> */}
                        <PrivateRoute exact={true} path="/project/:projectID" component={ ProjectView } />
                        <PrivateRoute exact={true} path="/project/:projectID/page/:pageID" component={ PageView } />
                        <PrivateRoute exact={true} path="/project/:projectID/page/:pageID/vig/:vigID" component={ VigView } />
                        <PrivateRoute exact={true} path="/project/:projectID/page/:pageID/vig/:vigID/layer/:layerID" component={ LayerView } />
                        <Route  path="/" component={App} />
                    </Switch>
                </div>
            </div>
        </Router>
    )
};


export default AppRouter;
