export {renderHTML} from "./renderHTML";
/*
    used for RenderProps example:
    https://levelup.gitconnected.com/ultimate-react-component-patterns-with-typescript-2-8-82990c516935
    https://github.com/Hotell/blogposts/blob/master/2018-02/ultimate-react-component-patterns/src/shared/utils.ts
*/

import { Children, ReactNode, ComponentType } from 'react'
import { nextTick } from "async";


export function formatTime({format , timeDate}) {
	const currentYear = new Date().getFullYear();

	const timeDateParsed  = new Date(timeDate),
			defaultDate = { 
				// weekday: 'short',
				//year: 'numeric', 
				month: 'short', 
				day: '2-digit'
			},
			defaultTime = {
				hour:'numeric',
				minute: 'numeric'
			};
	let year = (()=>{
		if (timeDateParsed.getFullYear() !== currentYear) return {year: 'numeric'};
		return {};
	})()
	const options = (()=> {
		switch (format) {
			case 'time':
				return { ...defaultTime };
			case 'date':
				return { ...defaultDate, ...year };
			case 'timeAndDate':
				return { ...defaultDate, ...defaultTime,  ...year };
			default:
			return { ...defaultDate, ...defaultTime };
		}
	})()
	return timeDateParsed.toLocaleString("en-US", options);
}


export function simpleID() {
  return Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
}


export const isEmptyChildren = (children: ReactNode) => Children.count(children) === 0

export const isFunction = <T extends Function>(value: any): value is T =>
  typeof value === 'function'

export const getComponentName = (component: ComponentType<any>) =>
  component.displayName || (component as any).name

  export const getHocComponentName = (hocName: string, component: ComponentType<any>) =>
  `${hocName}(${getComponentName(component)})`

// export const withDefaultProps = <P extends object, DP extends Partial<P> = Partial<P>>(
//   defaultProps: DP,
//   Cmp: ComponentType<P>
// ) => {
//   // we are extracting props that need to be required
//   type RequiredProps = Omit<P, keyof DP>
//   // we are re-creating our props definition by creating and intersection type
//   // between all original props mapped to be optional and required to be required
//   type Props = Partial<DP> & Required<RequiredProps>

//   // here we set our defaultProps
//   Cmp.defaultProps = defaultProps

//   // we override return type definition by turning type checker off
//   // for original props  and setting the correct return type
//   return (Cmp as ComponentType<any>) as ComponentType<Props>
// }


export interface GraphQLResponse_I {
  data?:object
  message?: string
  locations?: Array<any>
  // path?:ReactText
  name?:string
  time_thrown?:string
  value?:any
  errors?:Array<any>
  loading: boolean
  networkStatus: number
  stale: boolean
}
export class GraphQLError extends Error {
  constructor(
       public graphqlError: GraphQLResponse_I
  ) {
      super();
      this.graphqlError = {...graphqlError};        
  }
}

interface PromptError_I  {
  primary?:string;
  secondary?:string;
  info?:string;
  status?:string;
}
export class PromptError extends Error {
  constructor(
       public promptError: PromptError_I
  ) {
      super();
      this.promptError = {...promptError, status: 'error'};        
  }
}


export const RenderViewSection = (props) => {
	const {
		section,
		currentViewState,
		children
	} = props;
	const checkCurrentSection = () => {
		if (section === currentViewState) return children;
		return null;
	} 
	return (
		checkCurrentSection()
	);
}