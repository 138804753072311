import * as React from 'react';
import { Component } from 'react';

import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './index.scss';

import Button from 'src/components/atoms/Button'
// import Tabs from 'src/components/molecules/Tabs'
import InputText from '../../../atoms/InputText'
import { InputTextProps_I } from '../../../atoms/InputText/interfaces'
import { createElemDraft, deleteElemDraft, updateElemDraft } from 'src/data/layer/actions'
import withOriginalContent from 'src/data/originalContent/containers' 
import InputFile from 'src/components/atoms/InputFile/ui'
import InputTextSimple from 'src/components/atoms/InputTextSimple';
// import StylesEditor from 'src/components/molecules/StylesEditor/ui'
import { BlockPicker } from 'react-color';
/*
ImageEditor
	ImagePreview ( stored, new)
	EditorNav (editorState = styles, contentManual, contentFromPage )
		ReplaceButton (active)
			OR
		UpdateButtons (active)
		SaveButton (active, disabled, hidden)
	EditorOptions (editorState = styles, contentManual, contentFromPage )
		Styles	( pristine )
			OR
		ManualUpdate ( pristine, error, pending )
			OR
		OriginalContentUpdate
*/


interface IDipatchProps { 
	load?: (ref: string) => void; 
	createElemDraft?:Function
	deleteElemDraft?: Function
	updateElemDraft?: Function
}

interface Props extends IDipatchProps {
	vig?: any
	elem?:Object 
	elems?: Array<any>
	elemEditing: any
	originalContents?: Array<any>
}

interface State extends Props {
	editorState?: 'styles' | 'contentManual' | 'contentFromPage'
	elemEditing: any
	storedImage?:  Object
	previewImage?: any
}

class ShapeContentEditor extends Component<Props & RouteComponentProps<any>, State>  {	
	state: State = {
		editorState: 'styles',
		elemEditing: {},
		storedImage: {},
		previewImage: {}
	};

	static defaultProps = {
		vig: {
			data: {
				created_at: '',
				updated_at: ''
			}, 
			layers: [], 
			contentGroups: [], 
			elems: []
		}
	}
	
	static getDerivedStateFromProps(newProps, prevState) { 
		console.log('newProps :', newProps);
		return newProps;
	}

	updateStyles({ elem, newElemStyles }) {
		const { 
			elemEditing,
			createElemDraft,
			updateElemDraft
			// updateElemContent, // action
		 } = this.props;

		const currentDraft = elemEditing.elem_drafts && elemEditing.elem_drafts[0] ? elemEditing.elem_drafts[0] : null;

		switch (currentDraft) {
			case null:
				createElemDraft({
					targetElemID: elem.id, 
					newElemStyles,
					type: 'styles'
				});
				break;
			default:
				updateElemDraft({
					draftID: currentDraft,
					newElemStyles,
					type: 'styles'
				});
		}
	}

	/* Preview */
	renderEditorPreview({storedElem, draftElem, loading, draftID, elemID}) {
		if (loading) {
			return (
				<div className="grid-x align-middle align-center" style={{minHeight: '200px'}}>
					<div className="progress cell small-8 small-offset-1">
						<div className="indeterminate"></div>
					</div>
				</div>
			);
		}
		if (draftElem) {

			let css:React.CSSProperties = {
					...cssToJsStyles(draftElem.elem_formats.css),
					height:"200px",
					width:"200px",
					position: 'relative',
					opacity: 1
			};
			console.log('css :', css);
			return (
				<div className={`${styles['preview-image']} grid-x align-center align-middle`}>
					<div className={`cell auto grid-x align-middle align-center`}>
						<div style={css}></div>
						<h5 className="cell">Saved Elem</h5>
					</div>
					<div className={`cell auto grid-x align-middle align-center`}>
						<div style={css}></div>
						<h5 className="cell">Draft Elem</h5>
						<div className={`${styles.actions} grid-x`}>
							<i 
								className={`material-icons ${styles.clear}`}
								onClick={()=> this.props.deleteElemDraft({draftID, id: elemID})}
							>clear</i>
							{/* <i className={`material-icons ${styles.save}`}>done</i> */}
						</div>
					</div>
				</div>
			)
		} else {
			let css:React.CSSProperties = {
				...cssToJsStyles(storedElem.elem_formats.css),
				height:"200px",
				width:"200px",
				position: 'relative',
				opacity: 1
			};
			console.log('css :', css);
			return (
				<div className={`${styles['preview-image']} grid-x align-center align-middle`}>
					<div style={css}></div>
				</div>
			)
		}
	}


	/* Editor Body */
	renderEditorStyles({ elemEditing }) {
		const { elem_formats } = elemEditing,
			{ css } = elem_formats;

		const handleColorChange:any = (color, event) => {
			this.updateStyles({ 
				elem: elemEditing, 
				newElemStyles: {
					'background-color': color.hex
				} 
			})
		}
		return <BlockPicker 
					key={'backgroundColor'}
					color={ css['background-color'] || null}
					triangle="hide"
					onChange={ handleColorChange }
					width="300px"
				/>
	}
	
	renderEditorFromPage ({ elem }) {
		const previews = (() =>  {
			return (
				<h4>'Shape' editor options coming soon</h4>
			)
		})()

		return <div className="grid-x align-center align-middle">{ previews }</div>;
	}
	/* Editor Body: end */

	render () {
		const { elemEditing, vig } = this.props,
			{ elems, multimediaObjs } = vig,
			{ editorState, originalContents: multimediaImages } = this.state;

		const draftElem = (()=> {
			const { elem_drafts } = elemEditing;
			if (!elem_drafts || !elem_drafts.length) return null;
			const elem = elems.find(({id}) => elem_drafts[0] === id);
			if (!elem) return null;
			// const allMedia = [...multimediaObjs, ...multimediaImages]
			// const multimedia_obj = allMedia.find(({id})=> elem.multimedia_obj === id);
			return {
				...elem,
				// multimedia_obj
			}
		})()
		
		const editorBody = (()=>{
			switch (editorState) {
				case 'styles':
				default:
					return this.renderEditorStyles({ elemEditing });
			}
		})()

		return(
			<div className="grid-x">
				<div className="cell small-12">
					{ 	this.renderEditorPreview({
							storedElem: elemEditing,
							draftElem: draftElem && draftElem !== 'loading' ? draftElem : null,
							loading: draftElem === 'loading' ? true : false,
							draftID: draftElem  && draftElem !== 'loading' ? draftElem.id : null,
							elemID: elemEditing.id
						}) 
					}
					{ editorBody }
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => state;	

export default withOriginalContent(
						withRouter(
							connect<{}, IDipatchProps, Props & RouteComponentProps<any>>(
								mapStateToProps,
								{ createElemDraft, deleteElemDraft, updateElemDraft}
							)(ShapeContentEditor)
						),
					['images']
				);



function cssToJsStyles(cssObj) {
	return Object.entries(cssObj).reduce((acc,entry) => {
		let prop = entry[0],
			value = entry[1],
			parsedProp = parseProp(prop)
		return {
			...acc,
				[parsedProp]: value
		}	
	},{})

	function parseProp(prop) {
		let splitProp = prop.split('-');
		return splitProp.reduce((acc, elem, i)=> {
			if (i === 0) return acc + elem.toLowerCase();
			return acc+capitalizeFirstLetter(elem)
		},'')
	}
	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
}