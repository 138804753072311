import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './index.scss';

import ViewTitles from 'src/components/molecules/ViewTitles';
import {ViewTitles_I} from 'src/components/molecules/ViewTitles/interfaces';
import InputText from 'src/components/atoms/InputText';
import { InputTextProps_I } from 'src/components/atoms/InputText/interfaces'
import Button from 'src/components/atoms/Button';
import { Button_I} from 'src/components/atoms/Button/interfaces';
import FormPrompts from 'src/components/molecules/FormPrompts';
import { FormPromptsProps_I } from 'src/components/molecules/FormPrompts/interfaces';
import AuthForm from 'src/components/organisms/AuthForm';
import  { Project_I } from 'src/data/project/interfaces';
import {User_I} from 'src/data/user/interfaces';

import { 
	validateNewProject,
	selectProject,
	setProjectDefaults
} from 'src/data/project/actions';
import {
	registerUser
} from 'src/data/user/actions';

import clientViewStates  from 'src/shared/constants/viewStates';
const projectViewStates = clientViewStates.viewStates; 

import * as componentConfig from './config';

import VigPreview from 'src/components/atoms/VigPreview'

interface State {
	draft?:String;
	formPrompts?:FormPromptsProps_I;
	project?: Project_I
	user?:User_I
	projectButton?: Button_I
	viewState?: object
	viewTitles:ViewTitles_I
	currentViewState?:string
	vigs?: Array<any>
}

interface Props {
	currentViewState?:string;
	mapStateToProps?:Function;
	viewTitles?: ViewTitles_I
	validateNewProject?:Function
	selectProject?: Function
	registerUser?: Function
	setProjectDefaults?: Function
	project?: Project_I
	viewTemplate?: any
	quickStart?: any
}

class QuickStartView extends Component<Props & RouteComponentProps<any>, State>  {	
	state:State = componentConfig.defaultState; 
	// why cant I destructure this....
	authButtonStyles:Button_I = componentConfig.defaultProps.authButtonStyles;
	authButtonData:Button_I[] = componentConfig.defaultProps.authButtonData;
	authButtons = this.authButtonData.map(btnData => {
		return Object.assign({},this.authButtonStyles, btnData)
	})

	componentWillReceiveProps (newProps) {
		console.log('newProps :', newProps);

		const {quickStart} = newProps,
			  { viewTemplate } = quickStart;

		this.setState((prevState) => {
			return {
				...prevState,
				...viewTemplate,
				viewTitles: {
					...prevState.viewTitles,
					...viewTemplate.viewTitles
				},
				formPrompts: {
					...prevState.formPrompts,
					...viewTemplate.formPrompts
				}
			}
		});
	}

	renderAuthBtns = () => {
		return this.authButtons.map((btnData, index) =>{
			return <Button key={index + new Date().getTime() }  {...btnData} />
		})
	}
	handleProjectInputChange = ({value}) => {

		if (value && value.length && this.state.formPrompts.status !== 'pending') {
			this.props.validateNewProject(value);
		}	
	}
	inputProps:InputTextProps_I = {
		size:'large',
		placeholder:'Enter URL of your project...',
		validation: {
			execution: [
				'onChange',
				// 'onBlur'
			],
			setStatus: this.handleProjectInputChange
		},
		classNames: 'cell small-12'
	}
	
	authFormSubmit = (formData) => {
		console.log('this.props.quickStart.viewTemplate.project.targetURL :', this.props.quickStart.viewTemplate.project.targetURL);
		// console.log(' this.props.project.targetURL :',  this.props.project.targetURL);

		// console.log('this.props.project.targetURL :', this.props.viewTemplate.project.targetURL);
		// console.log('formData :', formData);
		// let registrationData = Object.keys(formData).reduce((acc,key)=> {
		// 	return {
		// 		...acc,
		// 		[formData[key].type]: formData[key].value
		// 	}
		// },{});
		this.props.registerUser({
			...formData,
			newAccountSubscription: 'free' ,
			defaultProject: this.props.quickStart.viewTemplate.project.targetURL
		});
	}

	renderVIGPreviews = (vigs) => {
		if (vigs && vigs.length) {
			return vigs.map((vig,i) => <VigPreview key={i} onSelect={this.handlePreviewSelect}  {...vig.preview} />)			
		}
	}

	handlePreviewSelect = ({project, pageTemplate}) => {
		// this.props.setProjectDefaults({project, pageTemplate, history:this.props.history})
	}

	renderStep = (viewState) => {
		switch (viewState) {
			case 'validating':
				return (
					<div className="cell small-12 grid-x align-spaced">
						<InputText {...this.inputProps}/>
						<div className="cell small-12 grid-x align-evenly">
							<FormPrompts {...this.state.formPrompts}/>
						</div>
						<div className="spacer-inline spacer40"></div>
						<div className="cell small-12 grid-x align-evenly">
							{this.renderAuthBtns()}
						</div>
					</div>
				);
			case 'valid':
				return (
					<div className="cell small-12 grid-x align-spaced">
						<InputText {...this.inputProps}/>
						<div className="cell small-12 grid-x align-evenly">
							<FormPrompts {...this.state.formPrompts}/>
						</div>
						<Button 
							action={this.props.selectProject}
							{...this.state.projectButton} 
						/>
						<div className="spacer-inline spacer40"></div>
						<div className="cell small-12 grid-x align-evenly">
							{this.renderAuthBtns()}
						</div>
					</div>
				);
			case 'registering':
				return (
				<div className="cell small-12 grid-x align-spaced">
					<AuthForm  
						onFormSubmit={this.authFormSubmit}
					/>
				</div>);
			
			case 'fetching':
				return (
					<div className="cell small-12 grid-x align-spaced">
						<FormPrompts {...this.state.formPrompts}/>
					</div>);
			case 'previewing':
				return (
					<div className="cell small-12 grid-x align-spaced">
						{this.renderVIGPreviews(this.state.vigs)}
					</div>
				);
			default:
				return (
				<div className="cell small-12 grid-x align-spaced">
					<div>
						<h1> No...</h1>
					</div>
				</div>);
		}
	}

	renderViewTitles () {
		if (this.state.viewTitles.primary) {
			return <ViewTitles {
				...this.state.viewTitles,
				{
					primary: this.state.viewTitles.primary,
					secondary:this.state.viewTitles.secondary,
					position:'center'
				}
			} />;
		} 
	}

	render () {
		return(
			<div className="grid-x">
				<div className="cell small-8 small-offset-2">
					<div className={`grid-x grid-margin-x ${styles['quick-start-container']}`}>
						<div className="cell small-12">
							<div className="spacer-inline spacer40"></div>
							{ this.renderViewTitles() }
							<div className="spacer-inline spacer20"></div>
							{ this.renderStep(this.state.currentViewState) }
						</div>
					</div>
				</div>
			</div>
		);	
	}
}

const mapStateToProps = (state:any ) => state;

export default withRouter(
	connect<{},Props>(
		mapStateToProps,
		{ validateNewProject, registerUser, selectProject, setProjectDefaults }
	)(QuickStartView)
);



