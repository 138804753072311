import * as React from 'react';
import { Component } from 'react';

import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './index.scss';

import Button from 'src/components/atoms/Button'
// import Tabs from 'src/components/molecules/Tabs'
import InputText from '../../../atoms/InputText'
import { InputTextProps_I } from '../../../atoms/InputText/interfaces'
import { createElemDraft, deleteElemDraft, updateElemDraft } from 'src/data/layer/actions'
import withOriginalContent from 'src/data/originalContent/containers' 
import InputFile from 'src/components/atoms/InputFile/ui'
import InputTextSimple from 'src/components/atoms/InputTextSimple';
import { EditorNav } from '../../Editors/modules/EditorNav'
import StylesEditor from 'src/components/molecules/StylesEditor/ui'

interface IDipatchProps { 
	load?: (ref: string) => void; 
	createElemDraft?:Function
	deleteElemDraft?: Function
	updateElemContent?: Function
}

interface Props extends IDipatchProps {
	vig?: any
	elem?:Object 
	elems?: Array<any>
	multimediaObj?: any
	elemEditing: any
	originalContents?: Array<any>
	user?:any
}

interface State extends Props {
	editorState?: 'styles' | 'contentManual' | 'contentFromPage' | 'stylesAdmin'
	elemEditing: any
	multimediaObj: any
	storedImage?:  Object
	previewImage?: any
}

class ImageContentEditor extends Component<Props & RouteComponentProps<any>, State>  {	
	state: State = {
		editorState: 'styles',
		elemEditing: {},
		multimediaObj: {},
		storedImage: {},
		previewImage: {}
	};

	static defaultProps = {
		vig: {
			data: {
				created_at: '',
				updated_at: ''
			}, 
			layers: [], 
			contentGroups: [], 
			elems: [], 
			multimediaObjs: []
		}
	}
	
	static getDerivedStateFromProps(newProps, prevState) { 
		console.log('newProps :', newProps);
		return newProps;
	}

	startContentUpdate({ from, multimediaObj, elem }) {
		this.setState(prevState=>{
			return {
				editorState: from
			}
		})
	}
	/* redundant. Refactor!!! */ 
	replaceMedia({ elem = null, newObjID = null, newMediaObj = null }) {
		const { 
			elemEditing,
			createElemDraft
			// updateElemContent, // action
		 } = this.props;

		const currentDraft = elemEditing.elem_drafts && elemEditing.elem_drafts[0] ? elemEditing.elem_drafts[0] : null;
		
		 switch (currentDraft) {
			case null:
				if (newObjID) {
					createElemDraft({
						targetElemID: elem.id, 
						newObjID,
						type: 'mediaFromPage'
					});	
				}
				if (newMediaObj) {
					createElemDraft({
						targetElemID: elem.id, 
						newMediaObj,
						type: 'mediaManually'
					});	
				}
				break;
			default:
				if (newObjID) {
					updateElemDraft({
						draftID: currentDraft, 
						newMediaID: newObjID,
						type: 'mediaFromPage'
					});	
				}
				if (newMediaObj) {
					updateElemDraft({
						draftID: currentDraft, 
						newMediaObj,
						type: 'mediaManually'
					});	
				}
		}
	}
	editContent(contentEditing) {
		this.setState(prevState => {
			return {
				...prevState,
				contentEditing
			}
		})
	}

	/* Preview */
	renderEditorPreview({storedImage, draftImage, loading, draftID, elemID}) {
		if (loading) {
			return (
				<div className="grid-x align-middle align-center" style={{minHeight: '200px'}}>
					<div className="progress cell small-8 small-offset-1">
						<div className="indeterminate"></div>
					</div>
				</div>
			);
		}
		if (draftImage) {
			return (
				<div className={`${styles['preview-image']} grid-x align-center align-middle`}>
					<div className={`cell auto grid-x align-middle align-center`}>
						<img src={storedImage} width="200" />
						<h5 className="cell">Saved Image</h5>
					</div>
					<div className={`cell auto grid-x align-middle align-center`}>
						<img src={draftImage} width="200" />
						<h5 className="cell">Draft Image</h5>
						<div className={`${styles.actions} grid-x`}>
							<i 
								className={`material-icons ${styles.clear}`}
								onClick={()=> this.props.deleteElemDraft({draftID, id: elemID})}
							>clear</i>
							{/* <i className={`material-icons ${styles.save}`}>done</i> */}
						</div>
					</div>
				</div>
			)
		} else {
			return (
				<div className={`${styles['preview-image']} grid-x align-center align-middle`}>
					<img className="cell auto" src={storedImage} width="200" />
				</div>
			)
		}
	}

	/* Editor Body */
	renderEditorStyles({ elemEditing, multimediaObj, admin = false }) {
		let props = {
			media:'image',
			elemEditing, 
			multimediaObj,
			admin
		}
		return <StylesEditor {...props} />;
	}
	renderEditorManual ({elemEditing}) {
		const { previewImage, } = this.state;

		return (
			<div className="grid-x align-middle align-center">
				<InputFile 
					onChange={ 
						(file)=> {
							let { name } = file,
								fr = new FileReader();

							if (file) {
								fr.onload = () =>{
									this.setState({
										previewImage: {
											imageData: fr.result,
											original_filename: name
										}
									})
								};
								fr.readAsDataURL(file);
							}
						}
					}
					onAccept={ () => {this.replaceMedia({ elem:elemEditing, newMediaObj: previewImage }) } }
				/>
				{/* <h4 className="cell small-12 text-center">OR</h4>
				<InputTextSimple label={{text: 'Image URL', position:'left'}} size="large" /> */}
				{/* <Button 
					label={{text: 'Preview Image'}}
					status={ previewImage && previewImage.imageData ? 'visible' : 'disabled'}
					action={ () => {this.replaceMedia({ elem:elemEditing, newMediaObj: previewImage }) } }
				/> */}
			</div>
		)
	}
	renderEditorFromPage ({ multimediaImages, elem }) {
		const previews = (() =>  {
			return multimediaImages
			.sort((a,b)=>{
				if (!a.scores || !a.scores.total) return 1;
				if (!b.scores || !b.scores.total) return -1;
				if (a.scores.total < b.scores.total) return 1;
				if (a.scores.total > b.scores.total) return -1;
				return 0;
			})		
			.reduce((acc,image) => {
				let { s3_path, original_filename, id } = image;
				if (id === elem.multimedia_obj) return acc;
				let preview = (
					<div
						key={id}
						onClick={() => this.replaceMedia({elem, newObjID: id})}
						className={`${styles['content-option']} cell`}
						style={{backgroundImage:`url(${s3_path})`}}
					></div>	
				);
				return [...acc, preview]
			},[]) 
		})()

		return <div className="grid-x align-center align-middle">{ previews }</div>;
	}
	/* Editor Body: end */

	render () {
		const { elemEditing, multimediaObj, vig, user } = this.props,
			{ elems, multimediaObjs } = vig,
			{ editorState, originalContents: multimediaImages } = this.state;

		const draftElem = (()=> {
			const { elem_drafts } = elemEditing;
			if (!multimediaImages.length) return 'loading';
			if (!elem_drafts || !elem_drafts.length) return null;
			const elem = elems.find(({id}) => elem_drafts[0] === id);
			if (!elem || ! elem.multimedia_obj) return null;
			const allMedia = [...multimediaObjs, ...multimediaImages]
			const multimedia_obj = allMedia.find(({id})=> elem.multimedia_obj === id);
			return {
				...elem,
				multimedia_obj
			}
		})()
		
		const editorBody = (()=>{
			switch (editorState) {
				case 'contentManual':
					return this.renderEditorManual({elemEditing});
				case 'contentFromPage':
					return this.renderEditorFromPage({multimediaImages, elem: elemEditing });
				case 'stylesAdmin':
					return this.renderEditorStyles({ elemEditing, multimediaObj , admin: true});
				case 'styles':
				default:
					return this.renderEditorStyles({ elemEditing, multimediaObj });
			}
		})()
		
		return(
			<div className="grid-x">
				<div className="cell small-12">
					{ 	this.renderEditorPreview({
							storedImage: multimediaObj.s3_path,
							draftImage: draftElem  && draftElem !== 'loading' && draftElem.multimedia_obj && draftElem.multimedia_obj.s3_path ? draftElem.multimedia_obj.s3_path : null,
							loading: draftElem === 'loading' ? true : false,
							draftID: draftElem  && draftElem !== 'loading' ? draftElem.id : null,
							elemID: elemEditing.id
						}) 
					}
					<EditorNav {...{ 
						media: 'Image',
						editorState, 
						pristine: true, 
						multimediaObj, 
						elem: elemEditing,
						startContentUpdate: this.startContentUpdate.bind(this),
						isSuperAdmin: user.super_admin ? true : false
					}} />
					{ editorBody }
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => state;	

export default withOriginalContent(
						withRouter(
							connect<{}, IDipatchProps, Props & RouteComponentProps<any>>(
								mapStateToProps,
								{ createElemDraft, deleteElemDraft}
							)(ImageContentEditor)
						),
					['images']
				);
