import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'

import styles from './index.scss';

import Node from '../../../atoms/TreeNode/ui'
import  TickerBoard  from '../../../molecules/TickerBoard/ui'
 import { TreeNode_I } from '../../../atoms/TreeNode/interfaces'
import { TickerBoard_I } from '../../../molecules/TickerBoard/interfaces'


// type TreeMenuOverview_I = {}
// type  TreeMenuDetails_I = {
	// ancestors?: Array<TreeNode_I>
	// activeNode?: TreeNode_I
	// childCount?: TickerBoard_I
	// children?: Array<TreeNode_I>
// }
interface TreeMenu_I {
	treeType?: string //'details' | 'overview'
	ancestors?: Array<TreeNode_I>
	activeNode?: TreeNode_I
	childCount?: TickerBoard_I
	children?: Array<TreeNode_I>
	// data?: TreeMenuDetails_I 
	// | TreeMenuOverview_I
	pages?: any 
	pageViewing?:string
	projectID?: string
	handleCreate?: Function
}
interface State extends TreeMenu_I {
	treeData?:any
	key?: number
}

class TreeMenu extends Component<TreeMenu_I & RouteComponentProps<any>, State> {
	mounted;
	state = {
		treeData: null
	};

	static defaultProps = {
		treeType: 'details', // details, overview
		ancestors: [
			{ id:'', title:'', onClick: () => {}}
		],
		activeNode: { id:'', title:'', active:false, thumbnail:'' },
		childCount: {
			title: 'Children',
			tallys: [
				{
					title: 'Direct',
					count: 0
				},
				{
					title: 'Total',
					count: 0
				}
			]
			
		},
		children: [
			{ id:'', title:'', active:false, thumbnail:'', onClick: ()=>{} }
		]
	};

	async componentDidMount () {
		const { pages = [], pageViewing, projectID, handleCreate } = this.props;
		this.mounted = true;

		if (pages.length) {
			let rootPage = (() => {
				if (pageViewing) return pages.find(page => page.id === pageViewing);  
				return pages.find(page => page.project === projectID && page.url_path === '/');
			})()
			if (rootPage && rootPage.id && pages && pages.length) {
				console.log('pages :', pages);
				let { ancestors, children, childCount, activeNode } = await renderProjectTree({ 
					pages, 
					rootPage,
					handleCreate
				});
				
				if (activeNode.id) { 
					let treeData = await buildTreeData({ancestors, children, childCount, activeNode})
					if (this.mounted) {
						return this.setState(prevState=> {
							return { ...prevState, treeData }
						})
					}
				}
			}
		}
	}

	// should move this async logic into a container
	componentWillUnmount(){
		this.mounted = false;
	}

	renderTree (treeData) {	
		let { treeType } = this.props;		
		let key = Math.random();
		switch (treeType) {
			case 'details':
				return (
					<ul className="ancestors">
						<Node 
							size="small"
							key={key}
							{ ...treeData }
						/>
					</ul>
				);
			default:
				return <div></div>;
		}
	}

	render () {
		return (
			<nav 
				className={`${styles['tree-menu']}`}
			>
				{ this.state.treeData ? 
					<ul className="ancestors">
						<Node 
							size="small"
							key={Math.random()}
							{ ...this.state.treeData }
						/>
					</ul> 
					: 
					<div>Loading...</div>
				}
			</nav>
		) 
	}
}

async function buildTreeData({ancestors, children, childCount, activeNode}) {
	let childrenDomNode = renderChildren({ children, childCount }),
		defaultNode = {
				...activeNode,
				size:"large",
				children: [ childrenDomNode ],
				childrenType: 'dom_nodes',
		}
	if (ancestors.length) {
		let treeData = await buildNodeTree({ 
			arr: ancestors,
			defaultNode
		})
		return treeData 

	}
	return defaultNode 
}

async function renderProjectTree ({ rootPage, pages, handleCreate }) {	

		let errandChildren = (()=>{
			if (rootPage.url_path !== '/') return [];
			return pages.filter(page => page.parent === null);
		})();
		let ancestors =  await (() => {
			if (rootPage.url_path && rootPage.url_path === '/') return [];
			return findAncestorsRecursive({ 
				pages, 
				pageID: rootPage.id,
				handleCreate: handleCreate
			})
		})()

		let directChildren = pages.filter(page => page.parent === rootPage.id)

		let sortedChildren = [...directChildren, ...errandChildren].sort((a,b)=> {
			// console.log('a,b :', a,b);
			if (a.active_vig && !b.active_vig) return -1;
			if (b.active_vig && !a.active_vig) return 1;
			return 0;
		})



		let children = //await Promise.all(
             sortedChildren	
				.reduce((acc,page) => {
					// de-duping pages for some reason??? This should be solved further upstream
						let addedPage:any = (()=> {
							if (!acc.length) return null;
							return acc.find(({id}) => id === page.id);
						})()
						if (addedPage && addedPage.id) return acc;
						
					let allChildrenCount = countAllChildren({pages, pageID: page.id}),
						children = (() => {
							if (allChildrenCount < 1) return null;
							return [{ title: allChildrenCount + ' Children', size:'tiny' }];
						})()

					let node = nodeFromPage(page, handleCreate);
					return [
						...acc,
						{
							...node,
							children
						}
					]
				},[])
			//);
		

		let treeData = {
			ancestors,	
			activeNode: nodeFromPage(rootPage, handleCreate),
			children,
			childCount: {
				title: 'Children',
				tallys: [
					{
						title: 'Direct',
						count: children.length
					},
					{
						title: 'Total',
						count: countAllChildren({ pages, pageID: rootPage.id })
					}
				]
				
			},
		};

		// this.setState((prevState) => {
		return treeData;
				// key: Math.random() //excessive re-rendering for is_scrpaing!!
			// }
		// })
	// }

}


function nodeFromPage(page, onCreate) {
	if (page.is_scraping) {
		console.log('page :', page);
	}
	let title = (() => {
		console.log('page :', page);
		if (page.parent === null && page.url_path !== '/') return page.url_path + (page.url_hash || "") + (page.url_query || "");
		if (page.url_path === '/') {
			let isRoot =  !page.url_hash && !page.url_query ; 
			return isRoot ? page.url_project_root : page.url_path
					+ page.url_hash 
					+ page.url_query; 
		}
		let split = page.url_path.split('/'),
			lastPath = split[split.length -1];
		
		return '/'+ lastPath + (page.url_hash || "") + (page.url_query || "");
	})();
	let noConnector = (()=> {
		if (page.url_path === '/') return { noConnector: true };
		return {};
	})()
	return {
		id: page.id,
		thumbnail: page.active_vig && page.active_vig.path_thumbnail ? page.active_vig.path_thumbnail : null,
		title,
		selectPath: '/project/'+page.project+'?pageViewing='+page.id,
		editPath: page.scraped === true ? '/project/'+page.project+'/page/'+page.id : null,
		createPath: () => {
			onCreate({pageID: page.id})
		},
		pendingMsg: page.is_scraping === true ? "Hold on, we're populating this one!" : null,
		//'/project/'+page.project+'/page/'+page.id,
		...noConnector
	}
}

function countAllChildren({ pages, pageID }) {
		var count = 0;

		count_leaves({pages, pageID})

		return count;

		function count_leaves({pages, pageID}) {
			let children = pages.filter(page => page.parent === pageID);
			if(children.length) {
				for(var i = 0; i< children.length; i++){
					count++;
					let id = children[i].id,
						grandChildren = pages.filter(page => page.parent === id);
					if (grandChildren.length){
						count_leaves({pages, pageID:id});
					}
					
				}
			}
		}
	}

function findAncestorsRecursive({ pages, pageID, ancestorList = [], handleCreate }) {
		return new Promise(resolveRecursive => {

			const findAncestors = ({ pages, pageID, ancestorList }) => {
				let active = pages.find(page => page.id === pageID);	
				
				if (active.parent === null) resolveRecursive(ancestorList);

				let parent = pages.find(page => page.id === active.parent);

				findAncestors({
					pages, 
					pageID: parent.id,
					ancestorList: [
						...ancestorList,
						nodeFromPage(parent, handleCreate)
					]
				})
			}

			findAncestors({ pages, pageID, ancestorList })
		})
	}
function renderChildren({ children, childCount }) {
	return (
		<div className="grid-x">
			<div className="cell medium-8">
				{ children.length ? children.map((node,i)=> <Node size="tiny" key={i} {...node} noConnector={true} />) : '' }
			</div>
			<div className="cell medium-4">
				{ childCount.tallys.length ? <TickerBoard {...childCount} /> : '' }
			</div>
		</div>
	)
}
function buildNodeTree({arr, defaultNode}) {
	return new Promise(resolveRecursive => {
		
		buildNode({ arr, tree: defaultNode, noConnector: true })

		function buildNode({arr, tree, noConnector}) {
			if (!arr.length) resolveRecursive(tree);

			buildNode({
				arr: arr.slice(1),
				tree: {
					...arr[0],
					noConnector,
					children: tree ? [ tree ] : null
				},
				noConnector: false,
			})
		}
	})
}
export default withRouter(TreeMenu);
