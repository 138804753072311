import { store } from 'src/index';
import {
    REGISTER_USER,
    SIGN_IN_USER,
    GET_USER,
    SIGN_OUT_USER
} from './types';
import {
    BUILD_VIG_MEDIA
} from '../../vig/actions/types'
import axios from 'axios';
import { SERVER_ROOT, AUTH_HEADERS, WEBSOCKET_ROOT } from 'src/shared/constants'; 
const USER_ROOT = SERVER_ROOT + '/user';
import { Redirect } from 'react-router-dom';
// var testRes =  require('../../../../../dev-json/quickStart-res.json')
import Amplify,{ Auth, API, graphqlOperation } from "aws-amplify";
import aws_config from "../../../aws-exports";
import * as subscriptions from 'src/graphql/subscriptions';

declare var NODE_ENV: string;

export const checkAuth = () => {
    let storeObj:any = store.getState(),
        user = storeObj.user;

    if (user && user.isAuthed) return true;

    let currentToken = localStorage.getItem('token');
    if (currentToken) {
        

        let userData:any = getUser()
        // .catch(err=> { console.log(err); return false })
        return false;
    }
    return false;
}

export const getUser = () =>{
    axios.get(
        USER_ROOT,
        AUTH_HEADERS
    )
    .catch(err=> {
        console.log(err)
    })
    .then((res:any) => {
        // id, username, email
        if (res.data) {
            store.dispatch({type: GET_USER, payload: res.data});
        }

    });

};


export const registerUser = (userInput) => {
    const input  = {
        ...userInput,
        singlePageOnly:false
    }
    console.log('input :', input);
    // test script
    // console.log('testRes :', testRes);
    // return function (dispatch) {
    //     dispatch({ 
    //         type: REGISTER_USER, 
    //         payload: { ...testRes,  currentViewState:'previewing', isAuthed: true  }
    //     });
    // }
    
    let supportsWebSockets = 'WebSocket' in window || 'MozWebSocket' in window;

    if (supportsWebSockets) {
        return function (dispatch) { 
            let ws = new WebSocket(WEBSOCKET_ROOT+'/user/register');
    
            ws.onopen = function() {
                console.log('app connected to websocket!');
                ws.send(JSON.stringify(input))
            };
    
            ws.onmessage = function (message) {
                // id, username, email, token,
                console.log('message :', message);
                let data = JSON.parse(message.data)
                console.log('data :', data);
                
                if (data.viewTemplate.formPrompts.status === 'pending') {
                    dispatch({ 
                        type: REGISTER_USER, 
                        payload: {
                            ...data, 
                            viewTemplate: {
                                ...data.viewTemplate,
                                currentViewState:'fetching' 
                            }
                        }
                    });
                }
                if (data.viewTemplate.formPrompts.status === 'success') {
                    dispatch({ 
                        type: REGISTER_USER, 
                        payload: { 
                            ...data,  
                            viewTemplate: {
                                ...data.viewTemplate,
                                currentViewState:'previewing',
                                viewTitles: {
                                    secondary: 'Select your favorite template. You can do more customization after.'
                                }
                            }
                        }
                    });
                }                
                if (data.viewTemplate.formPrompts.status === 'error') {
                    dispatch({ 
                        type: REGISTER_USER, 
                        payload: {
                            ...data,
                            viewTemplate: {
                                ...data.viewTemplate,
                                currentViewState:'fetching'
                            }
                        }
                    });
                }
            }

            ws.onclose = function (message) {
                console.log('closed :', message);
            }
        }
    } else {
        return function (dispatch) { 
            axios.post(
            USER_ROOT,
            { data: input },
            AUTH_HEADERS
            )
            .catch(err=> {console.log(err)})    
            .then((res:any) => {
                // id, username, email, token,
                console.log('register', res)
                dispatch({ type: REGISTER_USER, payload: res.data });
            });
        }
    }
};

export const signInUser = (input) => dispatch => {
    const {email, username, url_token} = input;
    
    if (!email.length && !username.length && !url_token.length) {
        dispatch({type: SIGN_IN_USER, payload: {error: 'username or email required!'}});
        return false;
    }
    console.log('input :', input);
    axios.post(
        `${USER_ROOT}/signin`,
        { data:input },
        AUTH_HEADERS
    )
    .catch(err=> console.log(err))
    .then((res:any) => {
        console.log('res :', res);
        if (res.data.error || !res.data.user) {
            dispatch({type: SIGN_IN_USER, payload: {error: res.data.error }});
            return false;
        }

        dispatch({type: SIGN_IN_USER, payload: {...res.data, error: null}});

        let prefix = window.location.host.startsWith("http") ? "" : "http://";
        let target = prefix + window.location.host;
        if (!res.data.user.token) { window.location.replace(target+'/quick-start') }
        if (res.data.user.defaultProjectID) {
            return window.location.replace(target+'/project/'+res.data.user.defaultProjectID)    
        }
        // unnecessary now, should be pushing to creatProject, but dont have that yet
        return window.location.replace(target+'/project/686cb000-8254-11e8-b963-14109fdedc87')
    });
};


var isSubscribed = false;
(()=>{
    let interval = 10000;

    let authWorker = setInterval(async ()=>{
        let currentToken = localStorage.getItem('cognito_token');
        if (!currentToken || currentToken === 'undefined') return false;
        let username:any = store.getState().user.id; //+ (NODE_ENV === 'development' ? '-local': '');

        let user = await Auth.signIn(username, currentToken).catch(err => console.log('signin',err));

        let isCloudAuthed = await (async ()=> {
                let user = await Auth.currentAuthenticatedUser()
                                    .catch(err=>console.log('user err :', err));
                // console.log('user :', user);
                if (!user || user.err) return false;
                return user;
            })(); // find thin from Amplify 
        
        if (!isCloudAuthed) return false;
        
        if (username && !isSubscribed) {
            console.log('subscribing :');
            await subscribeCloudAPI({username})
            isSubscribed = true;
        }
    },interval)
})()

Amplify.configure(aws_config);
// Amplify.Logger.LOG_LEVEL = "DEBUG";


const subscribeCloudAPI = async ({username}) => {
    const subscription:any = await API.graphql(
        graphqlOperation(   
            // subscriptions.onUpdateVigVideo,
            subscriptions.onObjectComplete,
            { userId: username}
        )
    )
    console.log('subscription :', subscription);

    subscription.subscribe({
        next: (response:any) => {
            if (!response || !response.value || !response.value.data || !response.value.data.onObjectComplete ) return null;
            
            let { vigId, videoPath:path_video } = response.value.data.onObjectComplete,
                    // createdAt: null
                    // fps: 15
                    // frameFormatsURL: "https://vig-frame-formats.s3.amazonaws.com/b76164b4-fff2-11e8-8a70-0ed8ef18b79c-frame-formats.json"
                    // id: null
                    // status: "complete"
                    // updatedAt: null
                    // userId: "0634abe6-cf1a-11e8-9378-42010a80010c"
                    // videoPath: "https://encode-vig-concat-clips-4.s3.amazonaws.com/test-vids/testvid-0.3715842974023551.mp4"
                    // vigDuration: 80000
                    // vigId: "b76164b4-fff2-11e8-8a70-0ed8ef18b79c"
                    // vigUIID: null
                actionType = (()=> {
                    // need much more structured method of checking this!!
                    return BUILD_VIG_MEDIA;
                    // switch (data.someIdentifier) {
                    //     case 'vig_video_encoded':
                    //     case 'vig_created':
                    //     case 'vig_updated':
                    //     case 'page_scraped':
                    //     //future
                    //     case 'new_page_added':
                    // }
                })();

            store.dispatch({type: actionType, payload: {vigId, path_video}});
            console.log('subscription next',{vigId, path_video}, actionType)
        }
    });

    // // subscription.unsubscribe();
}


export const signInUserRaw = (input) => {
    const {email, username, url_token} = input;
    
    if (!email.length && !username.length && !url_token.length) {
        store.dispatch({type: SIGN_IN_USER, payload: {error: 'username or email required!'}});
        return false;
    }
    console.log('input :', input);
    
    axios.post(
        `${USER_ROOT}/signin`,
        { data:input },
        AUTH_HEADERS
    )
    .catch(err=> console.log(err))
    .then((res:any) => {
        console.log('res :', res);
        debugger;
        if (res.data.error || !res.data.user) {
            store.dispatch({type: SIGN_IN_USER, payload: {error: res.data.error }});
            return false;
        }

        store.dispatch({type: SIGN_IN_USER, payload: {...res.data, error: null}});

        let prefix = window.location.host.startsWith("http") ? "" : "http://";
        let target = prefix + window.location.host;
        if (!res.data.user.token) { window.location.replace(target+'/quick-start') }
        if (res.data.user.defaultProjectID) {
            return window.location.replace(target+'/project/'+res.data.user.defaultProjectID)    
        }
        // unnecessary now, should be pushing to creatProject, but dont have that yet
        return window.location.replace(target+'/project/686cb000-8254-11e8-b963-14109fdedc87')
    });
};


export const signOutUser = () => dispatch => {
    localStorage.removeItem('token');
    localStorage.removeItem('cognito_token');
    Auth.signOut().catch(err => console.log('signout',err));
    dispatch({type: SIGN_OUT_USER})
    let prefix = window.location.host.startsWith("http") ? "" : "http://";
    let target = prefix + window.location.host;
    return window.location.replace(target)
}