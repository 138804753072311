import {
    UPDATE_LAYER_DRAFT,
    CREATE_ELEM_DRAFT,
    UPDATE_ELEM_DRAFT,
    DELETE_DRAFT_ELEM 
} from './types';
import { 
    deleteElemDraft as deleteDraft, 
    updateDraft, 
    createElemDraft as createDraft,
    updateElemDraft as updateElem
} from '../queries'


export const updateElemDraft = ({ 
        draftID, 
        newMediaID = null,
        newMediaObj = null,
        newElemStyles = null, 
        type = ''
    }) => async dispatch => {
    console.log('draftID :', draftID);
    // const queryString = `?draftID=${draftID}`;
    const res:any = await updateElem({ draftID, type, newMediaID, newMediaObj, newElemStyles});
    
    if (res.error) {
        dispatch({
            type: UPDATE_ELEM_DRAFT, 
            payload: {
                viewTemplate: {
                    formPrompts: {
                        primary: "Oop, something went wrong updating your elem draft!",
                        secondary:'',
                        status:'error'
                    }
                }
            }
        });
    } else {
        dispatch({
            type: UPDATE_ELEM_DRAFT,
            payload: res
        })
    }
}

export const deleteElemDraft = ({ id, draftID}) => async dispatch => {
    
    const queryString = `?draftID=${draftID}`;
    const res:any = await deleteDraft({ id, queryString});
    
    if (res.error) {
        dispatch({
            type: DELETE_DRAFT_ELEM, 
            payload: {
                viewTemplate: {
                    formPrompts: {
                        primary: "Oop, something went wrong deleting your elem draft!",
                        secondary:'',
                        status:'error'
                    }
                }
            }
        });
    } else {
        dispatch({
            type: DELETE_DRAFT_ELEM,
            payload: res
        })
    }
}


export const createElemDraft = ({ targetElemID, newObjID, newMediaObj, type, newElemStyles }) => async dispatch => {
    
    const res:any = await createDraft({ targetElemID, newObjID, newMediaObj, type, newElemStyles})
    
    if (res.error) {
        dispatch({
            type: CREATE_ELEM_DRAFT, 
            payload: {
                viewTemplate: {
                    formPrompts: {
                        primary: "Oop, something went wrong updating your layer draft!",
                        secondary:'',
                        status:'error'
                    }
                }
            }
        });
    } else {
        dispatch({
            type: CREATE_ELEM_DRAFT,
            payload: res
        })
    }
}

export const updateLayerDraft = ({ id, action }) => async dispatch => {
    
    const res:any = await updateDraft({id, action})

    if (res.error) {
        dispatch({
            type: UPDATE_LAYER_DRAFT, 
            payload: {
                viewTemplate: {
                    formPrompts: {
                        primary: "Oop, something went wrong updating your layer draft!",
                        secondary:'',
                        status:'error'
                    }
                }
            }
        });
    } else {
        dispatch({
            type: UPDATE_LAYER_DRAFT,
            payload: res
        })
    }
}