import {
    REGISTER_USER
} from '../../user/actions/types'
import {
    FETCH_PAGE_TEMPLATES
} from '../actions/types'

export function pageTemplatesReducer(state = [], action) {
    if (!action.payload || !action.payload.pageTemplates || !action.payload.pageTemplates.length) return state;
    
    switch (action.type) {
        case REGISTER_USER:
            return action.payload.pageTemplates;
        case FETCH_PAGE_TEMPLATES:
            return action.payload.pageTemplates;
        default:
            return state
    }
}

export function pageTemplateReducer(state = {}, action) {
    switch (action.type) {
        default:
            return state
    }
}