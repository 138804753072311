import * as React from 'react';
import { Component } from 'react';

const Spinner = props => {
	const {active} = props;
	return (
		<div 
			className="cell preview-loader grid-x align-middle align-center" 
			style={{
				width:'100%', 
				height:'100%', 
				position:'absolute',
				opacity: active ? 1 : 0,
				visibility: active ? 'visible' : 'hidden'
			}}
		>
			<div className="progress cell small-8 small-offset-1">
				<div className="indeterminate"></div>
			</div>
		</div>	
	)
} 

export default Spinner;