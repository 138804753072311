import { FETCH_ORIGINAL_CONTENTS } from '../actions/types'

const defaultContents = [];
const defaultContent = {};

export function originalContentsReducer(state:any = defaultContents, action) {
    switch (action.type) {
        case FETCH_ORIGINAL_CONTENTS:
            if (action.payload.length) {
                return action.payload;
            }
            return state;
        default:
            return state
    }
}

export function originalContentReducer(state = defaultContent, action) {
    switch (action.type) {
        default:
            return state
    }
}