import * as React from 'react'
import {SelectField} from 'src/components/atoms/SelectField/ui';
import InputText from 'src/components/atoms/InputText'
import InputTextSimple from 'src/components/atoms/InputTextSimple'
import ColorPicker from 'src/components/atoms/ColorPicker/ui'
import Button from 'src/components/atoms/Button'

export function buildStyleFields({ media, elem_formats, handleChange, handleUpdate = null }) {
	let { css, cssContainer, animations, animationsContainer = []} = elem_formats,
		fields = {
			'text':[
				{
					prop:'color', type: 'colorPicker', colors:[], default: css.color || ''},
				{ 	prop:'background-color', type: 'colorPicker', colors:[], default: css['background-color'] || ''},
				{ 	prop:'font-size', type: 'text', default: css['font-size']||''},
				{	obj:'cssContainer' ,prop:'align-items', label: 'position vertical', type: 'select', 
					options:[{value:'flex-start',label:'top', }, {value:'flex-end',label: 'bottom'}, {value: 'center'}], 
					default: cssContainer && cssContainer['align-items'] ? cssContainer['align-items'] : ''
				},
				{
					obj:'cssContainer' ,prop:'justify-content', label: 'position horizontal', type: 'select', 
					options:[{value:'flex-start',label:'left'}, {value:'center'},{value:'flex-end',label:'right'}], 
					default: cssContainer && cssContainer['justify-content'] ? cssContainer['justify-content'] : ''
				},
				{
					prop:'text-align', type: 'select', 
					options:[{value:'left'}, {value:'center'}, {value:'right'}, {value:'justify'}],
					default: css['text-align'] || ''
				}
			],
			'image': [
				{ 	prop:'background-color', type: 'colorPicker', colors:[], default: css['background-color'] || '', OnlyIfExists:true},
				{ 	prop:'height', type: 'text', default: css['height']||'', OnlyIfExists:true},
				{ 	prop:'width', type: 'text', default: css['width']||'', OnlyIfExists:true },
				{ 	prop:'top', type: 'text', default: css['top']||'', OnlyIfExists:true },
				{ 	prop:'bottom', type: 'text', default: css['bottom']||'', OnlyIfExists:true },
				{ 	prop:'left', type: 'text', default: css['left']||'', OnlyIfExists:true },
				{ 	prop:'right', type: 'text', default: css['right']||'', OnlyIfExists:true },
				{	obj:'cssContainer', prop:'align-items', label: 'position vertical', type: 'select', 
					options:[{value:'flex-start',label:'top', }, {value:'flex-end',label: 'bottom'}, {value: 'center'}], 
					default: cssContainer && cssContainer['align-items'] ? cssContainer['align-items'] : '', 
					OnlyIfExists:true
				},
				{
					obj:'cssContainer' ,prop:'justify-content', label: 'position horizontal', type: 'select', 
					options:[{value:'flex-start',label:'left'}, {value:'center'},{value:'flex-end',label:'right'}], 
					default: cssContainer && cssContainer['justify-content'] ? cssContainer['justify-content'] : '',
					OnlyIfExists:true
				},
				{
					prop:'text-align', type: 'select', 
					options:[{value:'left'}, {value:'center'}, {value:'right'}, {value:'justify'}],
					default: css['text-align'] || '',
					OnlyIfExists:true
				}
			]
	};

	const handleColorChange:any = (color, event) => {
		console.log('color :', color);
		handleChange({prop:'color', value: color.hex})
	}
	
	return (
		<div>
			<Button 
				label={{text:'View Changes'}}
				action={ handleUpdate }
				color="primary"
				shape="round"
				size="mini"
				width="cell small-12"
			/>
			<div className="spacer-inline spacer10"></div>
			{fields[media].reduce((acc, field:any) => {
				if (field.OnlyIfExists && (!field.default || !field.default.length)) return acc;
				switch (field.type) {
					case 'colorPicker':
						return [...acc, (()=>{
							const { prop, options, default:defaultVal, obj } = field;
							return (
								<div key={prop} className="grid-x small-12">
									<div className="cell small-4">
										<label>{prop}</label>
									</div>
									<div className="cell small-8">
										<ColorPicker 
											default={defaultVal}
											// colors={[}
											onSelect={ color => handleChange({prop, value: color, obj}) }
										/>
									</div>
								</div>
							)
						})()];
					case 'select':
						return [...acc, (()=>{
							const { prop, label, options, default:defaultVal, obj } = field,
									props = {
										options, 
										handleChange: ({name, value }) => { handleChange({prop:name, value, obj}) }, 
										label: label || prop, 
										default:defaultVal, 
										name: prop
									};
							return <SelectField key={prop} {...props} />;
						})()]
					case 'text':
						return [...acc, (()=>{
							const { prop, default:defaultValue, obj } = field,
								props:any = {
									name:prop,
									label: { text:prop, position:'left' },
									defaultValue,
									onChange: ({name,value}) => { handleChange({prop:name, value, obj}) }, 
								};
							return <InputTextSimple key={prop} {...props}/>
						})()]
					default:
				}	
			},[])}
		</div>
	)
	
}
