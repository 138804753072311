import axios from 'axios'
import { SERVER_ROOT, AUTH_HEADERS } from 'src/shared/constants'; 
const ORIGINAL_CONTENT_ROOT = SERVER_ROOT + '/original-content';
const ORIGINAL_CONTENTS_ROOT = SERVER_ROOT + '/original-contents';

export const fetchAll = async ({ pageID, queryString = '' }) => {
    const res:any = await axios.get(
        `${ORIGINAL_CONTENTS_ROOT}/${pageID}${queryString ? queryString : ''}`,
        AUTH_HEADERS
    )
    .catch(err => { throw new Error(err) })
    
    if (!res.data) throw new Error();
    return res.data;
}
