import {
    FETCH_VIG,
    BUILD_VIG_MEDIA,
    CREATE_VIG_START,
    CREATE_VIG_END,
    CREATE_VIG_REMOVE_ERROR
} from './types';
import {fetchOne, generateOne, createOne } from '../queries'
import { simpleID } from 'src/shared/utils';
import Amplify,{Auth, API, graphqlOperation} from "aws-amplify";
import * as mutations from 'src/graphql/mutations';

export const fetchOneVig = ({ id, queryString, queries, layerViewing }) => async dispatch => {
    
    const res:any = await fetchOne({id, queryString})

    if (res.error) {
        dispatch({
            type: FETCH_VIG, 
            payload: {
                viewTemplate: {
                    formPrompts: {
                        primary: "Oop, something went wrong fetching you vig!",
                        secondary:'',
                        status:'error'
                    }
                }
            }
        });
    } else {
        let newState = (()=> {
            if (!layerViewing) return res;
            const { vig, ...rest } = res;
            return {
                ...rest,
                vig: {
                    ...vig,
                    layerViewing
                }
            }
        })()
        dispatch({
            type: FETCH_VIG,
            payload: newState
        })
    }
}


export const generateOneVig = ({ id, duration, output }) => async dispatch => {
    // const res:any = await generateOne({id, output})
    // const input = {
    //     //remove entirely
    //     bucketName: "open-gif",
    //     dir: "vig-vig-vxnppjw5w6bjof8q5n2",
    //     // have 
    //     userId:"vigAdmin",
    //     vigId: id, //"00321088-e475-11e8-a1ff-42010a80010c",
    //     vigDuration: 80000,
    //     // UI/User generated or defaults
    //     fps: 15,
    //     dimensions: {
    //         width: 1000,
    //         height: 800
    //     },
    //     // need to pass down from server, but should be eliminated and be compose from vigId
    //     vigUIID: "vig-vxnppjw5w6bjof8q5n2",
    //     // need to create on server and update all VIGs, move to S3
    //     // OR quick-and-dirty... create fresh for each call and refactor later
    //     frameFormatsURL: "https://storage.googleapis.com/vig-1/frame-formats/vig-vig-vxnppjw5w6bjof8q5n2",
    // }
    const input = {
        // have 
        // userId:"vigAdmin",
        vigId: id, //"00321088-e475-11e8-a1ff-42010a80010c",
        vigDuration: duration, //80000,
        // UI/User generated or defaults
        // fps: 60,
        fps: 30,
        dimensions: {
            width: 1000,
            height: 800
        }
    }
    console.log('input sending:', input);
    
    const query:any = API.graphql(
        graphqlOperation(mutations.createVigEncoding, {input})
    )

    let attempt = await query.catch(err=>console.log('mutation',err));
    console.log('generate VIG attempt :', attempt);

    /* even need this now? */
    // if (res.error) {
    //     dispatch({
    //         type: BUILD_VIG_MEDIA, 
    //         payload: {
    //             viewTemplate: {
    //                 formPrompts: {
    //                     primary: "Oop, something went wrong fetching you vig!",
    //                     secondary:'',
    //                     status:'error'
    //                 }
    //             }
    //         }
    //     });
    // } else {
    //     dispatch({
    //         type: BUILD_VIG_MEDIA,
    //         payload: res
    //     })
    // }
}

export const createNewVIG = ({ pageID, templateID, output }) => async dispatch => {
    const attemptID = simpleID();
    
    dispatch({
        type: CREATE_VIG_START,
        payload: {
            pageID, 
            templateID,
            attemptID,
            status: 'pending',
            updated_at: '9999999999999999999999999999999999999999'
        }
    })

    const res:any = await createOne({ pageID, templateID, output, attemptID })

    if (res.error) {
        dispatch({
            type: CREATE_VIG_END,
            payload: {
                ...res,
                updated_at: '9999999999999999999999999999999999999999'
            }
        })
        
        setTimeout(()=> {
            console.log('removing :');
            dispatch({
                type: CREATE_VIG_REMOVE_ERROR,
                payload: { 
                    attemptID: res.attemptID 
                }
            })
        },5000)
    } else {
        dispatch({
            type: CREATE_VIG_END,
            payload: res
        })
    }
}
