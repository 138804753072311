// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d0773539-9cc7-4141-bbcf-6d145f05880e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ARUvdGOwR",
    "aws_user_pools_web_client_id": "374h7i7kocg195s2n1sd4j522d",
    "aws_appsync_graphqlEndpoint": "https://ugwlsz5lybgitawq3d6oqucram.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
