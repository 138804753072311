import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import styles from './App.scss';
import 'src/components/App/sass/global.scss?raw';
import * as actions from  'src/data/user/actions';
import ViewTemplate from 'src/components/organisms/ViewTemplate/ui';
import ViewTitles from 'src/components/molecules/ViewTitles';
import { ViewTemplate_I } from 'src/components/organisms/ViewTemplate/interfaces';
import AuthForm from 'src/components/organisms/AuthForm'

interface Props {
  resData?: object
  registerUser?:Function
  signInUser?:Function
  getUser?:Function
  viewTemplate?: ViewTemplate_I
}

interface State {
  authError?: string;
}

class App extends Component<Props,State> {
  state = {
    authError: ''
  }
  // userTest = {
  //   username:"userwpass12345",
  //   email:"fake@email12345.com",
  //   password:"pa$$w0rd"
  // }

  // handleCreateClick = () => {
  //   console.log('create test');
  //   this.props.registerUser(this.userTest);
  // }

  static getDerivedStateFromProps(props, state){
    const { user } = props;
    if (user && user.authError) {
      return {...state, authError: user.authError}
    }
    return state;
  }

  handleAuth = (fields) => {
    this.props.signInUser(fields);
  }

  // handleLogOutClick = () => {
  //   localStorage.removeItem('token');
  // }

  // handleGetClick = () => {
  //   this.props.getUser({
  //     //fail
  //     // id: "0dc0cc52-5af1-11e8-a34e-c8bcc88ea655",
  //     //success
  //     id: "8f0e7d08-5ee4-11e8-b9a2-c8bcc88ea655",

  //   });
  // }


  renderViewTitles () {
    console.log('this.props vt:', this.props);
    if (this.props.viewTemplate && this.props.viewTemplate.formPrompts) {
      return  <ViewTitles {...this.props.viewTemplate.formPrompts} />
    } else {
      <p dangerouslySetInnerHTML={{__html: JSON.stringify(this.props)}}></p>
    }
  }
  render() {
    const {authError} = this.state;

    return (
      <div className={`${styles.App} grid-x align-middle align-center`}>              
          {/* <h1 className={`${styles['app-title']} cell`}>Log In</h1> */}
          {/* <img src={require("public/images/logos/leaf-logo-final-crop.png")} /> */}
          <div className="spacer-inline spacer60"></div>
          {this.renderViewTitles()}
          <div className="cell small-12 medium-10">
            <AuthForm 
              formType='login' 
              onFormSubmit={this.handleAuth} 
              parsedRes={true} 
              formPrompts={authError && authError.length ? {status:'error', primary:authError} : null}
            />
          </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return state;
}

export default connect<Props>(mapStateToProps, actions)(App);

