import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers, Middleware } from 'redux';
// import { createEpicMiddleware } from 'redux-observable';
import reduxThunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';
import AppRouter from './components/App/Router';
import ViewTemplate from 'src/components/organisms/ViewTemplate/ui'
//Reducers
import { userReducer, usersReducer } from './data/user/reducers';
import accountReducer from './data/account/reducers';
import quickStartReducer from './data/quickStart/reducers';
import viewTemplateReducer from './data/viewTemplate/reducers';
import { 
  // pageTemplateReducer, 
  pageTemplatesReducer 
} from './data/pageTemplate/reducers';
import { projectReducer, projectsReducer } from './data/project/reducers';
import { pageReducer, pagesReducer } from './data/page/reducers';
import { vigReducer, vigsReducer } from './data/vig/reducers';
import { originalContentReducer, originalContentsReducer } from './data/originalContent/reducers';
import { REGISTER_USER, SIGN_IN_USER, SIGN_OUT_USER } from './data/user/actions/types'

import * as ReactGA from 'react-ga';

ReactGA.initialize("UA-126136664-1", {
  // debug: true,
  gaOptions:{cookieDomain: 'auto'}
});

ReactGA.pageview(window.location.pathname + window.location.search);

console.log('git deploy test! FINAL 2');

const appReducer = combineReducers({
  users: usersReducer,
  user: userReducer,
  account: accountReducer,
  quickStart: quickStartReducer,
  projects: projectsReducer,
  project: projectReducer,
  pages: pagesReducer,
  page: pageReducer,
  pageTemplates: pageTemplatesReducer,
  viewTemplate: viewTemplateReducer,
  vigs: vigsReducer,
  vig: vigReducer,
  originalContent: originalContentReducer,
  originalContents: originalContentsReducer
})


const rootReducer = (state, action) => {
  if (
    action.type === REGISTER_USER 
    || action.type === SIGN_IN_USER
    || action.type === SIGN_OUT_USER
  ) {
    state = undefined
    localStorage.removeItem('token');
  }

  return appReducer(state, action)
}
// REGISTER_USER

// let fakeEpic =()=>{};
// const epicMiddleware = createEpicMiddleware(fakeEpic);

export const store = createStore(
  // connectRouter(history)(rootReducer),
  rootReducer,
  composeWithDevTools(
    // applyMiddleware(epicMiddleware)   
    applyMiddleware(
      ...[reduxThunk] as Middleware[]
      // routerMiddleware(history)
    )  
  )
);

ReactDOM.render(
  <Provider store={store}>
      <AppRouter />
  </Provider>,
  document.getElementById('root')
);

