import {
    FETCH_PAGE_TEMPLATES
} from './types'
import axios from 'axios'
import { PromptError } from 'src/shared/utils';
import { SERVER_ROOT, AUTH_HEADERS } from 'src/shared/constants'; 
const PAGE_TEMPLATE_ROOT = SERVER_ROOT + '/page-template';
const PAGE_TEMPLATES_ROOT = SERVER_ROOT + '/page-templates';
import { Route } from 'react-router-dom'

export function draftQuery (dispatch:any) {}

export const fetchAll = ()  => async dispatch => {
    
    const res:any = await axios.get(
        PAGE_TEMPLATES_ROOT,
        AUTH_HEADERS
    )
    .catch(err => {    
         throw new Error(err);
    })

    if (res.promptError) {
        dispatch({
            type: FETCH_PAGE_TEMPLATES,
            payload: {
                viewTemplate:{
                    formPrompts: PromptError
                }
            }
        })
    } else {
        dispatch({
            type: FETCH_PAGE_TEMPLATES,
            payload: {
                pageTemplates: res.data
            }
        })
    }

}

