// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__file-uploader___keRvc{border:1px dashed #000}.index__file-uploader___keRvc .index__preview___CZ7RM{min-height:150px;position:relative;padding:5px 10px}.index__file-uploader___keRvc .index__change-prompt___FRvXf{min-height:150px;position:relative}.index__file-uploader___keRvc .index__change-prompt___FRvXf p,.index__file-uploader___keRvc .index__change-prompt___FRvXf h5{text-align:center}.index__file-uploader___keRvc .index__change-prompt___FRvXf .index__dropzone___vFdYS{width:100%;height:100%;position:absolute !important}`, "",{"version":3,"sources":["webpack://./src/components/atoms/InputFile/ui/index.scss"],"names":[],"mappings":"AACA,8BACI,sBAAA,CAEA,sDACI,gBAAA,CACA,iBAAA,CACA,gBAAA,CAEJ,4DACI,gBAAA,CACA,iBAAA,CACA,6HAAA,iBAAA,CACA,qFACI,UAAA,CACA,WAAA,CACA,4BAAA","sourcesContent":["\n.file-uploader {\n    border: 1px dashed #000;\n\n    .preview {\n        min-height: 150px;\n        position: relative;\n        padding: 5px 10px;\n    }\n    .change-prompt{\n        min-height: 150px;\n        position: relative;\n        p, h5 {text-align: center}\n        .dropzone {\n            width: 100%;\n            height: 100%;\n            position: absolute !important;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file-uploader": `index__file-uploader___keRvc`,
	"preview": `index__preview___CZ7RM`,
	"change-prompt": `index__change-prompt___FRvXf`,
	"dropzone": `index__dropzone___vFdYS`
};
export default ___CSS_LOADER_EXPORT___;
