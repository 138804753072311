import axios from 'axios'
import { SERVER_ROOT, AUTH_HEADERS } from 'src/shared/constants'; 
const LAYER_ROOT = SERVER_ROOT + '/layer';
const LAYERS_ROOT = SERVER_ROOT + '/layers';

export const updateElemDraft = async ({ draftID, newMediaID, newMediaObj, newElemStyles, type, queryString = ''}) => {
    console.log('draftID, newElemStyles, newMediaID in actions :', draftID, newElemStyles, newMediaID);
    const res:any = await axios.put(
        `${LAYER_ROOT}/draft-elem/${draftID}${queryString ? queryString : ''}`,
        { 
            type,
            newMediaID,
            newMediaObj,
            newElemStyles,
        },
        AUTH_HEADERS,
    )
    .catch(err => { throw new Error(err) })
    console.log('res.data :', res.data);
    if (!res.data) throw new Error();
    return res.data;
}

export const deleteElemDraft = async ({ id, queryString}) => {
    const res:any = await axios.delete(
        `${LAYER_ROOT}/draft-elem/${id}${queryString ? queryString : ''}`,
        AUTH_HEADERS,
    )
    .catch(err => { throw new Error(err) })
    
    if (!res.data) throw new Error();
    return res.data;
}

export const updateDraft = async ({id, action, queryString = ''}) => {
    const res:any = await axios.put(
        `${LAYER_ROOT}/update-draft-status${queryString ? queryString : ''}`,
        {action, id},
        AUTH_HEADERS,
    )
    .catch(err => { throw new Error(err) })
    
    if (!res.data) throw new Error();
    return res.data;
}


export const createElemDraft = async ({ targetElemID, newObjID, newMediaObj, newElemStyles, type, queryString = ''}) => {
    const res:any = await axios.post(
        `${LAYER_ROOT}/create-draft-elem/${type}${queryString ? queryString : ''}`,
        { 
            id: targetElemID, 
            newMediaID: newObjID, 
            newMediaObj,
            newElemStyles 
        },
        AUTH_HEADERS,
    )
    .catch(err => { throw new Error(err) })
    console.log('res.data :', res.data);
    if (!res.data) throw new Error();
    return res.data;
}