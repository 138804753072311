import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAllOriginalContent } from '../actions';
import * as qs from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface OriginalContentHOC_I {
  draft?:String;
  fetchAllOriginalContent?: Function
  page?: any
}

const OriginalContentHOC = (WrappedComponent:any, contentTypes) => {
  const Container:any = class extends Component<OriginalContentHOC_I & RouteComponentProps<any>, {}> {

    static displayName = `OriginalContentHOC(${WrappedComponent.displayName  || WrappedComponent.name})`;

    componentDidMount() {
      let { match, fetchAllOriginalContent, history, location, page } = this.props,
          { id: pageID } = page,
          params:any = {}, 
          queries = {};
      
      if (match && match.params) {
        params = match.params;
      }

      if (contentTypes.length) {
        queries = 'populate=' + contentTypes.reduce((acc, type)=> acc + '+' + type);
      }

      if (pageID) {
        setTimeout(()=>{
          fetchAllOriginalContent({ 
            pageID,
            ...params, 
            queryString: '?'+queries
          });
        },1000)
        
      }
      // query issues when both gif & gifs populated!
    }

    render() {
      return (
        <WrappedComponent {...this.props} />
      )
    }
  }

  // return withRouter(
          return connect(
            (state) => state, 
            { fetchAllOriginalContent }
          )(Container)
        // )
}

export default OriginalContentHOC;
