// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__view-titles___xcseV{margin-bottom:30px}.index__view-titles___xcseV.index__left___AtCTz>*{text-align:left}.index__view-titles___xcseV.index__center___tp3Pp>*{text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/molecules/ViewTitles/ui/index.scss"],"names":[],"mappings":"AAAA,4BACI,kBAAA,CAGI,kDAAA,eAAA,CAGA,oDAAA,iBAAA","sourcesContent":[".view-titles {\n    margin-bottom: 30px;\n\n    &.left {\n        > * { text-align: left}\n    }\n    &.center {\n        > * { text-align: center}\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view-titles": `index__view-titles___xcseV`,
	"left": `index__left___AtCTz`,
	"center": `index__center___tp3Pp`
};
export default ___CSS_LOADER_EXPORT___;
