import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter, RouteComponentProps } from 'react-router-dom'
import { MainSideNav_I, NavSection_I, NavSubSection_I, NavLink_I } from 'src/components/organisms/MainSideNav/interfaces'; 
import { formatTime } from 'src/shared/utils'
import * as ReactGA from 'react-ga';

interface NavHOC_I {
	navSections?: Array<NavSection_I>
	active?: boolean
	isAuthed?: boolean
	isCollapsed?:boolean
}

interface IDipatchProps {
	load?: (ref: string) => void;
}

interface State extends NavHOC_I {}

const WithNavHOC = (WrappedComponent:any) => {

	const Container:any = class extends WrappedComponent<NavHOC_I & RouteComponentProps<any>, State> {
		// hack to prevent warning: https://github.com/facebook/react/issues/12516
		componentWillReceiveProps = null;
		componentWillUpdate = null;

		static displayName = `WithNavHOC(${WrappedComponent.displayName  || WrappedComponent.name})`;
		state = { 
			navSections: [],
			active: false,
			isCollapsed: false,
			isAuthed: false
		};

		static getDerivedStateFromProps(nextProps, prevState) { 
			// moving away from navStates in redux!!!
			let currentPath = location.pathname,
				navType = nextProps.navType,
				isAuthed = nextProps.user.isAuthed,
				isVisible = (() => {
					switch (navType) {
							case 'mainTopNav':
								return true;
							case 'mainSideNav':
								return (nextProps.viewTemplate.mainSideNav.visible && isAuthed);
							case 'secondarySideNav':
								if (currentPath.indexOf('page') === -1) return false;
								return (nextProps.viewTemplate.secondarySideNav.visible && isAuthed);
					}
				})(),
				isCollapsed = (()=>{
					if (navType === 'mainSideNav' && nextProps.viewTemplate.secondarySideNav.visible && currentPath.indexOf('page') !== -1) {
						return true;
					}
					return false;
				})();
			if (isAuthed) {
				if (nextProps.user.id && nextProps.user.username) {
					ReactGA.set({ userId: nextProps.user.id, username: nextProps.user.username });
				}
			}
			if (!isVisible) return {...prevState,active: false}

			let navSections = (()=> {
				let	newNavSections = getNavSections({
					nav: navType, 
					state: nextProps,
					currentPath
				});
				if (JSON.stringify(prevState.navSections) !== JSON.stringify(newNavSections)) return { navSections: newNavSections };
				return {};
			})()

			return {
				...prevState,
				...navSections,
				active: true,
				isCollapsed,
				isAuthed
			} 
		}
		
		render() {
			const { navSections: sections, active, isCollapsed, isAuthed } = this.state,
				props = { sections, active, isCollapsed, isAuthed};
				
			return <WrappedComponent {...props} />;
		}
	}

	return withRouter(
		connect<{}, IDipatchProps, NavHOC_I & RouteComponentProps<any>>(
			mapStateToProps, 
			null
		)(Container)
	);
}

function mapStateToProps (state) {
	return state;
}


function getNavSections ({nav, state, currentPath}) {
	let mainSections,
			storeObjs = [],
			stateKeys = Object.keys(state),
			{ page, vigs, vig } = state,
			{ layers, data: vigData } = vig ;

	switch (nav) {
		case 'mainSideNav':
			storeObjs = [
				'projects',
				// 'pageTemplates'
			];
			mainSections = storeObjs.reduce((acc, objKey) => {
				if (stateKeys.includes(objKey)) {
					let newSection = parseMainSideNavSection({
						section: objKey,
						stateObj: state[objKey], 
						currentPath
					});
					return [...acc, newSection]
				}
				return acc;
			},[])
			break;
		case 'secondarySideNav':
			storeObjs = ['page', 'vig'];

			let pageVigs = (() => {
					if (!vigs.length) return [];
					return vigs.filter(({project_page}) => page.id === project_page)
			})()

			mainSections = [
				{ 
					label: 'Page Info',
					target: '/project/'+page.project+'/page/'+page.id
				},
				// {label: 'Original Content'},
				{
					label: 'VIGs',
					active: true,
					subSections: ((vigs) => {
						if (!vigs || !vigs.length) return [];
						let splitPath = currentPath.split('/'),
								vigID = (()=> {
									let vigPathIndex = splitPath.indexOf('vig');
									if (vigPathIndex !== -1 && splitPath[vigPathIndex+1] ) {
										return splitPath[vigPathIndex+1];
									} 
									return null
								})()

						return vigs
							.sort((a,b)=> {
								if (a.updated_at > b.updated_at) return -1;
								if (a.updated_at < b.updated_at) return 1;
								return 0;
							})
							.map((vig, i)=>{
							let { vig_layers, id, path_thumbnail, status, name, updated_at } = vig;
							let active = (vigID === id);
							return {
								label: (()=>{
									if (name) return name;
									if (path_thumbnail) return null;
									return 'VIG :'+i;
								})(),
								target: `/project/${page.project}/page/${page.id}/vig/${id}`,
								thumbnail: path_thumbnail,
								hoverContent: `
									<div class="grid-x align-center align-middle" style="height: 100%; padding:5px">
										
										<h6 class="cell text-center white-text">
											${formatTime({
												format: active ? 'timeAndDate' : 'date', 
												timeDate:updated_at
											})}
										</h6>
									</div>
								`, //${active ? '<i class="material-icons white-text" style="font-size:1rem">build</i>' : ''}
								active, 
								status,
								links: (()=> {
									if (!active || !vig_layers || !vig_layers.length || !layers || !layers.length) return [];
									
									return vig_layers.map((layerID, layerIndex) => {
										let layer = layers.find(({id}) => id === layerID);
										if (!layer) return null;
										let label = (()=>{
													if (layer.name) return layer.name;
													if (layer.type) return layer.type;
													return 'Layer '+layerIndex+1
												})(),
												active = (vigData.layerViewing && vigData.layerViewing === layerID ? true : false);

										return { active, label , target: `/project/${page.project}/page/${page.id}/vig/${id}/layer/${layerID}`, }
									})
								})()
							}
						})
					})(pageVigs)
				}
			]
			break;
		case 'mainTopNav':
			storeObjs = ['notifications','account', 'user'];
			break;
	}
	return mainSections;
}

function parseMainSideNavSection({section, stateObj, currentPath}) {
	let subSections = [],
		label = section,
		sectionActive = false;

	switch (section) {
		case 'projects' :
			sectionActive  = currentPath.indexOf('project') !== -1 ? true : false;
			if (stateObj.length) {
				subSections = stateObj.map((obj,i) => {
					let subSectionActive = sectionActive && currentPath.indexOf(obj.id) !== -1 ? true : false,
						isConfigView = subSectionActive && currentPath.indexOf('config') !== -1 ? true : false;
					return {
						label: obj.name || obj.target_url.replace('http://','').replace('https://',''),
						target: `/project/${obj.id}`,
						active:  subSectionActive,
						links: [
							{label:'pages', target: `/project/${obj.id}`, active: !isConfigView},
							// {label:'config', target: `/project/${obj.id}/config`, active: isConfigView }
						]
					}
				})
			}
			label = subSections.length && subSections.length > 1 ? 'Projects' : 'Project';
			break;
		case 'pageTemplates':
			label = 'Templates';
			sectionActive  = currentPath.indexOf('page-template') !== -1 ? true : false;
			if (stateObj.length) {
				subSections = stateObj.map((obj,i) => {
					let subSectionActive = sectionActive && currentPath.indexOf(obj.id) !== -1 ? true : false,
						isConfigView = subSectionActive && currentPath.indexOf('config') !== -1 ? true : false;
					return {
						label: obj.name || obj.target,
						target: `/page-template/${obj.id}`,
						active:  subSectionActive,
						links: []
					}
				})
			}
			break;
	}

	return {
		subSections,
		label,
		active: sectionActive,
		target: subSections.length ? subSections[0].target : ''
	}
}


export default WithNavHOC;