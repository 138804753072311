// import { store } from 'src/index';
import {
    UPDATE_QUICK_START_VIEW,
    VALIDATED_NEW_PROJECT,
    FETCH_PROJECT,
    FETCH_PROJECTS,
    START_FETCH_PROJECT,
    ADD_NEW_PAGE
} from './types'

import * as projectQueries from '../queries'
import { fetchAllPages } from '../../page/actions'
import axios from 'axios'
import * as validUrl from 'valid-url';
import { PromptError } from 'src/shared/utils';
import { SERVER_ROOT, AUTH_HEADERS } from 'src/shared/constants'; 
const PROJECT_ROOT = SERVER_ROOT + '/project';
const PROJECTS_ROOT = SERVER_ROOT + '/projects';
import { Route } from 'react-router-dom'

export const validateNewProject = (targetURL) => async dispatch => {    
    let validatedProject;
    
    dispatch({
        type: VALIDATED_NEW_PROJECT, 
        payload: {
            viewTemplate: { 
                formPrompts: {
                    status: 'pending',
                    primary: 'One minute',
                    secondary: "I'm thinking..."
                }
            }
        }
    })

    try {
        // this isn't working...at all
        await verifyURLFormat(targetURL);
    } catch (err) {
        console.log('err :', err);
        if (err) {
            return dispatch({
                type: VALIDATED_NEW_PROJECT, 
                payload: {
                    viewTemplate: { 
                        formPrompts: { 
                            status: 'error',
                            primary:'Sorry thats not a valid URL',
                            secondary: 'please try again...',
                            info: 'more text'
                        },
                        currentViewState: 'validating'
                    }
                }
            })
        }
    }   
    
    try {
        validatedProject = await checkExistingProjects(targetURL)
    } catch (err) {
        console.log('err :', err);
        if (err) return dispatch({type: VALIDATED_NEW_PROJECT, payload: err});
    };

    return dispatch({
        type: VALIDATED_NEW_PROJECT, 
        payload: { 
            viewTemplate: { ...validatedProject } 
        }
    })
};

function verifyURLFormat (url) {
    return new Promise((resolve, reject) => {
        if (validUrl.isWebUri('http://'+ url)) {
            resolve(url);
        } else {
            reject('Not a URI');
        }
    });
}

const checkExistingProjects = (targetURL) => { 
    return new Promise(resolve => {
        return axios.post(
            `${PROJECT_ROOT}/validate`,
            {
                data: {
                    targetURL
                }
            }
            )
            .catch(err=> {
                throw new Error(err);
            })
            .then((res:any) => {
                if (res.promptError) {
                    resolve ({
                        formPrompts: res.promptError,
                        currentViewState: 'validating',
                    });
                }
                if (res.data.promptError) {
                    resolve ({
                        formPrompts: res.data.promptError,
                        currentViewState: 'validating',
                    });
                }
                if (res.data.targetURL) {
                    resolve({
                        currentViewState: 'valid',
                        formPrompts: {
                            status:'success',
                            primary: 'Looks Good!',
                            secondary: 'Lets get building...'
                        },
                        viewTitles: {
                            primary: res.data.targetURL
                        },
                        project: {
                            targetURL: res.data.targetURL
                        }
                    });
                }
            });
    });
}

export const updateQuickStart = (value) => async dispatch => {
    const projectTitles = {
        primary: value
    };
    dispatch({
        type: UPDATE_QUICK_START_VIEW, 
        payload: {
            viewTemplate: {projectTitles}
        }
    });
}

export const selectProject = (value) => async dispatch => {
    dispatch({
        type: VALIDATED_NEW_PROJECT, 
        payload: {
            viewTemplate: {
                currentViewState: 'registering'
            }
        }
    });
}

export const setProjectDefaults = ({project, pageTemplate, history }) => async dispatch => {
    const res:any = await axios.put(
        `${PROJECT_ROOT}`,
        {
            data: {
                id: project, 
                page_template: pageTemplate
            }
        }
    )

    if (res.error) {
        dispatch({
            type: VALIDATED_NEW_PROJECT, 
            payload: {
                viewTemplate: {
                    formPrompts: {
                        primary: "Oop, something went wrong updating your project!",
                        secondary:'',
                        status:'error'
                    }
                }
            }
        });
    }
    // or should I push to hom page of project?
    history.push('/project/'+project)
}

export const fetchOne = ({ id, asPromise = false, populate = ['pages'], pageViewing = null })  => async dispatch =>  {
    // let params = '?' 
    //             + populate.length ? 'populate='+ populate.join('+') : '';

    let populatedObjs = {},
        relations = {
            'pages': async () => {
                fetchAllPages({
                    queryString: `?filters={"project":"${id}"}&populate=vig`,
                    noDispatch: true
                })
            }
        };

    if (populate.length) {
        for (let objKey of populate) {
            if (relations[objKey]) {
                await relations[objKey]();
            }
        }
        /* could handle synchronously and grab results V */
        // let populatedList = await Promise.all(
        //     populate.map(async objKey => {
        //         if (relations[objKey]) {
        //             return {
        //                 [objKey]: await relations[objKey]
        //             }
        //         }
        //         return 
        //     })
        // );
        
        // populatedObjs = populatedList.reduce((acc,result) => {
        //     let key = Object.keys(result)[0],
        //         val = Object.values(result)[0];
        //     return { ...acc, [key]: val }
        // },{})
    }

    const res:any = await projectQueries.fetchOne({ id/*queryString: params */ })

    let newState = {
        project: {
            ...res.project,
            pageViewing
        },
        ...populatedObjs
    };

    if (res.error) {
        dispatch({
            type: FETCH_PROJECT,
            payload: {
                viewTemplate:{
                    formPrompts: { primary: res.error }
                },
                project: { error: 'you dont have permission to be here' }

            }
        })
    } else {
        dispatch({
            type: FETCH_PROJECT,
            payload: newState
        })
    }
}
// export const fetchOnePromise = async ({ id})  => {
//     console.log('fetching :', id);
//     const res:any = await axios.get(
//         `${PROJECT_ROOT}/${id}`,
//         AUTH_HEADERS
//     )
//     .catch(err => { throw new Error(err) })

//     if (res.error) {
//         store.dispatch({
//             type: FETCH_PROJECT,
//             payload: {
//                 viewTemplate:{
//                     formPrompts: { primary: res.error }
//                 }
//             }
//         })
//         return res.error;
//     } else {
//         store.dispatch({
//             type: FETCH_PROJECT,
//             payload: {
//                 project: res.data
//             }
//         })
//         return res.data;
//     }
// }


export const fetchAll = ()  => async dispatch => {
    
    const res:any = await axios.get(
        PROJECTS_ROOT,
        AUTH_HEADERS
    )
    .catch(err => {    
         throw new Error(err);
    })
    if (res.promptError) {
        dispatch({
            type: FETCH_PROJECTS,
            payload: {
                viewTemplate:{
                    formPrompts: PromptError
                }
            }
        })
    } else {
        dispatch({
            type: FETCH_PROJECTS,
            payload: {
                projects: res.data
            }
        })
    }

}


export const addNewPage = ({projectId, url_project_root,from})  => async dispatch => {
    console.log('action {projectId, pageData} :', {projectId, url_project_root,from});

    const res:any = await axios.post(
        SERVER_ROOT +'/page/add-new-page',
        { projectId, url_project_root, from },
        AUTH_HEADERS
    )
    .catch(err => {    
        console.log('err', err)
        throw new Error(err);
    })
    if (res.promptError) {
        dispatch({
            type:ADD_NEW_PAGE,
            payload: {
                viewTemplate:{
                    formPrompts: PromptError
                }
            }
        })
    } else {
        dispatch({
            type:ADD_NEW_PAGE,
            payload: {
                newPage: res.data
            }
        })
    }

}