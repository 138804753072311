import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { AuthForm_I } from '../interfaces'
import * as configs from './config';
import { BrowserRouter as  Router, Redirect, RouteComponentProps, Route, withRouter } from 'react-router-dom';

import InputText from 'src/components/atoms/InputText';
import { InputTextProps_I } from 'src/components/atoms/InputText/interfaces'
import Button from 'src/components/atoms/Button';
import { Button_I} from 'src/components/atoms/Button/interfaces';
import FormPrompts from 'src/components/molecules/FormPrompts';
import { FormPromptsProps_I } from 'src/components/molecules/FormPrompts/interfaces';
import viewStates from 'src/shared/constants/viewStates';


interface FormFieldStatus_I {
	type?:string
	name?:string
	label?:string
	value?: string | number
	status?: string
	validation?: any
	fieldErrors?: Array<object>
}
interface FormStatus_I {
	status?:'pristine' | 'error' | 'success'
	formErrors?:  Array<object>
}
interface AuthFormStatus_I {
	form?: FormStatus_I
	fields?:FormFieldList_I
}

interface State extends AuthForm_I {
	status?: AuthFormStatus_I;
	formPrompts?: FormPromptsProps_I;
}
interface FormFieldList_I {
	[key:string]:FormFieldStatus_I
}

class AuthForm extends Component<RouteComponentProps<any> & AuthForm_I, State>  {
	
	//inherit defaults from children defaults
	static defaultProps:AuthForm_I = { 
		formType: 'registration',
		parsedRes: true,
		fieldStyles: {
			layoutClasses: 'cell medium-6'
		},
		buttonStyles:{
			color: 'primary',
			shape:'round',
			width:'cell auto',
			status:'disabled',
		},
		formValidation: {
			type: 'matchFields',
			assertion: ['password', 'confirmPassword']
		},
		formPrompts: {
			status:'hidden'
		}
	};
	authButtons: {[key:string]:Button_I} = configs.authButtons;
	authFields: {[key:string]:InputTextProps_I} = configs.authFields;
	forms = configs.forms;
	validationProcedures = configs.defaultFormValidations;

	state:State = {
		status: {
			form: {
				status: 'pristine'
			},
			fields:{}
		},
		formPrompts: {
			status: 'hidden',
			primary: ''
		} 
	}
	componentWillReceiveProps(nextProps) {
		const {formPrompts} = nextProps;
		if (formPrompts) {
			this.setState({formPrompts})
		}
	}

	componentDidMount () {
		let defaultFormFields = {};
		this.forms[this.props.formType].fields.forEach((field, i) => {
			let fieldName = i+'_'+this.props.formType+'_field',
			    isRequired = (()=> {
					if (!field.validation || !field.validation.fieldPolicies || !field.validation.fieldPolicies.length) return false;
					return field.validation.fieldPolicies.reduce((acc,policy) => {
						if (policy.type === 'required') return true;
						return acc;
					},false)
				})()
			defaultFormFields[fieldName] = {
				value:'',
				type: field.type,
				label: field.label,
				status: isRequired ? 'error' : ''
			}
		})
		this.setState((prevState) => {
			return {
				status: {
					...prevState.status,
					fields: defaultFormFields
				}
			}
		})
	}
	// initialize form with 'status' of all fields
	handleInputStatusChange = async ({type, name, value, status, label, fieldErrors, validation }) => {
			let newFields = Object.assign({},this.state.status.fields),
						newFormStatus,
						newFormPromptMessages;

			// add input state to fields object
			newFields[name] = {type, name, value, status, label, fieldErrors, validation};
			// check fields array for errors
			let fieldsHaveAnError = await this.checkFieldsForError(newFields);
			// check for from-level errors
			let newFormLevelErrors:any = await this.validationProcedures[this.props.formValidation.type](
													newFields,
													this.props.formValidation.assertion
			 								);
			
			let requiredInGroup = (()=>{
				if (this.props.formType === 'login' && !requiredOfGroup(newFields, ['username','email'], 1)) {
					newFormPromptMessages = {
						status:'error',
						...newFormLevelErrors.errorMessages
					}
					return false
				}
				return true;
			})() 	

			if ( !requiredInGroup || fieldsHaveAnError || newFormLevelErrors.status === 'error' || this.state.status.form.status === 'pristine') {
				newFormStatus = 'error';
			} else {
				// account for pristine?
				newFormStatus = 'success';
			}

			function requiredOfGroup(fields, types, quantity) {
					let passed = Object.keys(fields).reduce((acc,fieldKey:any)=> {
						let field = fields[fieldKey];
						if (!field.type || !types.includes(field.type) || !field.status || field.status !== 'success') return acc;
						return [...acc, field];
					},[])
					console.log('passed :', passed);
					if (passed.length && passed.length >= quantity) return true;
					return false;
			}

			if (newFormLevelErrors.status === 'error') {
				// theres form errors, so update prompt
				newFormPromptMessages = {
					status:'error',
					...newFormLevelErrors.errorMessages
				}
			} else {
				// there's field errors, but no form errors. Keep form prompt hidden
				newFormPromptMessages = { status:'hidden' };
			}

		this.setState((prevState) => {
			// return new fields array and new form status 
			return {
				...prevState,
				status: {
					form: {
						status: newFormStatus,
						formErrors: newFormLevelErrors
					},
					fields: newFields,
				},
				formPrompts: {
					...newFormPromptMessages
				}
			}
		});
	}

	checkForRequiredFields (fields) {

	} 
	checkFieldsForError(fields) {
		return new Promise(resolve => {
			resolve(Object.keys(fields).reduce((acc, fieldKey) =>{
				if (acc === true) return true;
				if (fields[fieldKey].status === 'error') return true;
				return false;
			}, false))
		})
	}



	handleButtonClick = async () => {
		if (this.state.status.form.status === 'success') {
			let data = {},
				inputs:FormFieldList_I = this.state.status.fields;

			if (this.props.parsedRes) {
				for (let key  in inputs) {
					let obj = inputs[key];
					data[obj.type] = obj.value; 
				}
			} else {
				data = inputs;
			}
			this.props.onFormSubmit(data);
		}
	}
	renderFormFields = (formType = 'registration') => {	
		if (formType === 'registration') {
			return this.forms[formType].fields.map((field, i) => {
				let props = Object.assign(
					this.props.fieldStyles,
					field,
					{
						layoutClasses: 'cell small-12',
						name: i+'_'+formType+'_field',
						validation: {
							...field.validation,
							setStatus: this.handleInputStatusChange,
							execution:['onChange', 'onBlur', 'onFocus']
						}
					}
				);
				return <InputText  {...props} key={i+'_'+formType+'_field'} />
			})
		}
		if (formType === 'login') {
			let fields:any = this.forms[formType].fields.reduce((acc,field, i) => {
				let props = Object.assign(
					this.props.fieldStyles,
					field,
					{
						layoutClasses: 'cell',
						name: i+'_'+formType+'_field',
						validation: {
							...field.validation,
							setStatus: this.handleInputStatusChange,
							execution:['onChange', 'onBlur', 'onFocus']
						}
					}
				);
				return {
					...acc,
					[field.type]: <InputText  {...props} key={i+'_'+formType+'_field'} />
				}
			},{})
			return (
				<div className="gird-x small-8 small-offset-2">
					<div className="grid-x align-center align-middle">
						<div className="cell small-8">
							{fields.username}
						</div>
						{/* <div className="cell shrink" style={{marginLeft: '20px', marginRight:'20px'}}>
							<h4>OR</h4>
						</div> */}
						{/* <div 
							className="cell small-8" 
							// style={{marginTop: '20px', marginBottom:'20px'}}
						>
							<h4>OR</h4>
						</div> */}
						<div className="cell small-8">
							{fields.email}
						</div>
					</div>
					<div className="grid-x align-center align-middle">
						<div className="cell small-8">
							{fields.password}
						</div>
					</div>
				</div>
			);
		}
	}
	renderFormButtons = (formType = 'registration',formStatus) =>{
		return this.forms[formType].buttons.map((button,i) => {			
			let props = Object.assign(
				this.props.buttonStyles,
				{ 
					status: formStatus === 'success' ? 'visible': 'disabled',
					action: this.handleButtonClick,
					width: "cell small-12 medium-6"
				},
				button
			);
			return <Button 
						key={i+'_'+formType+'_button'}  
						{...props}
					/>
		})
	}

	render () {
		return(
			<div>
				<div className="grid-x align-spread grid-margin-x">
					{this.renderFormFields(this.props.formType)}
				</div>
				<FormPrompts 
					{...this.state.formPrompts}
				/>
				<div className="spacer-inline spacer20"></div>
				<div className="grid-x align-middle align-center grid-margin-x">
					{this.renderFormButtons(this.props.formType, this.state.status.form.status)}
				</div>
			</div>
		);	
	}
}

export default withRouter(AuthForm);



	// buildFormPrompt(newFormLevelErrors) {
	// 	return new Promise(resolve => {
	// 		resolve(
	// 			newFormLevelErrors.reduce((acc, error) => {
	// 				// concatenate individual form-level error messages
	// 				return {
	// 					status: 'error',
	// 					primary: error.primary ? acc.primary + '<br>' + error.primary : acc.primary,
	// 					secondary: error.secondary ? acc.secondary + '<br>' + error.secondary : acc.secondary,
	// 					info: error.info ? acc.info + '<br>' + error.info : acc.info,
	// 				}
	// 			},{ primary:'', secondary:'', info:'' })
	// 		);
	// 	})
	// }
	// checkFormLevelErrors(currentFieldType, validations, fields) {
	// 	// return array og form-level errors
	// 	return new Promise(resolve => {
	// 		if (!validations.length || !Object.keys(fields).length) resolve([]);
			
	// 		let validationErrors = validations.reduce((acc, validation) => {
	// 			let currentValidation = this.validationProcedures[validation.type];
				
	// 			console.log('form valin',validation, fields);
				
	// 			if (validation.assertion.includes(currentFieldType)) {
					
	// 				currentValidation(fields, validation.assertion).then(result => {
	// 					if (result.status === 'error') {
	// 						return [...acc, ...result.errorMessages];
	// 					}
	// 					return acc; 
	// 				})
	// 			} else {
	// 				return acc;
	// 			}
	// 		},[])
	// 		resolve(validationErrors);
	// 	})
	// }