import axios from 'axios'
import { SERVER_ROOT, AUTH_HEADERS } from 'src/shared/constants'; 
const PROJECT_ROOT = SERVER_ROOT + '/project';
const PROJECTS_ROOT = SERVER_ROOT + '/projects';

export const fetchOne = async ({id, queryString = null}) => {
    const res:any = await axios.get(
        `${PROJECT_ROOT}/${id}${queryString ? queryString : ''}`,
        AUTH_HEADERS
    )
    .catch(err => { throw new Error(err) })

    if (!res.data) throw new Error();
    return res.data;
}