
export function deNormalizeVIG({
    vig,
    layers,
    contentGroups,
    elems,
    draftMode = false
}) {
    return {
        ...vig,
        vig_layers: layers.map(layer => {
            let contentGroupsFiltered = contentGroups.filter(({vig_layer}) => vig_layer === layer.id)
            let vig_content_groups = contentGroupsFiltered.map(storedGroup => {
                let vig_content_group_elems = storedGroup.vig_content_group_elems.map(elemID => {
                    let elem = elems.find(({id}) => id === elemID);
                    if (!elem.elem_drafts || !elem.elem_drafts.length || !draftMode) return elem;

                    let draftElem = elems.find(({id}) => id === elem.elem_drafts[0]);
                    return draftElem;
                })
                return { ...storedGroup, vig_content_group_elems }
            })
            return { ...layer, vig_content_groups } 
        })
    } 
}

export function addElemMedia({ elems, objList }) {
    return elems.map(storedElem => {
        let multimediaObj = ((obj) => {
            if (!obj) return null; 
            return objList.find(({id}) => {
                return (id === obj);
            });
        })( storedElem.multimedia_obj )
    
        return {
            ...storedElem,
            multimedia_obj: multimediaObj
        }
    })
}
