import * as React from 'react';
import { Component } from 'react';
import styles from './index.scss';
import * as configs from './config';
import {  ValidationError, ValidationOptionsField, InputTextProps_I, ValidationType } from '../interfaces'
import { networkInterfaces } from 'os';


interface State extends InputTextProps_I {
	validationErrors?: Array<ValidationError>;	
}

class InputText extends Component<InputTextProps_I, State>  {
	
	static defaultProps = {
		type:'',
		name:'',
		placeholder: '',
		shape: 'square',
		size: 'medium',
		// inputStyle: 'light',
		label: {
			text:''
		},
		prompt: {	
			text:'',
			position: 'bottom',
		},
		layoutClasses: '',
		validation: {
			fieldPolicies:[
				// {type: 'custom', assertion: null},
				// {type:'required'},
				// {type: 'minimum', assertion: 8},
				// {type: 'maximum', assertion: 25},
				// {type: 'valueType', assertion:'number'},
			],
			execution: [
				'onChange',
			], 
			setStatus: fieldStatus => fieldStatus,
			customProcedure: (value, assertion) => {return false;},
				/*  if has error => return { error: true, message: ''};
					else no error => return false;
					(note: may want to account for empty field or you may return a error for empty value)
				*/
			onFormValidation: (value, assertion, fields) => {return false;}
		},
		hasErrors: false
	}
	defaultFieldValidations = configs.defaultFieldValidations;
	defaultFormValidations = configs.defaultFormValidations;

	state:State = { 
		value: '',
		status: 'pristine',
		inputState: 'filled',
		prompt: {	
			status: 'hidden',
			text:''
		},
		validationErrors: []
	}

	componentDidMount () {
		this.setState((prevState) => {
			return Object.assign(prevState, this.props)
		// }
		// , () => {
		// 	if (this.props.validation.fieldPolicies && this.props.validation.fieldPolicies.length) {
		// 		let initialStatus = this.props.validation.fieldPolicies.reduce((acc, policy) => {
		// 			if (policy.type === 'required') return 'error';
		// 			return acc;
		// 		},'pristine');
		// 		// this.handleInputEvent(this.state.value, this.props.type)
		// 		this.handleSetStatus({
		// 			status: initialStatus
		// 		});
		// 	}
		});
	}
	validationSuccess = () => {
		this.setState({
			status: 'success',
			prompt: {
				status: 'hidden', 
				text:'',
			}	
		})
	}
	validationError = (errMsg:string) => {
		this.setState({
			status: 'error',
			prompt: {
				status: 'error', 
				text: errMsg,
			}	
		});
	} 
	
	/* this is laggin by 1 event!!!! */
	handleSetStatus = (inputStatus) => {
		let withDefaults = {
			type: this.props.type,
			name: this.props.name,
			label: this.props.label,
			value: this.state.value,
			status: this.state.status, 
			errors: this.state.validationErrors,
			...inputStatus
		}
		// console.log('final', inputStatus, withDefaults);
		this.setState(prevState=>{
			return {
				...prevState,
				...inputStatus
			}
		}, ()=> {
			this.props.validation.setStatus(withDefaults);
		})
		
	}

	getFieldErrors = (newVal, fieldPolicies, fieldValidations) => {
		return new Promise((resolve, reject) => {
			if (fieldPolicies && fieldPolicies.length) {
				let newFieldErrors:Array<ValidationError> = 
					fieldPolicies
					.reduce((acc:Array<ValidationError>, policy:ValidationType) => {
						let validationResult = fieldValidations[policy.type](newVal, policy.assertion);
						if (validationResult && validationResult.error) {
							return [
								...acc, 
								{ 
									type: policy.type, 
									message: validationResult.message 
								}
							];
						} else { return [...acc] }
					},[])
				resolve(newFieldErrors);
			} else {
				resolve([]);
			}
		})
	}
	checkForErrors = (
		newVal:any, 
		fieldPolicies = this.props.validation.fieldPolicies, 
		formPolicies = this.props.validation.formPolicies, 
		fieldValidations = this.defaultFieldValidations,
		formValidations = this.defaultFormValidations,
		formValidationFunc = this.props.validation.onFormValidation) => {
			
		return new Promise((resolve, reject) => {
			return Promise.all([
				// this.getFormErrors(newVal, formPolicies, formValidations, formValidationFunc),
				this.getFieldErrors(newVal, fieldPolicies, fieldValidations)
			])
			.then(allErrors=>{
				//[ts] No idea why [...allErrors[0],...allErrors[1]] wouldn't work?
				let newErrs = allErrors.reduce((acc:Array<any>, arr:Array<any>)=> {
					return [...acc, ...arr];
				},[]);
				resolve(newErrs);
			})
			.catch(err => {
				console.log(err);
				resolve([]);
			});
		})
	}

	buildStateObject = (
		newErrors, //:Array<ValidationError>,
		prevErrors,
		value		
	) => {
		return new Promise((resolve, reject) => {
			let promptMessage = 
					newErrors.reduce((acc, error) => { 
						return `${acc} <p class="cell shrink">${error.message}</p>`;
					},''),
				promptStatus  = newErrors.length ? 'error' : 'hidden';

			let currentStatus = () => {
				if (newErrors.length) {
					return 'error';
				} else if (value !== '' && value !== undefined) {
					return 'success';
				} else {
					return 'pristine';
				}
			}
			resolve({
				validationErrors: newErrors, 
				status: currentStatus(),
				prompt: {
					status: promptStatus,
					text: promptMessage
				}
			});
		})
	}

	async handleInputEvent (newVal, eventType) {
		if (this.props.validation.execution.includes(eventType)) {
			let newErrors = await this.checkForErrors(newVal).catch(err=>console.log(err));
			let newState = await this.buildStateObject(
											newErrors, 
											this.state.validationErrors,
											newVal
										).catch(err=>console.log(err));
			this.handleSetStatus(newState);	
		}
	}

	handleOnInput = (e:any) => {
		let newVal = e.target.value;

		this.setState(prevState => {
			return {
				...prevState,
				value: newVal
			}
		}, ()=> {
			this.handleInputEvent(newVal, 'onChange');
		})
	}
	handleOnFocus = (e:any) => {
		let newVal = e.target.value;
		this.handleInputEvent(newVal, 'onFocus');
	}
	handleOnBlur = (e:any) => {
		let newVal = e.target.value;
		this.handleInputEvent(newVal, 'onBlur');
	}

	render () {
		return(
			<div 
				className={`input-text ${this.props.layoutClasses} ${this.props.classNames}`}
				// Global
				data-state={this.state.inputState}
				data-size={this.props.size}
				//Input
				data-input-style={this.props.inputStyle}
				data-shape={this.props.shape}
				// Label 
				data-label-style={this.props.label.style}
				data-label-position={this.props.label.position}
				// Prompt
				data-prompt-status={this.state.prompt.status}
				data-prompt-position={this.props.prompt.position}
				data-has-errors={this.props.hasErrors}
			>
				<label>{this.props.label.text}</label> 
				<input 
					type={this.props.type === 'password' ? 'password' : 'text'} 
					placeholder={this.props.placeholder} 
					value={this.state.value} 
					onChange={this.handleOnInput} 
					onInput={this.handleOnInput}
					onBlur={this.handleOnBlur}
					onFocus={this.handleOnFocus}
				/>
				<p className="input-text-prompt grid-y align-middle align-center" dangerouslySetInnerHTML={{__html:this.state.prompt.text}}></p>
			</div>
		);	
	}
}

export default InputText;



