// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__quick-start-container___kCaOI{height:60vh;min-height:400px}`, "",{"version":3,"sources":["webpack://./src/components/views/QuickStartView/ui/index.scss"],"names":[],"mappings":"AAAA,sCACI,WAAA,CACA,gBAAA","sourcesContent":[".quick-start-container {\n    height: 60vh;\n    min-height: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quick-start-container": `index__quick-start-container___kCaOI`
};
export default ___CSS_LOADER_EXPORT___;
