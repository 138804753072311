import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAll } from '../actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { User_I } from 'src/data/user/interfaces';

interface ProjectsHOC_I {
  projects?: Array<any>
  fetchAll?: Function
  user?: User_I
}

interface State extends ProjectsHOC_I { }

const WithProjectsHOC = (WrappedComponent:any) => {
  const Container:any = class extends Component<ProjectsHOC_I & RouteComponentProps<any>, State> {

    static displayName = `WithProjectsHOC(${WrappedComponent.displayName  || WrappedComponent.name})`;

    componentDidMount() {
      this.props.fetchAll();  
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
        />
      )
    }
  }

  return connect(
    mapStateToProps, 
    { fetchAll }
  )(Container)
}

function mapStateToProps(state) {
  return state;
}
export default WithProjectsHOC;
