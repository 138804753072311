import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './index.scss';
import withVig from 'src/data/vig/containers' 
import Button from 'src/components/atoms/Button'
import InputText from '../../../atoms/InputText'
import { InputTextProps_I } from '../../../atoms/InputText/interfaces'
import ImageContentEditor from 'src/components/organisms/ImageContentEditor'
import TextContentEditor from 'src/components/organisms/TextContentEditor'
import DomNodeContentEditor from 'src/components/organisms/DomNodeContentEditor'
import ShapeContentEditor from 'src/components/organisms/ShapeContentEditor'

interface Props {
	vig?: any
	contentGroups?: any 
	elemID?:string
}

interface State extends Props {
	elemEditing: any
	multimediaObj: Object
	contentEditing: Object
	originalContents?: any
}

interface IDipatchProps { load?: (ref: string) => void; }

class ElemEditor extends Component<Props & RouteComponentProps<any>, State>  {	
	state = {
		elemEditing: {},
		multimediaObj: {},
		contentEditing: {},
		originalContents: []
	};

	static defaultProps = {
		vig: {
			data: {
				created_at: '',
				updated_at: ''
			}, 
			layers: [], 
			contentGroups: [], 
			elems: [], 
			multimediaObjs: []
		}
	}

	static getDerivedStateFromProps(newProps, prevState) {      
		const   { elemID, vig } = newProps,
				{ 
					data: vigData, 
					contentGroups, 
					elems, 
					multimediaObjs,
					history,
					originalContents
				} = vig,
				{ layerEditing } = vigData;;

		if (vigData && vigData.error) history.push('/');

		const elemEditing = elems.find(({id}) => elemID === id)

		if (!elemEditing) return prevState;
		
		const multimediaObj = multimediaObjs.find(({id}) => elemEditing.multimedia_obj === id)

		let elemChanged = updateElem({
			prevElem: prevState.elemEditing, 
			newElem: elemEditing, 
			prevMedia: multimediaObj, 
			newMedia: prevState.multimediaObj
		});

		if (elemChanged) {
			return {
				...prevState,
				elemEditing,
				multimediaObj,
				originalContents
			}
		}
		return prevState;
	
		function updateElem({prevElem, newElem, prevMedia, newMedia}) {
			// diff all elem props
			if (!prevElem || !prevElem.id) return true;
			if (prevElem.id !== newElem.id) return true;
			if (prevElem.updated_at !== newElem.updated_at) return true;
			// may need to diff css props here
			return false;
		}
	}
	
	renderEditor({ elemEditing, multimediaObj }) {
		const { elem_family, elem_type, elem_formats } = elemEditing,
			{ css } = elem_formats;
		const props = {
			multimediaObj,
			elemEditing
		}
		let multimediaType = (()=>{
			if (elem_family === 'shape') return 'shape';  
			if (!multimediaObj) return null;
			if (multimediaObj.text) return 'text';
			if (multimediaObj.s3_path) return 'image';
			if (multimediaObj.node) return 'domNode';
			return null;
		})()
		switch (multimediaType) {
			case 'image':
				return <ImageContentEditor {...props} />
			case 'text':
				return <TextContentEditor {...props} />
			case 'domNode':
				return <DomNodeContentEditor {...props} />
			case 'shape':
				return <ShapeContentEditor {...props} />
			default:
				return <h5>Your element is missing a 'type'!</h5>
		}
	}


	render () {
		const { vig, elemID } = this.props,
			  { data } = vig,
			  { LayerEditing } = data,
			  { elemEditing: currentElem, multimediaObj, contentEditing, originalContents } = this.state,
			  editorProps = {...currentElem, ...multimediaObj};
		const elemEditing: any = currentElem;
	    const props = {
			multimediaObj,
			elemEditing,
			originalContents
		}

		return(
			<div className={`grid-x grid-margin-x ${styles['project-container']}`}>
				<div className="cell small-12">
					{ elemEditing && (elemEditing.elem_family === 'shape' || multimediaObj) ? this.renderEditor(props) : <h3>Loding your element...</h3> }
				</div>
			</div>
		);
	}
}



function mapStateToProps(state:any ) {
	return state;	
}

export default withVig(
					withRouter(
						connect<{}, IDipatchProps, Props & RouteComponentProps<any>>(
							mapStateToProps,
							{}
						)(ElemEditor)
					)
				)