import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
// import { AddProjectPage_I } from '../interfaces'
import * as configs from './config';
// import { BrowserRouter as  Router, Redirect, RouteComponentProps, Route, withRouter } from 'react-router-dom';

import InputText from 'src/components/atoms/InputText';
import { InputTextProps_I } from 'src/components/atoms/InputText/interfaces'
import Button from 'src/components/atoms/Button';
import { Button_I} from 'src/components/atoms/Button/interfaces';
import FormPrompts from 'src/components/molecules/FormPrompts';
import { FormPromptsProps_I } from 'src/components/molecules/FormPrompts/interfaces';
import Tabs from 'src/components/molecules/Tabs/ui'
import { Tabs_I } from 'src/components/molecules/Tabs/interfaces'
import ProjectContainer from 'src/data/project/containers'
import { addNewPage } from 'src/data/project/actions'
import {Project_I} from 'src/data/project/interfaces'
import InputTextSimple from 'src/components/atoms/InputTextSimple/ui';
interface AddProjectForms {
		byAddress: ByAddress;
		byURL: ByURL;
		byMLSCode: ByMLSCode;
}
	interface ByAddress {
		street: string;
		city: string;
		state: string;
		zip: string;
	}
	interface ByURL {
		url: string;
	}
	interface ByMLSCode {
		MLSCode: string;
	}

interface AddProjectPageProps_I {
	projectId?:string;
	addNewPage: Function;
	project?:Project_I;
}

interface State {
	// projectId:string;
	visible: boolean;
	activeTab:number;
	formValues?: AddProjectForms;
	formPrompts: FormPromptsProps_I;
}

class AddProjectPage extends Component<AddProjectPageProps_I, State>  {	
	//inherit defaults from children defaults
	// static defaultProps:AddProjectPageProps_I = { 
	// 	// buttonStyles:{
	// 	// 	color: 'primary',
	// 	// 	shape:'round',
	// 	// 	width:'cell auto',
	// 	// 	status:'disabled',
	// 	// }
	// };

	// concession
	// 	managers, partners, owners
	// NFL - short season hard to attract consisten 
	// concert venues - ""
	// 
	state:State = {
		visible: false,
		activeTab: 0,
		// projectId: null,
		formValues: {
			byAddress:{
				street:'',
				city:'',
				zip:'',
				state:''
			},
			byMLSCode:{
				MLSCode:''
			},
			byURL:{
				url:''
			}
		},
		formPrompts: {
			status: 'hidden',
			primary: ''
		} 
	}

	// componentWillMount(){

	// }
	handleFieldChange ({formName, fieldName, newVal}) {
		this.setState( prevState =>{
			return {
				...prevState,
				formValues: {
					...prevState.formValues,
					[formName]:{
						...prevState.formValues[formName],
						[fieldName]: newVal
					}
				}
			}
		},()=>{
			console.log('this.state', this.state)
		})
	}

	handleSubmit ({ formName }) {
		const { project } = this.props,
			 { id, target_url  } = project,
			 formValues = this.state.formValues[formName];

		const queryData =(()=> {
			switch (formName) {
				case 'byAddress':
					return  {
						api: {
							type:'realEstate',
							address: formValues
						}
					}
				case 'byMLSId':
					return  {
						api: {
							type:'realEstate',
							MLSId: formValues
						}
					}
				case 'byURL':
					return{
						url: formValues
					}
			}
		})();
		this.props.addNewPage({
			projectId:id, 
			url_project_root: target_url.replace('http://','').replace('https://',''),
			from: queryData
		})
	}
	buildForm({fields, formName}) {
		return <form>
				{
					Object.entries(fields).map(([name, value]:any,i) => {
						return <InputTextSimple
							label={{text:name}}
							name={name}
							value={value}
							key={name+"-"+i}
							onChange={({value:newVal,name:fieldName})=> {
								this.handleFieldChange({
									formName, 
									fieldName, 
									newVal
								})
							}}
						/>
					})
				}
				<Button 
					action={()=>this.handleSubmit({formName})}
					label={{text:"Add Page"}}
				/>
				</form>;
	}

	buildTabs({formValues, activePane}) {
		return Object.entries(formValues).reduce((acc:any,[formName, fields], i)=>{
			return [
				...acc,
				{
					title: formName,
					pane: {
						// title:formName, 
						body: this.buildForm({fields, formName}), 
						active:activePane === i 
					}, 
					active: activePane === i 
				}
			]
		},[])
	}
	render () {
		const tabData:any = {
			title:"Build Project Page",
			tabs: this.buildTabs({
				formValues:this.state.formValues,
				activePane: this.state.activeTab
			})
		}
		return(
			<div>
				<Tabs
					{...tabData}
				/>
				<div className="grid-x align-spread grid-margin-x">
				</div>
				<FormPrompts 
					{...this.state.formPrompts}
				/>
				<div className="spacer-inline spacer20"></div>
				<div className="grid-x align-middle align-center grid-margin-x">

				</div>
			</div>
		);	
	}
}

function mapStateToProps(state:any ) {
	console.log('state', state)
	return state;	
}

export default ProjectContainer(
		connect<{},AddProjectPageProps_I>(
			mapStateToProps,
			{ addNewPage },
			null,
			{pure: false }
		)(AddProjectPage)
	);
