import * as React from 'react';
import { Component } from 'react';
import styles from './index.scss';
import { ViewTemplate_I } from '../interfaces'
import MainTopNav from '../../MainTopNav/ui';
import { MainSideNav } from '../../MainSideNav';
import { SecondarySideNav } from '../../SecondarySideNav';
import { connect } from 'react-redux'
import AppRouter from 'src/components/App/Router'
import {withRouter, RouteComponentProps } from 'react-router-dom'
import Button from 'src/components/atoms/Button';

interface Props {
	viewTemplate?:any
	mainSideNav?: any
	secondarySideNav?: any	
}
interface State {
	mobileVisible?: boolean
}

class ViewTemplate extends Component<Props & RouteComponentProps<any>, State>  {
	state = {
		mobileVisible: false
	}

	width () {
		const { location } = this.props,
			  { pathname } = location,
			width = 0;

		if (!pathname.includes('project')) return {width: 0};

		const mainSide = (() => {
				if (!pathname.includes('page')) return 300;
				if (pathname.includes('page')) return 150;
				return 0;			
			})(),
			secondSide = pathname.includes('page') ? 200 : 0;

		let widthFinal = { width: width+mainSide+secondSide };
		return widthFinal;
	}

	toggleMobile () {
		this.setState(prevState => {
			return {
				...prevState,
				mobileVisible: !prevState.mobileVisible
			}
		})
	}
	render () {
		const { mobileVisible } = this.state;
		const empty:any = {}
		return(			
			<div 
				className={`${styles['view-template']} cell`}
				style={this.width()}
				data-visible={mobileVisible}
				// onClick={ ()=>this.setState({mobileVisible:false}) }
			>	
				<MainTopNav {...empty}/>
				<div 
					style={{
						position:'fixed',
						top:'15px',
						left:'20px',
						zIndex:200,
						// visibility: mobileVisible ? 'hidden' : 'visible',
						// opacity: mobileVisible ? 0 : 1
					}}
					className={`${styles['nav-toggle']}`}
				>
					<Button
						label={{text:'Mobile Nav'}}
						action={this.toggleMobile.bind(this)}
					/>
				</div>
				<MainSideNav {...empty}/> 
				<SecondarySideNav {...empty} />
			</div>
		);	
	}
}


const mapStateToProps = (state) => state;

export default withRouter(
					connect<any,any, RouteComponentProps<any>>(
						mapStateToProps,
						null
					)(ViewTemplate)
				);



