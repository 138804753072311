import axios from 'axios'
import { SERVER_ROOT, AUTH_HEADERS } from 'src/shared/constants'; 
const PAGE_ROOT = SERVER_ROOT + '/page';
const PAGES_ROOT = SERVER_ROOT + '/pages';

export const fetchAll = async ({queryString = ''}) => {
    const res:any = await axios.get(
        `${PAGES_ROOT}${queryString ? queryString : ''}`,
        AUTH_HEADERS
    )
    .catch(err => { throw new Error(err) })
    
    if (!res.data && !res.data.length ) throw new Error();
    return { pages: res.data };
}

export const fetchOne = async ({id, queryString = ''}) => {
    const res:any = await axios.get(
        `${PAGE_ROOT}/${id}${queryString ? queryString : ''}`,
        AUTH_HEADERS
    )
    .catch(err => { throw new Error(err) })
    
    if (!res.data && !res.data.id ) throw new Error();
    return { page: res.data };
}

export const populateOne = async ({id}) => {
    const res:any = await axios.post(
        `${PAGE_ROOT}/populate`,
        { id },
        AUTH_HEADERS
    )
    .catch(err => { throw new Error(err) })
    
    if (!res.data && !res.data.id ) throw new Error();
    return res.data;
}