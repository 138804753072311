import { reduceContentGroups, buildFrame } from './compileVIG'
import { simpleID } from '../'
import { deNormalizeVIG , addElemMedia } from './transformVIG'

export async function buildVIGPreview({
        layers, // Array<Fragment>
        contentGroups,
        elems,
        multimediaObjs,
        vigData,
        draftMode
    }) {

    const elemsWithMedia = await addElemMedia({ elems, objList: multimediaObjs })

    const deNormalizedVIG = deNormalizeVIG({
        vig: vigData,
        layers,
        contentGroups,
        elems: elemsWithMedia,
        draftMode
    })

    let vigID = 'vig-'+simpleID();
    let vigLayers = await Promise.all(
        deNormalizedVIG.vig_layers.map(async (layer, layerIndex) => {
            let reducedContentGroupProps:any = await reduceContentGroups({
                layer, 
                layerIndex, 
                vigID,
                useBinaryImg: false
            });
            return {
                ...layer,
                ...reducedContentGroupProps
            }
        })
    )

    let frameFormats = await buildFrame({
        vigLayers
    });

    return {
        ...frameFormats,
        vigID
    }
}
