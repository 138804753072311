import * as React from 'react';
import { Component } from 'react';
import styles from './index.scss';

import Button from 'src/components/atoms/Button'
import { Button_I } from 'src/components/atoms/Button/interfaces'
import { Tabs_I, TabPane_I } from '../interfaces'
import ContentGroupEditor from 'src/components/organisms/ContentGroupEditor/ui'
import Spinner from 'src/components/atoms/Spinner/ui';

interface State extends Tabs_I {
	activeTab?: number
	key?:any
}

const TabNav: React.StatelessComponent<any>  = props => {
	const { title, active, tabIndex, onTabChange } = props,
		buttonProps:Button_I = {
			label: { text: title },
			size: 'large',
			width: "cell small-6",
			color: 'primary',
			shape:'tab',
			status: active ? 'filled' : 'visible',
			action: onTabChange,
			actionParams: { tabIndex }
		};
	return <Button {...buttonProps}/>
}

const TabPane: React.StatelessComponent<any>  = props => {
	// need to refactor to render component from variable
	const { body, active, title } = props;

	return (
		<div className="grid-x cell small-12">
			<h3>{title}</h3>
			{body}
		</div>
	)
}

class Tabs extends Component<Tabs_I, State> {
	state = {
		activeTab: 0,
		key: Math.random()
	}
	
	static defaultProps = {
		title:'',
		tabs: []
	}

	renderTabs ({ tabsData, activeTab }) {
		let width = 100 / tabsData.length;
		return (
			<div className="cell small-12 grid-x">
			{
				tabsData.map((tab, i) => {
					const { pane, ...rest} = tab,
							tabProps = {
								...rest,
								active: (activeTab === i),
								onTabChange: () => this.handleTabChange({tabIndex:i})
							};
					return (
						<div key={i} className="cell" style={{ width: width+'%', float:'left' }} >
							<TabNav { ...tabProps } />
						</div>
					)
				})
			}
			</div>
		);
	}

	renderPane ({ tabsData, activeTab }) {
		if (!tabsData || !tabsData[activeTab] || !tabsData[activeTab].pane) return <Spinner />;
		let activePane = tabsData[activeTab].pane;
		// let paneProps = {
		// 	body: activePane
		// }
		
		return (
			<div className="cell small-12 grid-x">
				{/* <TabPane {...paneProps} /> */}
				<TabPane {...activePane} />
			</div>
		)
	}

	handleTabChange({tabIndex}) {
		this.setState({activeTab: tabIndex, key: Math.random() })
	}

	render () {
		const { tabs: tabsData, title} = this.props,
			{ activeTab, key } = this.state;
		return (
			<div className="cell small-12 grid-x" key={key}>
				<h4>{title}</h4>
				{ tabsData.length ? this.renderTabs({ tabsData, activeTab }) : '' } 
				{ tabsData.length ? this.renderPane({ tabsData, activeTab }) : '' } 
			</div>
		);
	}
}

export default Tabs;
