import * as React from 'react';
import { Component } from 'react';
import styles from './index.scss';
import Button from 'src/components/atoms/Button'

interface EditorNav_I {
    media?: string
    editorState?:string
    pristine?:boolean
    multimediaObj?:any
    elem?:any
    startContentUpdate?: Function
    isSuperAdmin?:boolean
}
export const EditorNav: React.StatelessComponent<EditorNav_I> = ({ media, editorState, pristine, multimediaObj, elem, startContentUpdate, isSuperAdmin }) => {
    const buttons = (()=> {
        switch (editorState) {
            case 'contentManual':
                return [
                    <div key={0} className={`grid-x small-12 align-center align-middle`}>			
                        <Button width="cell small-6" shape="tab" size="regular" label={{text:'Content'}} status="disabled-filled" color="primary" action={() => startContentUpdate({from: 'contentFromPage', multimediaObj, elem })} />
                        <Button width="cell small-6" shape="tab" size="regular" label={{text:'Styles'}} status="visible" color="primary" action={() => startContentUpdate({from: 'styles', multimediaObj, elem })} />
                        {!pristine ? <Button key={2} label={{text:'Use '+media}} /> : '' }
                    </div>,
                    <div key={1} className={`grid-x small-12 align-center align-middle`}>			
                        <Button width="cell small-6" shape="tab" size="small" label={{text:'From Page'}} status="visible" color="primary" action={() => startContentUpdate({from: 'contentFromPage', multimediaObj, elem })} />
                        <Button width="cell small-6" shape="tab" size="small" label={{text:'Manually'}} status="disabled-filled" color="primary" />
                        {!pristine ? <Button key={2} label={{text:'Use '+media}} /> : '' }
                    </div>
                ];
            case 'contentFromPage':
                return [	
                    <div key={0} className={`grid-x small-12 align-center align-middle`}>			
                        <Button width="cell small-6" shape="tab" size="regular" label={{text:'Content'}} status="disabled-filled" color="primary" action={() => startContentUpdate({from: 'contentFromPage', multimediaObj, elem })} />
                        <Button width="cell small-6" shape="tab" size="regular" label={{text:'Styles'}} status="visible" color="primary" action={() => startContentUpdate({from: 'styles', multimediaObj, elem })} />
                        {!pristine ? <Button key={2} label={{text:'Use '+media}} /> : '' }
                    </div>,
                    <div key={1} className={`grid-x small-12 align-center align-middle`}>			
                        <Button width="cell small-6" shape="tab" size="small" label={{text:'From Page'}} status="disabled-filled" color="primary" />
                        <Button width="cell small-6" shape="tab" size="small" label={{text:'Manually'}} status="visible" color="primary" action={() => startContentUpdate({from: 'contentManual', multimediaObj, elem })} />
                        {!pristine ? <Button key={2} label={{text:'Use '+media}} /> : '' }
                    </div>
                ];
            case 'stylesAdmin':
                let styleAdminBtns = [
                    <div key={0} className={`grid-x small-12 align-center align-middle`}>			
                        <Button width="cell small-6" shape="tab" size="regular" label={{text:'Content'}} status="visible" color="primary" action={() => startContentUpdate({from: 'contentFromPage', multimediaObj, elem })} />
                        <Button width="cell small-6" shape="tab" size="regular" label={{text:'Styles'}} status="disabled-filled" color="primary" action={() => startContentUpdate({from: 'styles', multimediaObj, elem })} />
                        {!pristine ? <Button key={2} label={{text:'Use '+media}} /> : '' }
                    </div>,
                    <div key={1} className={`grid-x small-12 align-center align-middle`}>	
                        <Button width="cell small-6" shape="tab" size="small" label={{text:'User'}} status="visible" color="primary" action={() => startContentUpdate({from: 'styles', multimediaObj, elem })} />
                        <Button width="cell small-6" shape="tab" size="small" label={{text:'Admin'}} status="disabled-filled" color="primary" />		
                        {/* <Button width="cell small-10" label={{text:'Replace '+media}} action={() => this.startContentUpdate({from: 'content', multimediaObj, elem })} /> */}
                        {!pristine ? <Button label={{text:'Save Changes'}} /> : ''}
                    </div>
                ];
                if (isSuperAdmin) return styleAdminBtns;
                return styleAdminBtns[0];
            case 'styles':
            default:
                let styleBtns =  [
                    <div key={0} className={`grid-x small-12 align-center align-middle`}>			
                        <Button width="cell small-6" shape="tab" size="regular" label={{text:'Content'}} status="visible" color="primary" action={() => startContentUpdate({from: 'contentFromPage', multimediaObj, elem })} />
                        <Button width="cell small-6" shape="tab" size="regular" label={{text:'Styles'}} status="disabled-filled" color="primary" action={() => startContentUpdate({from: 'styles', multimediaObj, elem })} />
                        {!pristine ? <Button key={2} label={{text:'Use '+media}} /> : '' }
                    </div>,
                    <div key={1} className={`grid-x small-12 align-center align-middle`}>	
                        <Button width="cell small-6" shape="tab" size="small" label={{text:'User'}} status="disabled-filled" color="primary" />
                        <Button width="cell small-6" shape="tab" size="small" label={{text:'Admin'}} status="visible" color="primary" action={() => startContentUpdate({from: 'stylesAdmin', multimediaObj, elem })} />		
                        {/* <Button key={0} width="cell small-10" label={{text:'Replace Image'}} action={() => this.startContentUpdate({from: 'content', multimediaObj, elem })} /> */}
                        {!pristine ? <Button label={{text:'Save Changes'}} /> : ''}
                    </div>
                ];
                if (isSuperAdmin) return styleBtns;
                return styleBtns[0];
        }
    })()


    return (
        <div className={`${styles.nav} grid-x small-12 align-center align-middle`}>
            { buttons }
        </div>
    )
}