import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchOnePage } from '../actions';
import * as qs from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface PageHOC_I {
  draft?:String;
  fetchOnePage?: Function
}

const PageHOC = (WrappedComponent:any) => {
  const Container:any = class extends Component<PageHOC_I & RouteComponentProps<any>, {}> {

    static displayName = `PageHOC(${WrappedComponent.displayName  || WrappedComponent.name})`;
    state = { draft:'draft' };

    componentDidMount() {
      let { match, fetchOnePage, history, location } = this.props,
          params:any = {}, queries = {};
        
      if (match && match.params) {
        params = match.params
      }
    
      if (location && location.search) {
        queries = qs.parse(
          location.search, 
          // { ignoreQueryPrefix: true }
        )
      }

      if (params.pageID) {
        fetchOnePage({ id: params.pageID, ...params, queryString:'?populate=vigs', queries})
      }
      
      // query issues when both gif & gifs populated!
    }

    render() {
      return (
        <WrappedComponent {...this.props} />
      )
    }
  }

  return connect(
    (state) => state, 
    { fetchOnePage }
  )(Container)
}

export default PageHOC;
