import {
    VALIDATED_NEW_PROJECT
} from '../../project/actions/types';

import {
    REGISTER_USER
} from '../../user/actions/types';

let defaults = {
    projectTitles: {
        primary:'Quick start your first project...'
    },
    viewTemplate: {}
}

export default function quickStartReducer(state:any = defaults, action) {
    switch (action.type) {
        case VALIDATED_NEW_PROJECT:
            console.log('action.payload :', action.payload);
            return {
                ...state, 
                ...action.payload,
                viewTemplate: {
                    ...state.viewTemplate,
                    ...action.payload.viewTemplate
                }
            };
        // case REGISTER_USER:
        //     return {...state, ...action.payload};
        default:
            return state
    }
}