import * as React from 'react';
import { Component } from 'react';

import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './index.scss';

import Button from 'src/components/atoms/Button'
// import Tabs from 'src/components/molecules/Tabs'
import InputText from '../../../atoms/InputText'
import { InputTextProps_I } from '../../../atoms/InputText/interfaces'
import { createElemDraft, deleteElemDraft, updateElemDraft } from 'src/data/layer/actions'
import withOriginalContent from 'src/data/originalContent/containers' 
import InputFile from 'src/components/atoms/InputFile/ui'
import InputTextSimple from 'src/components/atoms/InputTextSimple';
/*
ImageEditor
	ImagePreview ( stored, new)
	EditorNav (editorState = styles, contentManual, contentFromPage )
		ReplaceButton (active)
			OR
		UpdateButtons (active)
		SaveButton (active, disabled, hidden)
	EditorOptions (editorState = styles, contentManual, contentFromPage )
		Styles	( pristine )
			OR
		ManualUpdate ( pristine, error, pending )
			OR
		OriginalContentUpdate
*/


interface IDipatchProps { 
	load?: (ref: string) => void; 
	createElemDraft?:Function
	deleteElemDraft?: Function
	updateElemContent?: Function
}

interface Props extends IDipatchProps {
	vig?: any
	elem?:Object 
	elems?: Array<any>
	multimediaObj?: any
	elemEditing: any
	originalContents?: Array<any>
}

interface State extends Props {
	editorState?: 'styles' | 'contentManual' | 'contentFromPage'
	elemEditing: any
	multimediaObj: any
	storedImage?:  Object
	previewImage?: any
}

class DomNodeContentEditor extends Component<Props & RouteComponentProps<any>, State>  {	
	state: State = {
		editorState: 'styles',
		elemEditing: {},
		multimediaObj: {},
		storedImage: {},
		previewImage: {}
	};

	static defaultProps = {
		vig: {
			data: {
				created_at: '',
				updated_at: ''
			}, 
			layers: [], 
			contentGroups: [], 
			elems: [], 
			multimediaObjs: []
		}
	}
	
	static getDerivedStateFromProps(newProps, prevState) { 
		console.log('newProps :', newProps);
		return newProps;
	}

	startImageUpdate({ from, multimediaObj, elem }) {
		switch (from) {
			case 'content':
				this.setState(prevState=>{
					return {
						editorState: 'contentFromPage'
					}
				})
				break;
			case 'upload':
				this.setState(prevState=>{
					return {
						editorState: 'contentManual'
					}
				})
				break;
		}
	}
	replaceImage({ elem = null, newObjID = null, newMediaObj = null }) {
		const { 
			elemEditing,
			createElemDraft
			// updateElemContent, // action
		 } = this.props;

		const currentDraft = elemEditing.elem_drafts && elemEditing.elem_drafts[0] ? elemEditing.elem_drafts[0] : null;
		
		 switch (currentDraft) {
			case null:
				if (newObjID) {
					createElemDraft({
						targetElemID: elem.id, 
						newObjID,
						type: 'mediaFromPage'
					});	
				}
				if (newMediaObj) {
					createElemDraft({
						targetElemID: elem.id, 
						newMediaObj,
						type: 'mediaManually'
					});	
				}
				break;
			default:
				if (newObjID) {
					updateElemDraft({
						draftID: currentDraft, 
						newMediaID: newObjID,
						type: 'mediaFromPage'
					});	
				}
				if (newMediaObj) {
					updateElemDraft({
						draftID: currentDraft, 
						newMediaObj,
						type: 'mediaManually'
					});	
				}
		}
		//  updateElemContent({})
		/* Manual Image */
		//  updateElemContent({
		// 	elem,
		// 	newID,
		// 	imageContent: {
		// 		data:'', //binaryimg
		// 		original_filename:'', 
		// 		extension:'',
		// 		dimensions:{
		// 			height:'',
		// 			width: ''
		// 		}
		// 	}, // if image
		// 	text:'',
		// 	mediaType:'', // image, text
		// 	updateType:'' // manual, fromContent
		// })
	}
	editContent(contentEditing) {
		this.setState(prevState => {
			return {
				...prevState,
				contentEditing
			}
		})
	}

	/* Preview */
	renderEditorPreview({storedNode, draftNode, loading, draftID, elemID}) {
		if (loading) {
			return (
				<div className="grid-x align-middle align-center" style={{minHeight: '200px'}}>
					<div className="progress cell small-8 small-offset-1">
						<div className="indeterminate"></div>
					</div>
				</div>
			);
		}
		if (draftNode) {
			return (
				<div className={`${styles['preview-image']} grid-x align-center align-middle`}>
					<div className={`cell auto grid-x align-middle align-center`}>
						<div 
							dangerouslySetInnerHTML={{__html: storedNode}} 
							style={{width:"200px"}}
						></div>
						<h5 className="cell">Saved Node</h5>
					</div>
					<div className={`cell auto grid-x align-middle align-center`}>
						<div 
							dangerouslySetInnerHTML={{__html: draftNode}} 
							style={{width:"200px"}}
						></div>
						<h5 className="cell">Draft Node</h5>
						<div className={`${styles.actions} grid-x`}>
							<i 
								className={`material-icons ${styles.clear}`}
								onClick={()=> this.props.deleteElemDraft({draftID, id: elemID})}
							>clear</i>
							{/* <i className={`material-icons ${styles.save}`}>done</i> */}
						</div>
					</div>
				</div>
			)
		} else {
			return (
				<div className={`${styles['preview-image']} grid-x align-center align-middle`}>
					<div 
						dangerouslySetInnerHTML={{__html: storedNode}} 
						style={{width:"200px"}}
					></div>
				</div>
			)
		}
	}

	/* Nav */
	renderEditorNav (props) {
		const { editorState, pristine, multimediaObj, elem } = props;

		const buttons = (()=> {
			switch (editorState) {
				case 'contentManual':
					return [
						<Button key={0} width="cell small-6" shape="square" size="regular" label={{text:'From Page'}} status="visible" color="secondary" action={() => this.startImageUpdate({from: 'content', multimediaObj, elem })} />,
						<Button key={1} width="cell small-6" shape="square" size="regular" label={{text:'Manually'}} status="disabled-filled" color="secondary" />,
						!pristine ? <Button key={2} label={{text:'Use Image'}} /> : '' 
					];
				case 'contentFromPage':
					return [	
						<Button key={0} width="cell small-6" shape="square" size="regular" label={{text:'From Page'}} status="disabled-filled" color="secondary" />,
						<Button key={1} width="cell small-6" shape="square" size="regular" label={{text:'Manually'}} status="visible" color="secondary" action={() => this.startImageUpdate({from: 'upload', multimediaObj, elem })} />,
						!pristine ? <Button key={2} label={{text:'Use Image'}} /> : '' 
					];
				case 'styles':
				default:
					return [
						<Button key={0} width="cell small-10" label={{text:'Replace Image'}} action={() => this.startImageUpdate({from: 'content', multimediaObj, elem })} />,
						!pristine ? <Button key={1} label={{text:'Save Changes'}} /> : ''
					];
			}
		})()

		return (
			<div className={`${styles.nav} grid-x align-center align-middle`}>
				{ buttons }
			</div>
		)
		// onClick={ ()=> this.editContent({elem: elemEditing,multimediaObj }) }
	}

	/* Editor Body */
	renderEditorStyles({ elemEditing, multimediaObj }) {
		const { elem_formats } = elemEditing,
			{ css } = elem_formats;
		
		let cssFields = buildStyleFields({css})

		return (
			<div>
				<form>
					{ cssFields }					
				</form>
			</div>
		);
	}
	renderEditorManual ({elemEditing}) {
		const { previewImage, } = this.state;

		return (
			<div className="grid-x align-middle align-center">
				<InputFile 
					onChange={ 
						(file)=> {
							let { name } = file,
								fr = new FileReader();

							if (file) {
								fr.onload = () =>{
									this.setState({
										previewImage: {
											imageData: fr.result,
											original_filename: name
										}
									})
								};
								fr.readAsDataURL(file);
							}
						}
					}
				/>
				<h4 className="cell small-12 text-center">OR</h4>
				<InputTextSimple label={{text: 'Image URL', position:'left'}} size="large" />
				<Button 
					status={ previewImage && previewImage.imageData ? 'visible' : 'disabled'}
					action={ () => {this.replaceImage({ elem:elemEditing, newMediaObj: previewImage }) } }
				/>
			</div>
		)
	}
	renderEditorFromPage ({ multimediaImages, elem }) {
		const previews = (() =>  {
			return multimediaImages.reduce((acc,image) => {
				let { node, id } = image;
				if (id === elem.multimedia_obj) return acc;
				let preview = (
					<div
						key={id}
						onClick={() => this.replaceImage({elem, newObjID: id})}
						className={`${styles['content-option']} cell`}
						dangerouslySetInnerHTML={{__html: node}}
					></div>	
				);
				return [...acc, preview]
			},[]) 
		})()

		return <div className="grid-x align-center align-middle">{ previews }</div>;
	}
	/* Editor Body: end */

	render () {
		const { elemEditing, multimediaObj, vig } = this.props,
			{ elems, multimediaObjs } = vig,
			{ editorState, originalContents: multimediaImages } = this.state;

		const draftElem = (()=> {
			const { elem_drafts } = elemEditing;
			if (!multimediaImages.length) return 'loading';
			if (!elem_drafts || !elem_drafts.length) return null;
			const elem = elems.find(({id}) => elem_drafts[0] === id);
			if (!elem || ! elem.multimedia_obj) return null;
			const allMedia = [...multimediaObjs, ...multimediaImages]
			const multimedia_obj = allMedia.find(({id})=> elem.multimedia_obj === id);
			return {
				...elem,
				multimedia_obj
			}
		})()
		
		const editorBody = (()=>{
			switch (editorState) {
				case 'contentManual':
					return this.renderEditorManual({elemEditing});
				case 'contentFromPage':
					return this.renderEditorFromPage({multimediaImages, elem: elemEditing });
				case 'styles':
				default:
					return this.renderEditorStyles({ elemEditing, multimediaObj });
			}
		})()

		return(
			<div className="grid-x">
				<div className="cell small-12">
					{ 	this.renderEditorPreview({
							storedNode: multimediaObj.node,
							draftNode: draftElem  && draftElem !== 'loading' && draftElem.multimedia_obj && draftElem.multimedia_obj.node ? draftElem.multimedia_obj.node : null,
							loading: draftElem === 'loading' ? true : false,
							draftID: draftElem  && draftElem !== 'loading' ? draftElem.id : null,
							elemID: elemEditing.id
						}) 
					}
					{ 
						this.renderEditorNav({ 
							editorState, 
							pristine: true, 
							multimediaObj, 
							elem: elemEditing
						}) 
					}
					{ editorBody }
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => state;	

export default withOriginalContent(
						withRouter(
							connect<{}, IDipatchProps, Props & RouteComponentProps<any>>(
								mapStateToProps,
								{ createElemDraft, deleteElemDraft}
							)(DomNodeContentEditor)
						),
					['images']
				);


function buildStyleFields({css}) {
	let allowedStyles = ['background-position'];
	return Object.entries(css).reduce((acc,prop:any) => {
		let key = prop[0],
			value = prop[1];
		if (!allowedStyles.includes(key)) return acc;
		let inputProps:InputTextProps_I  = {
			label: { text: key, position: 'left' },
			value: value,
			onChange: (newVal) => {

			}
		};
		return [...acc, <InputText key={key} {...inputProps} />];
	},[])
}

