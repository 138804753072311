import * as React from 'react';
import { Component } from 'react';
import styles from './index.scss';
import * as configs from './config';
import {  InputTextSimple_I } from '../interfaces'


interface State extends InputTextSimple_I {}

class InputTextSimple extends Component<InputTextSimple_I, State>  {
	
	static defaultProps = {
		type:'',
		name:'',
		placeholder: '',
		shape: 'square',
		size: 'medium',
		// inputStyle: 'light',
		label: {
			text:''
		},
		prompt: {	
			text:'',
			position: 'bottom',
		},
		layoutClasses: ''
	}

	state:State = { 
		value: '',
		status: 'pristine',
		inputState: 'filled',
		prompt: {	
			status: 'hidden',
			text:''
		},
	}

	componentDidMount () {}

	render () {
		const { 
			layoutClasses, 
			size, 
			inputStyle, 
			shape, 
			label, 
			defaultValue,
			onChange = () => { console.log('No on change function provided!');},
			placeholder,
			name,
			textarea,
			classNames
		} = this.props,
		{ value } = this.state;

		return(
			<div 
				className={`input-text ${layoutClasses} ${classNames}`}
				data-size={size}
				//Input
				data-input-style={inputStyle}
				data-shape={shape}
				// Label 
				data-label-style={label.style}
				data-label-position={label.position}
				// Prompt
				// data-prompt-status={prompt.status}
				// data-prompt-position={prompt.position}
				data-is-textarea={textarea}
			>
				<label>{label.text}</label> 
				{ 
					!textarea 
					? <input 
						type="text" 
						placeholder={ placeholder }
						name={name}
						defaultValue={ defaultValue }
						onChange={(e) => onChange({name:e.target.name, value: e.target.value}) } 
					 />
					: <textarea
						placeholder={ placeholder }
						name={name}
						defaultValue={ defaultValue }
						onChange={(e) => onChange({name:e.target.name, value: e.target.value}) } 
					 />
				}
				{/* <p 
					className="input-text-prompt" 
					dangerouslySetInnerHTML={{ __html: prompt.text }}
				></p> */}
			</div>
		);	
	}
}

export default InputTextSimple;



