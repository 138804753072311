import {
    REGISTER_USER,
    SIGN_IN_USER,
    GET_USER,
} from '../actions/types';

export function userReducer(state = {}, action) {
    
    let isAuthed = ((user) => {
        let currentToken = localStorage.getItem('token');
        if (currentToken && currentToken !== 'undefined') return true;
        if (user.token) {
            localStorage.setItem('token', user.token);
            if (user.cognito_token) {
                localStorage.setItem('cognito_token', user.cognito_token);
            }
            return true;
        }
        return false;
    })(action.payload && action.payload.user ? action.payload.user : {})
    
    switch (action.type) {
        case REGISTER_USER:
            return {...state, ...action.payload.user, isAuthed};
        case SIGN_IN_USER:
            if (action.payload && action.payload.error) return {...state, authError: action.payload.error };
            return {...state, ...action.payload.user, isAuthed};
        case GET_USER:
            return {...state, ...action.payload.user, isAuthed};
        default:
            return {...state}
    }
}

export function usersReducer(state = [], action) {
    // console.log('action.payload :', action.payload);
    switch (action.type) {
        default:
            return state
    }
}