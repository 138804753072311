import * as React from 'react';
import { Component } from 'react';

import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './index.scss';

import ViewTitles from 'src/components/molecules/ViewTitles';
import {ViewTitles_I} from 'src/components/molecules/ViewTitles/interfaces';
import withProject from 'src/data/project/containers' 
import withProjects from 'src/data/project/containers/projects' 
import withPage from 'src/data/page/containers' 
import withVig from 'src/data/vig/containers' 
import withPageTemplates from 'src/data/pageTemplate/containers/pageTemplates'

import Button from 'src/components/atoms/Button'
import { PageWithVIG_I } from 'src/data/page/interfaces'

import { TimeStamps } from 'src/components/molecules/TimeStamps/ui'
import { buildVIGPreview } from 'src/shared/utils/vig'

import VigPreview from 'src/components/atoms/VigPreview'
import ContentGroupEditor from 'src/components/organisms/ContentGroupEditor/ui'
import Tabs from 'src/components/molecules/Tabs/ui'
import { updateLayerDraft } from 'src/data/layer/actions'
import { access } from 'fs';

import Spinner from 'src/components/atoms/Spinner/ui'

interface Props {
	currentViewState?:string;
	viewTitles?: ViewTitles_I
	page?: PageWithVIG_I
	project?:any
	viewing?: string
	vig?: any
	history?: any 
	created_at?: string 
	updated_at?: string
	fetchOne?: Function
	updateLayerDraft:Function
	originalContents?: Array<any>
}

interface State extends Props{
	previewData?: any;
	activeDraft?:boolean;
	animationKey?: any
	draftMode?: boolean
}

interface IDipatchProps {
	load?: (ref: string) => void;
	updateLayerDraft:Function
}

class LayerView extends Component<Props & RouteComponentProps<any>, State>  {	
	state = {
		previewData: null,
		activeDraft: false,
		animationKey: Math.random(),
		draftMode: true,
		updateLayerDraft:()=>{} // no idea why TS is making me put this in state?????
	};

	static defaultProps = {
		vig: {
			data: {
				created_at: '',
				updated_at: ''
			}, 
			layers: [], 
			contentGroups: [], 
			elems: [], 
			multimediaObjs: [],

		}
	}
	static getDerivedStateFromProps(newProps, prevState) {      
		const { 
			data: vigData,
			history
		} = newProps.vig;

		const {
			updateLayerDraft,
			...newState
		} = newProps;

		if (vigData && vigData.error) {
			history.push('/');
		}
		return {
			...prevState,
			...newState
		};
	}
	
	componentDidUpdate(prevProps, prevState) {
		const { vig } = this.props,
			  { data, layers, elems, contentGroups } = vig,
			  { layerViewing } = data;

		if (!layerViewing || !layers.length) return false;

		const layer = layers.find(({id}) => layerViewing === id)
		if (!layer) return false;

		const activeDraft = (()=>{
			return contentGroups.some(group => {
				let { vig_content_group_elems: groupElems } = group;
				if (group.vig_layer !== layer.id || !groupElems.length) return false;
				return groupElems.some(elemID => {
					let elem = elems.find(({id}) => elemID === id)
					return (elem.elem_drafts && elem.elem_drafts.length);
				})
			})
		})()

		// should consolidate to single state update!!!
		// if (this.state.activeDraft !== activeDraft) {
		// 	this.setState({
		// 		activeDraft,
		// 		// animationKey: Math.random(),
		// 	})
		// }

		const updated = layerDataUpdated({
			oldProps: prevProps.vig, 
			newLayer: layer, 
			newProps: vig, 
			previewData: this.state.previewData,
			preDraftMode: prevState.draftMode,
			draftMode: this.state.draftMode
		})

		if ( updated || (this.state.activeDraft !== activeDraft) ) {
			let { multimediaObjs: vigObjs, ...vigProps } = vig,
				{ originalContents = [] } = this.props,
				allMedia = [...vigObjs, ...originalContents],
				{ draftMode } = this.state; // this should be de-duped

			this.buildPreviewData({
				vigProps, 
				layer, 
				multimediaObjs: allMedia,
				draftMode,
				activeDraft
			});
		}
	}

	buildPreviewData ({vigProps, layer,  multimediaObjs, draftMode, activeDraft}) {
		const { data , contentGroups, elems } = vigProps;

		if (!data || !contentGroups || !elems || !multimediaObjs) return <Spinner active="true" />;
		
		renderPreview({ 
			vigData: data, 
			layers: [ layer ], 
			contentGroups, 
			elems, 
			multimediaObjs,
			draftMode
		}).then(previewData => {
			this.setState(prevState => {
				return {
					...prevState,
					previewData,
					layerViewing: layer,
					animationKey: Math.random(),
					activeDraft
				}
			})
		})
		
	}

	renderEditor(props) {
		const { vig } = props,
			  { data, layers, contentGroups } = vig,
			  { layerViewing, vig_layers } = data;
			
		let groups = contentGroups.filter(({vig_layer}) => vig_layer === layerViewing);

		let tabsData = {
			tabs: groups.map((group,i) => {
				let { name, type } = group,
					editorProps:any = { groupID: group.id };
					
				return { 
					title: `${name || ''} ${type||''} ${ name || type ? '' : 'Group:'+i }`,
					pane: {
						body:<ContentGroupEditor {...editorProps} /> 
					}
				};
			})
		}

		return <Tabs {...tabsData} />
	}

	editLayerDraft ({action, layerID}) {
		switch (action) {
			case 'save':
				this.props.updateLayerDraft({id: layerID, action})
				break;
			case 'destroy':
				this.props.updateLayerDraft({id: layerID, action})
				break;
		} 
	}

	renderLayerActions({activeDraft, layerID}) {
		return (
			<div className="grid-x" style={{marginTop:'10px', marginBottom:'10px'}}>
				<div className="grid-x small-12" style={{marginBottom:'5px'}}>
					<Button 
						width="cell small-12"
						label={{text: this.state.draftMode ? 'View Saved Version' : 'View Draft Version', weight:'text-bold'}}
						color="secondary"
						shape="round"
						size="small"
						status={activeDraft ? 'visible' : 'disabled'}
						action={() => this.setState({ draftMode: !this.state.draftMode }) }
					/>
				</div>
				<div className="grid-x small-12">
					<Button 
						width="cell small-6"
						label={{text: 'Save Changes'}}
						color="primary"
						shape="round"
						size="small"
						status={activeDraft ? 'visible' : 'disabled'}
						action={ ()=> this.editLayerDraft({action: 'save',layerID}) }
					/>
					<Button 
						width="cell small-6"
						label={{text: 'Destroy Draft'}}
						color="error"
						shape="round"
						size="small"
						status={activeDraft ? 'visible' : 'disabled'}
						action={ ()=> this.editLayerDraft({action: 'destroy',layerID}) }
					/>
				</div>
			</div>
		)
	}
	render () {
		const { page, vig } = this.props,
			  { data } = vig,
			  { layerViewing } = data,
			  { activeDraft, previewData, animationKey } = this.state;

		return(
			<div className={`grid-x ${styles['layer-container']}`}>
				<div className="grid-x small-10 small-offset-1">
					<div className="cell auto" style={{flexBasis:'500px'}}>
						<h2>{ renderViewTitles({ page }) }</h2>
						<div style={{minHeight:'300px', width:'100%', position:'relative' }}>
						{ previewData ? <VigPreview {...previewData} key={animationKey} /> : <Spinner active={true} /> }
						</div>
						{ this.renderLayerActions({activeDraft, layerID: layerViewing})}
						
					</div>
					<div className="cell shrink" style={{paddingLeft: "20px",flexBasis:'350px'}}>
						{ data && (data.created_at || data.updated_at) ? renderTimeStamps(data) : '' }
							{this.renderEditor(this.props)}
					</div>
				</div>
			</div>
		);
	}
}




function mapStateToProps(state:any ) {
	return state;	
}

export default 
	withPageTemplates(
		withProjects(
			withProject(
				withPage(
					withVig(
						withRouter(
							connect<{}, IDipatchProps, Props & RouteComponentProps<any>>(
								mapStateToProps,
								{ updateLayerDraft },
								null,
								{pure: false }
							)(LayerView)
						)
					)
				)
			)
		)
	);


function layerDataUpdated({oldProps, newLayer, newProps, previewData, preDraftMode, draftMode }) {
	const oldID = oldProps.data.layerViewing
	
	if (
		(!previewData)
		|| (!oldID)
		|| (!oldProps.layers || !oldProps.layers.length) 
		|| (preDraftMode !== draftMode)
	) return true;
	
	const oldLayer = oldProps.layers.find(({id}) => oldID === id);
	
	if (
		(!oldLayer || !oldLayer.updated_at)
		|| (oldLayer.id !== newLayer.id)
		|| (oldLayer.updated_at !== newLayer.updated_at)
	) return true;
	
	const oldObjs = flattenLayerObjs(oldProps, newLayer.id)
	const newObjs = flattenLayerObjs(newProps, newLayer.id)

	return oldObjs.some(obj => {
		let initial = newObjs.find(({id}) => obj.id === id);
		if (
			(!initial) 
			|| (initial.created_at !== obj.created_at) 
			|| (initial.updated_at !== obj.updated_at)
		) return true;
	})
}
function flattenLayerObjs(vigData, layerID) {
	const { contentGroups, elems, multimediaObjs  } = vigData;
	
	let layerContentGroups = contentGroups.filter(({vig_layer}) => layerID === vig_layer);
	let elemIDs = [].concat(
		...layerContentGroups.map(({vig_content_group_elems}) => vig_content_group_elems)
	);
	let layerElems = elemIDs.reduce((acc,elemID) => {
		let elem = elems.find(({id}) => elemID === id)
		if (elem) return [...acc, elem];
		return acc;
	},[]);
	let draftElems = layerElems.reduce((acc, elem) => {
		if (!elem.elem_drafts || !elem.elem_drafts.length) return acc;
		let drafts = elems.filter(({id}) => elem.elem_drafts.includes(id));
		return [...acc, ...drafts];
	}, [])
	let allElems = [...layerElems, ...draftElems,];
	let layerMultimedia = allElems.reduce((acc, elem) => {
		let mediaObj = multimediaObjs.find(({id}) => elem.multimedia_obj === id)
		if (mediaObj) return [...acc, mediaObj];
		return acc;
	},[])

	const flattenedObjs = [].concat(layerContentGroups, allElems, layerMultimedia)

	return flattenedObjs;
}

async function renderPreview({
	vigData,
	layers,
	contentGroups,
	elems,
	multimediaObjs,
	draftMode
}) {
	
	const previewData = await buildVIGPreview({
		layers, //[ layers[0] ],
        contentGroups,
        elems,
        multimediaObjs,
		vigData,
		draftMode
	});

	const { htmlString, cssString, layerAnimations, vigID } = previewData;

	if (htmlString.length && cssString.length && layerAnimations.length && vigID.length) {
		let props = {
			cssString,
			htmlString,
			layerAnimations,
			vigDuration: vigData.duration || 20000,
			dimensions: {
				width:500,
				height:300
			},
			vigID
		}
		return props;
		// return <VigPreview {...props} />
	} 
	// else {
	// 	return <h3>LoadingVIG preview... </h3>;
	// }
}

function buildVig({id, media}) {
	switch (media) {
		case 'video':
		case 'gif':
	}
}
function renderViewTitles({ page}) {
	if (page) {
		return  (
			<ViewTitles 
				primary={page.url_project_root || ''}
				secondary={page.url_path || ''}
				position={'left'}
			/>
		);
	} 
}

function renderTimeStamps(vigData) {
	const { 
			created_at, 
			updated_at 
		} = vigData,
		timesObj =  {
			created_at, 
			updated_at 
		},
		timeKeys = Object.keys(timesObj);

	if (timeKeys) {
		const times = timeKeys.map(key=> {
			return { label: key, timeDate: timesObj[key] }
		})
		return <TimeStamps 
					size="tiny"
					times={times}
				/>;
	}
}

