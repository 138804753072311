import {
    DRAFT
} from '../actions/types';

export default function draftReducer(state = {}, action) {
    switch (action.type) {
        case DRAFT:
            return {...state}
        default:
            return state
    }
}