// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__media-source___ir7C4{margin-bottom:20px}.index__media-source___ir7C4 .index__media-title___mD9N0{margin-bottom:0;margin-right:10px;min-width:70px}`, "",{"version":3,"sources":["webpack://./src/components/views/Vig/ui/index.scss"],"names":[],"mappings":"AAOA,6BACI,kBAAA,CAEA,yDACI,eAAA,CACA,iBAAA,CACA,cAAA","sourcesContent":["\n\n// .project-container {\n//     height: 60vh;\n//     min-height: 400px;\n// }\n\n.media-source {\n    margin-bottom: 20px;\n    \n    .media-title {\n        margin-bottom: 0;\n        margin-right: 10px;\n        min-width: 70px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"media-source": `index__media-source___ir7C4`,
	"media-title": `index__media-title___mD9N0`
};
export default ___CSS_LOADER_EXPORT___;
