export const defaultFieldValidations = {
    required:(value, assertion)=>{
        if (!value || !value.length || value === undefined || value === null || typeof value === 'undefined') {
            return {
                error: true,
                message: 'This field is required!'
            }
        }
        return false;
    },
    email:(value, assertion)=>{
        function validateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
        if (value && value.length && !validateEmail(value)) {
            return {
                error: true,
                message: 'Must be a valid email'
            }
        }
        return false; 
    },
    minimum:(value, assertion)=>{
        switch (isNaN(value)) {
            case true:
                if (value && value.length < assertion) {
                    return {
                        error: true,
                        message: `This field must have a length or value greater than ${assertion - 1}` 
                    };
                }
                return false;
            
            case false:
                if (value && value < assertion) {
                    return {
                        error: true,
                        message: `This field must have a length or value greater than ${ assertion - 1 }` 
                    };
                }
                return false;
            
            default:
                return false;
        }
        
    },
    maximum:(value, assertion)=>{
        switch (isNaN(value)) {
            case true:
                if (value && value.length > assertion) {
                    return {
                        error: true,
                        message: `This field must have a length or value less than ${assertion + 1}`
                    };
                }
                return false;
            
            case false:
                if (value && value > assertion) {
                    return {
                        error: true,
                        message: `This field must have a length or value less than ${assertion + 1}`
                    };
                }
                return false;
            
            default:
                return false;
        }
    },
    valueType: (value, assertion) => {
        if (assertion === 'number') {
            if (isNaN(value)) {
                return {
                    error: true,
                    message: `This field must be a ${assertion}` 
                }
            }
            return false;
        }
        return false;	
    },
}
export const defaultFormValidations = {
    matchField: (value, assertion, fields) => {
        return new Promise(resolve=> {
        // let fieldTypeToMatch = assertion,
        //     formFieldsList;

        // if (Object.keys(fields).length) {
        //     formFieldsList = Object.keys(fields).map(fieldKey => {
        //         console.log(fieldKey,fields[fieldKey])
        //         return fields[fieldKey];
        //     })
        // } else {
        //     formFieldsList = fields;
        // }
        // console.log('field list',formFieldsList);
        // let matchValue = formFieldsList.reduce((acc, field) => {
        //     if (field.type === fieldTypeToMatch) return field.value;
        //     return acc;
        // },'');

            if (value === assertion.value) {
                resolve(false);
            } else {  
                resolve({
                    error: true,
                    message: `Must be same as '${assertion.type}' field.` 
                })
            }
        })
    },
    // custom: this.props.validation.customProcedure
}