import {
    REGISTER_USER
} from '../../user/actions/types'

import {
    VALIDATED_NEW_PROJECT,
    FETCH_PROJECT,
    FETCH_PROJECTS,
    ADD_NEW_PAGE
} from '../actions/types';

let defaultProject = {
    pageViewing: null
}
export function projectsReducer(state:any = defaultProject, action) {
    if (action.payload && action.payload.projects && action.payload.projects.length) {
        switch (action.type) {
            case REGISTER_USER:
                return action.payload.projects;
            case FETCH_PROJECTS:
                return action.payload.projects;
            default:
                return state
        }
    } else {
        return state;
    }
}

export function projectReducer(state:any = {}, action) {
    if (action.payload && action.payload.project && action.payload.project.error) {
        return { 
            ...state,
            error: action.payload.project.error 
        }
    };
    switch (action.type) {
        case VALIDATED_NEW_PROJECT:
            return {
                ...state, 
                ...action.payload.project
            }
        case FETCH_PROJECT:
            return action.payload.project;
        // case  ADD_NEW_PAGE:
        default:
            return state
    }
}