import * as React from 'react';
import { Component } from 'react';

import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './index.scss';

import withVig from 'src/data/vig/containers' 
import withPageTemplates from 'src/data/pageTemplate/containers/pageTemplates'

import Button from 'src/components/atoms/Button'
import clientViewStates  from 'src/shared/constants/viewStates';
const projectViewStates = clientViewStates.viewStates; 
import { PageWithVIG_I } from 'src/data/page/interfaces'
// import Tabs from 'src/components/molecules/Tabs'
import ElemEditor from '../../ElemEditor/ui'
import { registerUser } from 'src/data/user/actions';


interface Props {
	vig?: any
	contentGroups?: any 
	groupID?:string
}

interface State extends Props{
	editingElem?: string;
	elemList?: Array<any>
	groupEditing?: any
}

interface IDipatchProps { load?: (ref: string) => void; }

class ContentGroupEditor extends Component<Props & RouteComponentProps<any>, State>  {	
	state = {
		editingElem: null,
		elemList: [],
		groupEditing: {}
	};

	static defaultProps = {
		vig: {
			data: {
				created_at: '',
				updated_at: ''
			}, 
			layers: [], 
			contentGroups: [], 
			elems: [], 
			multimediaObjs: []
		}
	}
	
	static getDerivedStateFromProps(newProps, prevState) {      
		const { vig, groupID } = newProps,
			{ 
				data: vigData, 
				contentGroups, 
				elems, 
				multimediaObjs,
				history
			} = vig;

		if (vigData && vigData.error) { history.push('/'); }
	
		const group = contentGroups.find(({id}) => groupID === id)
		if (!group) return prevState;
		if (!multimediaObjs.length) return prevState;

		let newElemList = updateElemList({
				prevElems: prevState.elems,
				newElems: elems, 
				groupElemIDs: group.vig_content_group_elems,
				prevGroup: prevState.groupEditing,
				newGroup: group,
				multimediaObjs
		});	

		let editingElem = getEditingElem(prevState.groupEditing, group);

		if (newElemList.changed) {
			return {
				...prevState,
				elemList: newElemList.elems,
				groupEditing: group,
				...editingElem
			}
		}
		return prevState;
	}
	
	renderElemList({elemList}) {
		console.log('elemList :', elemList);
		return (
			<ul className={styles.list}>
			{
				elemList.map(elem => {
					let { elem_family, elem_type, name, id } = elem;
					return (
						<li
							key={id}
							onClick={ (event: React.MouseEvent<HTMLElement>) => this.editElem({id}) } 
							className={`grid-x align-middle align-center hoverable ${styles['list-item']}`}
						>
							<p className="cell medium-4 medium-offset-1">
								{`${elem_family}: ${elem_type || ''} ${name || ''}`}
							</p>
							{ listPreview(elem) }
						</li>
					);
				})
			}
			</ul>
		)

		function listPreview(elem) {
			const { elem_family, multimedia_obj} = elem;
			let multimediaType = (()=>{
				if (!multimedia_obj) return null;
				if (multimedia_obj.text) return 'text';
				if (multimedia_obj.s3_path) return 'image';
				if (multimedia_obj.node) return 'domNode';
				return null;
			})()
			switch (multimediaType) {
				case 'image':
					return (
						<div className="cell medium-7">
							<img 
								className={styles['content-option']} 
								style={{backgroundImage: `url(${multimedia_obj.s3_path})`}}
							/>
						</div>
					);
				case 'text':
					let objText = (()=>{
						let { text } = multimedia_obj;
						if (text.length < 40 ) return text;
						return text.slice(0,39) + '...';
					})()
					return (
						<div className="cell medium-7">
							<h6>{objText}</h6>
						</div>
					);
				case 'domNode':
					return (
						<div 
							className="cell medium-7"
							dangerouslySetInnerHTML={{__html: multimedia_obj.node }}
						></div>
					);
			}
		}
	}

	editElem({id}) {
		this.setState(prevState => {
			return {
				...prevState,
				editingElem: id
			}
		})
		return true;
	}
	renderElemEditor({elemID}) {
		return <h3>{elemID}</h3>;
	}

	render () {
		const { vig } = this.props,
			  { data } = vig,
			  { LayerEditing } = data,
			  { editingElem, elemList } = this.state;
		const editorProps:any = {elemID: editingElem}
		
		return(
			<div className={`grid-x cell small-12 ${styles['project-container']}`}>
				<div className="cell small-12">
					{ editingElem ? <ElemEditor {...editorProps} /> : this.renderElemList({elemList}) }
				</div>
			</div>
		);
	}
}



function mapStateToProps(state:any ) {
	return state;	
}

export default withVig(
					withRouter(
						connect<{}, IDipatchProps, Props & RouteComponentProps<any>>(
							mapStateToProps,
							{}
						)(ContentGroupEditor)
					)
				)

function getEditingElem (prevGroup,newGroup) {
	if (!prevGroup.id || (prevGroup.id !== newGroup.id)) return {editingElem: null};
	if (prevGroup.updated_at !== newGroup.updated_at) return {editingElem: null};
	return {};
}

function updateElemList({prevElems, newElems, groupElemIDs, prevGroup, newGroup, multimediaObjs}) {
	// this is over re-rendering!!!!!
	const newGroupElems = groupElemIDs.reduce((acc, elemID) => {
		let newElem = newElems.find(({id}) => elemID === id)
		if (!newElem) {
			console.log('failed to find needed elem! :', elemID);
			return acc;
		}
		if (newElem.elem_family === 'shape') return [...acc, newElem];
		let multimedia_obj = multimediaObjs.find(({id}) => newElem.multimedia_obj === id);
		if (!multimedia_obj) {
			console.log('failed to find needed multimedia_obj! :', newElem);
			return acc;
		}
		let withMedia = {...newElem, multimedia_obj}
		return [...acc, withMedia];
	},[]);

	const changed = (()=> {
		if (!multimediaObjs.length || !newElems.length) return false;
		if (
			(!prevGroup.id || (prevGroup.id !== newGroup.id))
		    || (prevGroup.updated_at !== newGroup.updated_at)
			|| ((!prevElems || !prevElems.length) && newElems.length)
		) return true;
		const oldGroupElems = prevElems.filter(({id}) => groupElemIDs.includes(id));
		
		return oldGroupElems.some(obj => {
			if (oldGroupElems.length !== newGroupElems.length) return true;
			let initial = newGroupElems.find(({id}) => obj.id === id);
			if (
				(!initial)
				||(initial.updated_at !== obj.updated_at)
			) return true;
		})
	})()
	
	return { elems: newGroupElems, changed }
}